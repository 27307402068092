import React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';

type Props = {
    src: string;
    alt?: string;
};
// TODO: change Avatar import in Avatar group
const Avatar: React.FC<Props> = ({ src, alt = 'avatar' }) => {
    return (
        <MuiAvatar alt={alt} src={src}>
            Avatar
        </MuiAvatar>
    );
};

export default Avatar;
