import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { Root } from 'components/feedback/emojiList/styling';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACES } from 'translations';
import { FEEDBACK_EMOJI_TYPE } from 'utils/enums';

const emojiInfo = {
    [FEEDBACK_EMOJI_TYPE.ANGRY]: {
        left: true,
        right: true,
        mouth: true
    },
    [FEEDBACK_EMOJI_TYPE.SAD]: {
        left: true,
        right: true,
        mouth: true
    },
    [FEEDBACK_EMOJI_TYPE.OK]: {
        left: false,
        right: false,
        mouth: false
    },
    [FEEDBACK_EMOJI_TYPE.GOOD]: {
        left: true,
        right: true,
        mouth: true
    },
    [FEEDBACK_EMOJI_TYPE.HAPPY]: {
        left: true,
        right: true,
        mouth: false
    }
};

type Props = {
    activeValue: FEEDBACK_EMOJI_TYPE | null;
    activeEmojisKeys?: FEEDBACK_EMOJI_TYPE[];
    labelKey?: string;
    onSelect?: (emoji: FEEDBACK_EMOJI_TYPE) => void;
    showLabel?: boolean;
};

const FeedbackEmoji: React.FC<Props> = ({
    activeValue,
    activeEmojisKeys = [
        FEEDBACK_EMOJI_TYPE.ANGRY,
        FEEDBACK_EMOJI_TYPE.SAD,
        FEEDBACK_EMOJI_TYPE.OK,
        FEEDBACK_EMOJI_TYPE.GOOD,
        FEEDBACK_EMOJI_TYPE.HAPPY
    ],
    labelKey = 'EMOJI',
    onSelect,
    showLabel = true
}: Props) => {
    const { t } = useTranslation(TRANSLATION_NAMESPACES.FEEDBACK);
    const [animationGo, setAnimationGo] = useState(false);
    const animationRef = useRef(animationGo);
    const animationInterval = useRef<any>(-1);

    const handleFeedbackChange = (event: BaseSyntheticEvent) => {
        if (onSelect) {
            onSelect(event.target.value);
        }
    };

    const updateAnimation = (value: boolean) => {
        animationRef.current = value;
        setAnimationGo(animationRef.current);
    };

    useEffect(() => {
        if (!activeValue) {
            updateAnimation(false);

            return;
        }

        clearInterval(animationInterval.current);

        updateAnimation(true);

        const update = () => {
            updateAnimation(!animationRef.current);
        };

        animationInterval.current = setInterval(() => {
            update();
        }, 2000);

        return () => {
            clearInterval(animationInterval.current);
        };
    }, [activeValue]);

    return (
        <>
            <Root
                className='feedback'
                hasMoreThan3={Boolean(activeEmojisKeys.length >= 3)}
            >
                {activeEmojisKeys.map((emoji: FEEDBACK_EMOJI_TYPE) => {
                    const emojiInfoItem = emojiInfo[emoji];
                    const key = Object.keys(FEEDBACK_EMOJI_TYPE).find(
                        // @ts-ignore
                        (e: string) => FEEDBACK_EMOJI_TYPE[e] === emoji
                    );

                    return (
                        <div key={key} className='root-container'>
                            <label className={key}>
                                <input
                                    type='radio'
                                    className={`
                                    ${activeValue === emoji ? 'active' : ''}
                                    ${
                                        activeValue === emoji && animationGo
                                            ? 'animate'
                                            : ''
                                    }`}
                                    value={emoji}
                                    onChange={handleFeedbackChange}
                                    name='feedback'
                                />
                                <div>
                                    {emojiInfoItem.left && (
                                        <svg className='eye left'>
                                            <use xlinkHref='#eye' />
                                        </svg>
                                    )}
                                    {emojiInfoItem.right && (
                                        <svg className='eye right'>
                                            <use xlinkHref='#eye' />
                                        </svg>
                                    )}
                                    {emojiInfoItem.mouth && (
                                        <svg className='mouth'>
                                            <use xlinkHref='#mouth' />
                                        </svg>
                                    )}
                                </div>
                            </label>
                            {Boolean(labelKey) && showLabel && (
                                <p className='label-text'>
                                    {t(`${labelKey}.${key}`)}
                                </p>
                            )}
                        </div>
                    );
                })}
            </Root>

            <svg xmlns='http://www.w3.org/2000/svg' display='none'>
                <symbol
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 7 4'
                    id='eye'
                >
                    <path d='M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1'></path>
                </symbol>
                <symbol
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 18 7'
                    id='mouth'
                >
                    <path d='M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5'></path>
                </symbol>
            </svg>
        </>
    );
};

export default FeedbackEmoji;
