import { getValueFromObject } from 'utils/get';

export class Level {
    private readonly _points: number;
    private readonly _level: number;
    private readonly _percentageCompleted: number;

    constructor(level: any) {
        this._points = getValueFromObject(level, 'points', 0);
        this._level = getValueFromObject(level, 'level', 1);
        this._percentageCompleted = getValueFromObject(
            level,
            'percent_level_completed',
            0
        );
    }

    get points(): number {
        return this._points;
    }

    get level(): number {
        return this._level;
    }

    get percentageCompleted(): number {
        return this._percentageCompleted;
    }
}
