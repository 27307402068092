import React, {BaseSyntheticEvent, useEffect, useState} from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';
import UserProfilePictureModal from 'modals/userProfilePicture';
import assets from "assets";

type Props = {
    name?: string;
    label?: string;
    value?: string | File;
    onChange: (newValue: File) => void;
    imgSize?: string;
};

type RootProps = {
    name?: string;
    imgSize?: string;
};

const Root = styled.div<RootProps>`
    grid-area: ${({name}) => name};
    display: flex;
    flex-direction: column;

    label {
        margin-bottom: 8px;
        color: ${theme.colors.primaryGrey};
    }

    .input-file {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    img {
        width: ${({imgSize}) => imgSize};
        height: ${({imgSize}) => imgSize};
        border-radius: 20px;
        cursor: pointer;
        object-fit: contain;
        border: solid 1px ${theme.colors.primary10};
    }
`;

const ImageSelector: React.FC<Props> = ({
                                            name,
                                            label,
                                            value,
                                            onChange,
                                            imgSize = '100px'
                                        }: Props) => {
    const [imgSrc, setImgSrc] = useState<string>();
    const [editModalEnabled, setEditModalEnabled] = useState(false);
    const handleClick = (event: BaseSyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setEditModalEnabled(true);
    };

    const convertFileToUrl = (file: File) => {
        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => setImgSrc(reader.result as string),
            false
        );

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleProfilePictureChanged = (file: File) => {
        convertFileToUrl(file);
        handleProfilePictureCanceled();
        onChange(file);
    };

    const handleProfilePictureCanceled = () => {
        setEditModalEnabled(false);
    };

    useEffect(() => {
        if (!value) {
            setImgSrc(assets.DEFAULT_AVATAR);
            return
        }

        if (typeof value === 'string') {
            setImgSrc(value);
            return;
        }

        convertFileToUrl(value);
    }, [value]);

    return (
        <>
            <Root name={name} imgSize={imgSize}>
                {Boolean(label) && <label htmlFor={label}>{label}</label>}
                <img src={imgSrc} alt='' onClick={handleClick}/>
            </Root>
            <UserProfilePictureModal
                enabled={editModalEnabled}
                onConfirm={handleProfilePictureChanged}
                onCancel={handleProfilePictureCanceled}
            />
        </>
    );
};

export default ImageSelector;
