import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrajectAction } from 'models/traject';
import { useTranslationTransform } from 'translations/translation';
import { Description, SubHeader } from 'components/text';
import {
    RootContainer,
    RootMotivationContainer,
    RootMotivationBG,
    MotivationIconContainer
} from 'pages/traject/styling';
import assets from 'assets';
import { ICONS } from 'assets/icons';

type Props = {
    actionOfTheDay: TrajectAction | undefined;
};

type MotivationText = {
    TEXT: string;
    BG: string;
};

enum MotivationStatus {
    TODO = 'TODO',
    DONE_POSITIVE = 'DONE_POSITIVE',
    DONE_NEGATIVE = 'DONE_NEGATIVE'
}

const getMotivationStatus = (
    actionOfTheDay: TrajectAction
): MotivationStatus => {
    let output = MotivationStatus.TODO;

    if (actionOfTheDay.hasCompletedPositive) {
        output = MotivationStatus.DONE_POSITIVE;
    }

    if (actionOfTheDay.hasCompletedNegative) {
        output = MotivationStatus.DONE_NEGATIVE;
    }

    return output;
};

const RenderActionOfTheDay: React.FC<Props> = ({ actionOfTheDay }: Props) => {
    const getMotivationText = (): MotivationText => {
        if (!actionOfTheDay) {
            return {
                TEXT: '',
                BG: ''
            };
        }

        const output = getMotivationStatus(actionOfTheDay);

        return {
            TEXT: t(`TRAJECT.CURRENT.MOTIVATIONS.${output}.TEXT`),
            BG: t(`TRAJECT.CURRENT.MOTIVATIONS.${output}.BG`)
        };
    };

    const translateTransform = useTranslationTransform();
    const { t } = useTranslation();
    const [motivationText, setMotivationText] = useState<MotivationText>(
        getMotivationText()
    );

    useEffect(() => {
        setMotivationText(getMotivationText());
    }, [actionOfTheDay]);

    if (!actionOfTheDay) {
        return null;
    }

    const getIconForMotivation = () => {
        const motivationStatus = getMotivationStatus(actionOfTheDay);

        switch (motivationStatus) {
            case MotivationStatus.TODO:
            case MotivationStatus.DONE_NEGATIVE:
                return (
                    <assets.ARROW_SWIRL_RIGHT_BOTTOM className='icon arrow' />
                );

            case MotivationStatus.DONE_POSITIVE:
                return <ICONS.THUMBS_UP className='icon thumbs' />;

            default:
                return null;
        }
    };

    return (
        <RootContainer>
            <img
                className='img'
                src={actionOfTheDay.image}
                alt={translateTransform(actionOfTheDay.title)}
            />

            <SubHeader className='title'>
                {translateTransform(actionOfTheDay.title)}
            </SubHeader>

            <Description className='description'>
                {translateTransform(actionOfTheDay.description)}
            </Description>

            <RootMotivationContainer>
                <RootMotivationBG>
                    <span className='text'>{motivationText.TEXT}</span>
                </RootMotivationBG>
                <span className='bg'>{motivationText.BG}</span>
                <MotivationIconContainer>
                    {getIconForMotivation()}
                </MotivationIconContainer>
            </RootMotivationContainer>
        </RootContainer>
    );
};

export default RenderActionOfTheDay;
