import backgrounds from 'assets/backgrounds';
import arrows from 'assets/arrows';
import { ReactComponent as MALE } from 'assets/male.svg';
import { ReactComponent as FEMALE } from 'assets/female.svg';
import { ReactComponent as OTHER_SEX } from 'assets/other_sex.svg';
import { ReactComponent as USER } from 'assets/user.svg';
import { ReactComponent as USA_FLAG } from 'assets/flags/us.svg';
import { ReactComponent as FRENCH_FLAG } from 'assets/flags/fr.svg';
import { ReactComponent as DUTCH_FLAG } from 'assets/flags/nl.svg';
import { ReactComponent as CLOSE_CROSS } from 'assets/close_cross.svg';
import { ReactComponent as GARMIN } from 'assets/integrations/garmin.svg';
import { ReactComponent as FORM_ERROR } from 'assets/info-circle.svg';
import { ReactComponent as BACK_ARROW } from 'assets/back_arrow.svg';
import { ReactComponent as ONBOARDING_STEP_1 } from 'assets/onboarding/step_1.svg';
import { ReactComponent as ONBOARDING_STEP_2 } from 'assets/onboarding/step_2.svg';
import { ReactComponent as ONBOARDING_STEP_3 } from 'assets/onboarding/step_3.svg';
import { ReactComponent as BUTTON_STEPS } from 'assets/button/button-steps.svg';
import { ReactComponent as BUTTON_STEPS_NO_BORDER } from 'assets/button/button-steps-no-border.svg';
import { ReactComponent as LOCATION } from 'assets/location.svg';
import { ReactComponent as SELECT } from 'assets/select_icon.svg';
import { ReactComponent as TIP_AND_TRICK_LIGHT_BULB } from 'assets/tip_and_trick_light_bulb.svg';

import { ReactComponent as USER_PROFILE_EDIT } from 'assets/user_profile_edit.svg';
import { ReactComponent as POINTS } from 'assets/level/points.svg';
import { ReactComponent as LEVEL } from 'assets/level/level.svg';
import { ReactComponent as LEVEL_ACTIVE } from 'assets/level/level-active.svg';
import { ReactComponent as LEVEL_DISABLED } from 'assets/level/level-disabled.svg';
import { ReactComponent as COMMUNITY } from 'assets/community_link.svg';
import { ReactComponent as EXERCISE } from 'assets/exercise_link.svg';
import { ReactComponent as GOAL } from 'assets/goal_link.svg';
import { ReactComponent as PROFILE } from 'assets/profile_link.svg';
import { ReactComponent as ARROW_LEFT } from 'assets/arrow_left.svg';
import { ReactComponent as COMING_SOON } from 'assets/challenge/soon_bg.svg';

import { ReactComponent as EMPTY_GOALS } from 'assets/empty/goals.svg';
import { ReactComponent as HOME } from 'assets/home.svg';
import { ReactComponent as HOME_FILLED } from 'assets/home_filled.svg';

const onboarding = {
    ONBOARDING_STEP_1_ICON: ONBOARDING_STEP_1,
    ONBOARDING_STEP_2_ICON: ONBOARDING_STEP_2,
    ONBOARDING_STEP_3_ICON: ONBOARDING_STEP_3
};

const empty = {
    EMPTY_GOALS_ICON: EMPTY_GOALS
};

const assets: any = {
    LOGO_BLUE: 'https://d31oc3nzsr1xf5.cloudfront.net/general/logo_blue.png',
    LOGO_WHITE_BLUEBG:
        'https://d31oc3nzsr1xf5.cloudfront.net/general/logo_white_bluebg.png',
    MALE_ICON: MALE,
    FEMALE_ICON: FEMALE,
    OTHER_SEX_ICON: OTHER_SEX,
    USER_ICON: USER,
    USA_FLAG_ICON: USA_FLAG,
    FRENCH_FLAG_ICON: FRENCH_FLAG,
    DUTCH_FLAG_ICON: DUTCH_FLAG,
    CLOSE_CROSS_ICON: CLOSE_CROSS,
    GARMIN_ICON: GARMIN,
    COMMUNITY_ICON: COMMUNITY,
    EXERCISE_ICON: EXERCISE,
    GOAL_ICON: GOAL,
    PROFILE_ICON: PROFILE,
    WEIGHT_LOSS_ICON: '/dummy/weight_loss.jpg',
    ARROW_LEFT_ICON: ARROW_LEFT,
    CONFUSED_ICON: '/dummy/confused.png',
    NOT_HAPPY_ICON: '/dummy/not_happy.png',
    HAPPY_ICON: '/dummy/happy.png',
    ANGRY_ICON: '/dummy/angry.png',
    HEADER_BACKGROUND: '/dummy/header_background.png',
    NOTIFICATION_ICON: '/dummy/notification.png',
    WAVING_HAND_ICON: '/dummy/waving_hand.png',
    LEVEL_ICON: LEVEL,
    WOMAN_RUNNING: '/dummy/woman_running.png',
    COMING_SOON_BACKGROUND: '/dummy/coming_soon.png',
    COMING_SOON_SHAPE: COMING_SOON,
    FORM_ERROR_ICON: FORM_ERROR,
    BACK_ARROW_ICON: BACK_ARROW,
    BUTTON_STEPS_ICON: BUTTON_STEPS,
    BUTTON_STEPS_NO_BORDER_ICON: BUTTON_STEPS_NO_BORDER,
    LOCATION_ICON: LOCATION,
    USER_PROFILE_EDIT_ICON: USER_PROFILE_EDIT,
    POINTS_ICON: POINTS,
    LEVEL_ACTIVE_ICON: LEVEL_ACTIVE,
    LEVEL_DISABLED_ICON: LEVEL_DISABLED,
    SELECT_ICON: SELECT,
    HOME_ICON: HOME,
    HOME_FILLED_ICON: HOME_FILLED,
    DEFAULT_AVATAR: '/dummy/default_avatar.jpeg',
    TIP_AND_TRICK_LIGHT_BULB_ICON: TIP_AND_TRICK_LIGHT_BULB,
    ...empty,
    ...onboarding,
    ...backgrounds,
    ...arrows
};

export default assets;
