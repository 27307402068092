import styled from 'styled-components';
import theme from 'utils/theme';

export const TipAndTrick = styled.div`
    margin: 0 -${theme.space.page};
    padding: 24px;
    background-color: #e8e9f0;
    display: flex;
    align-items: center;
    gap: 32px;
    border-radius: 4px;
    font-style: italic;
    font-family: MetropolisSemiBold, MetropolisRegular, -apple-system,
        BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    .icon {
        width: 50px;
        height: 50px;
    }

    .text {
        flex: 1;
    }

    ${theme.mediaQueries.isTablet} {
        margin: 0;
    }
`;

export const RootMotivationContainer = styled.div`
    grid-area: motivation;
    margin: 20px 0;
    color: ${theme.colors.white};

    position: relative;

    .text {
        font-weight: bold;
        text-align: center;
        font-size: 20px;
    }

    .bg {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotateZ(12deg);
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 8px;
        white-space: nowrap;
    }
`;

export const RootMotivationBG = styled.div`
    z-index: 5;
    position: relative;
    background: rgba(254, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    padding: 16px 20px;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const MotivationIconContainer = styled.div`
    .icon {
        position: absolute;
        right: 24px;
        z-index: 6;

        &.arrow {
            bottom: -42px;
        }

        &.thumbs {
            bottom: -12px;
            font-size: 32px;
            transform: rotateZ(-12deg);
        }
    }
`;

export const RootContainer = styled.div`
    display: grid;
    grid-template-areas:
        'img title'
        'img description'
        'motivation motivation'
        'submit submit';
    grid-template-columns: 150px 1fr;
    grid-template-rows: auto 1fr;
    grid-row-gap: 8px;

    ${theme.mediaQueries.isDesktop} {
        grid-template-areas:
            'img title motivation'
            'img description motivation'
            'submit submit';
        grid-template-columns: 150px 2fr 3fr;

        ${RootMotivationContainer} {
            margin: 0;
        }
    }

    .img {
        grid-area: img;
        max-width: 120px;
        max-height: 120px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
    }

    .title {
        grid-area: title;
    }

    .description {
        grid-area: description;
        color: ${theme.colors.white};
    }

    .submit {
        grid-area: submit;
        max-width: 400px;
        width: 100%;
        margin: auto;
        margin-top: 40px;
    }
`;
