export enum LocalstorageKeys {
    AUTH_TOKEN = 'COACH_ME_1',
    USER = 'COACH_ME_2'
}

export function clearStorage(): void {
    Object.values(LocalstorageKeys).forEach((localstorageKey) =>
        localStorage.removeItem(localstorageKey)
    );
}
