import React, { useEffect, useState } from 'react';
import { Translation } from 'models/translation';
import { useTranslation } from 'react-i18next';

type Props = {
    translation: Translation;
};

export const TranslationTransform: React.FC<Props> = ({
    translation
}: Props) => {
    const { i18n } = useTranslation();
    const [text, setText] = useState('');

    useEffect(() => {
        setText(translation.getText(i18n.language));
    }, [i18n, translation]);

    return <>{text}</>;
};

export const useTranslationTransform = () => {
    const { i18n } = useTranslation();
    return (translation: Translation): string => {
        return translation.getText(i18n.language);
    };
};
