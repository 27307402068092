import JSConfetti from 'js-confetti';

const jsConfetti = new JSConfetti();

export async function showConfetti(): Promise<void> {
    await Promise.all([
        jsConfetti.addConfetti(),
        jsConfetti.addConfetti(),
        jsConfetti.addConfetti(),
        jsConfetti.addConfetti(),
        jsConfetti.addConfetti()
    ]);
}
