import { Description } from 'components/text';
import { Statistic } from 'models/statistic';
import { StyledStatisticContainer } from 'pages/statistics/styling';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACES } from 'translations';

type Props = {
    statistics: Statistic[];
};
export const RenderGeneralStatistic: React.FC<Props> = ({ statistics }) => {
    const { t } = useTranslation(TRANSLATION_NAMESPACES.STATISTICS);

    return (
        <div className='parent-statistic-container'>
            {statistics.map((statistic: Statistic) => (
                <StyledStatisticContainer
                    key={statistic.event}
                    iconColor={statistic.color}
                >
                    <div className='icon-container'>{statistic.icon}</div>
                    <div className='statistic-info'>
                        <Description>
                            {t(`${statistic.event}.TITLE`)}
                        </Description>
                        <p className='statistic-number'>{statistic.amount}</p>
                    </div>
                </StyledStatisticContainer>
            ))}
        </div>
    );
};
