import { getValueFromObject } from 'utils/get';
import assets from 'assets';

export class Article {
    private readonly _id: string;
    private readonly _title: string;
    private readonly _content: string;
    private readonly _coverPicture: string;
    private readonly _editor: string;
    private _bookmarked: boolean;
    private _liked: boolean;

    constructor(article: any) {
        this._id = getValueFromObject(article, 'id', '');
        this._title = getValueFromObject(article, 'title', '');
        this._content = getValueFromObject(article, 'content', '');
        this._coverPicture =
            getValueFromObject(article, 'cover_picture', assets.LOGO_WITH_BG) ||
            assets.LOGO_WITH_BG;
        this._editor = getValueFromObject(article, 'editor', '');
        this._bookmarked = getValueFromObject(
            article,
            'data.is_bookmarked',
            false
        );
        this._liked = getValueFromObject(article, 'data.has_liked', false);
    }

    get id(): string {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get content(): string {
        return this._content;
    }

    get contentShort(): string {
        const helpDiv = document.createElement('div');
        const outputDiv = document.createElement('div');

        helpDiv.innerHTML = this._content;

        const allImages = helpDiv.getElementsByTagName('img');
        for (let i = 0; i < allImages.length; i++) {
            if (allImages.item(i)) {
                allImages.item(i)!.remove();
            }
        }

        for (let i = 0; i < helpDiv.childElementCount; i++) {
            const child = helpDiv.children.item(i);
            if (!child) {
                continue;
            }
            outputDiv.innerHTML += child.innerHTML;

            if (outputDiv.innerText.length >= 500) {
                break;
            }
        }

        const allChildren = outputDiv.querySelectorAll('*');
        for (let i = 0; i < allChildren.length; i++) {
            const item = allChildren.item(i);
            if (!item) {
                continue;
            }
            item.removeAttribute('style');
        }

        return outputDiv.innerHTML;
    }

    get coverPicture(): string {
        return this._coverPicture;
    }

    get editor(): string {
        return this._editor;
    }

    get readingTime(): number {
        const wpm = 280; // WORDS PER MINUTE
        const words = this._contentPreview().trim().split(/\s+/).length;
        return Math.ceil(words / wpm);
    }

    get isBookmarked(): boolean {
        return this._bookmarked;
    }

    set isBookmarked(bookmarked: boolean) {
        this._bookmarked = bookmarked;
    }

    get hasLiked(): boolean {
        return this._liked;
    }

    set hasLiked(liked: boolean) {
        this._liked = liked;
    }

    private _contentPreview(): string {
        const helper = document.createElement('span');
        helper.innerHTML = this._content;
        return helper.innerText;
    }
}
