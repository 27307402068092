import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Achievement } from 'models/achievement';
import { getAchievements } from 'api/achievements';
import ACHIEVEMENT_LIST from 'pages/userProfile/achievements/list';
import { TRANSLATION_NAMESPACES } from 'translations';
import { Description } from 'components/text';
import {
    Root,
    AchievementItem,
    LevelsContainer,
    NextLevel
} from 'pages/userProfile/achievements/styling';

const Achievements: React.FC = () => {
    const { t } = useTranslation(TRANSLATION_NAMESPACES.ACHIEVEMENTS);
    const [achievements, setAchievements] = useState<Achievement[]>([]);

    const fetchAchievements = async () => {
        try {
            const response = await getAchievements();
            setAchievements(response);
        } catch (e) {
            console.log(e);
        }
    };

    const findAchievement = (
        achievement: Achievement
    ): Achievement | undefined => {
        return achievements.find(
            (a: Achievement) => a.type === achievement.type
        );
    };

    const hasAchievementALevel = (achievement: Achievement): boolean => {
        return Boolean(findAchievement(achievement));
    };

    useEffect(() => {
        fetchAchievements();
    }, []);

    return (
        <Root>
            {ACHIEVEMENT_LIST.map((achievement: Achievement) => {
                const activeAchievement = findAchievement(achievement);

                return (
                    <AchievementItem
                        key={achievement.type}
                        hasLevel={hasAchievementALevel(achievement)}
                    >
                        <span className='achievement-icon'>
                            {achievement.icon}
                        </span>
                        <span className='achievement-title'>
                            {t(`${achievement.type}.TITLE`)}
                        </span>
                        <Description className='achievement-content'>
                            {t(`${achievement.type}.CONTENT`)}
                        </Description>
                        {activeAchievement && (
                            <LevelsContainer>
                                <NextLevel
                                    levelWidth={
                                        (activeAchievement.currentAmount /
                                            activeAchievement.nextLevelAmount) *
                                        100
                                    }
                                />
                                <div className='amounts'>
                                    <span>
                                        {activeAchievement.currentAmount}
                                    </span>
                                    <span className='divider'>/</span>
                                    <span>
                                        {activeAchievement.nextLevelAmount}
                                    </span>
                                </div>
                            </LevelsContainer>
                        )}
                    </AchievementItem>
                );
            })}
        </Root>
    );
};

export default Achievements;
