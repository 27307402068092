import React from 'react';
import { Root } from 'pages/onboarding/info/styling';
import StepsButton from 'components/button/steps';

type Props = {
    imgElement: React.FC;
    title: string;
    description: string;
    step: number;
    amountOfSteps: number;
    onNext: () => void;
};

const InfoOnBoarding: React.FC<Props> = ({
    imgElement: ImgElement,
    title,
    description,
    step,
    amountOfSteps,
    onNext
}: Props) => {
    const handleNext = () => {
        onNext();
    };

    return (
        <Root>
            <div className='img'>
                <ImgElement />
            </div>
            <h1 className='title'>{title}</h1>
            <p className='description'>{description}</p>
            <StepsButton
                step={step}
                amountOfSteps={amountOfSteps}
                onClick={handleNext}
            />
        </Root>
    );
};

export default InfoOnBoarding;
