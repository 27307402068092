import styled from 'styled-components';
import theme from 'utils/theme';

export const Answer = styled.div<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px ${theme.colors.primary};
    border-radius: 4px;
    cursor: pointer;
    border: 0.5px solid ${theme.colors.border};
    padding: 26px 20px;
    transition: all 0.05s linear 0s;
    background-color: #7070702b;

    ${({ isActive }) =>
        isActive &&
        `
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
    `}
`;
