import { Action } from 'store';

// const ROUTE_ADD = 'ROUTE_ADD';
// const ROUTE_REPLACE = 'ROUTE_REPLACE';
// const ROUTE_REMOVE = 'ROUTE_REMOVE';
const ROUTE_CHANGE = 'ROUTE_CHANGE';

export type State = string[];

export const routeReducer = (state: State = [], action: Action) => {
    switch (action.type) {
        case ROUTE_CHANGE: {
            return [...state, action.payload];
        }

        // case ROUTE_ADD: {
        //     return [...state, action.payload];
        // }
        // case ROUTE_REPLACE: {
        //     const newState = [...state, action.payload];
        //     newState.pop();
        //     return newState;
        // }
        // case ROUTE_REMOVE: {
        //     const newState = [...state];
        //     newState.pop();
        //     return newState;
        // }
        default:
            return state;
    }
};

export function routeChange(url: string): Action {
    return {
        type: ROUTE_CHANGE,
        payload: url
    };
}

// export function addRoute(url: string): Action {
//     return {
//         type: ROUTE_ADD,
//         payload: url
//     };
// }

// export function replaceRoute(url: string): Action {
//     return {
//         type: ROUTE_REPLACE,
//         payload: url
//     };
// }

// export function removeRoute(): Action {
//     return { type: ROUTE_REMOVE };
// }
