import { QUESTION_TYPE } from 'models/question';

export const questions = {
    firstName: {
        id: 'firstName',
        text: {
            nl: 'Wat is jouw voornaam?',
            en: 'What is your first name?',
            fr: 'Quelle est votre surnom?'
        },
        type: QUESTION_TYPE.TEXT
    },
    lastName: {
        id: 'lastName',
        text: {
            nl: 'Wat is jouw achternaam?',
            en: 'What is your last name?',
            fr: 'Quelle est votre nom de famille?'
        },
        type: QUESTION_TYPE.TEXT
    },
    sex: {
        id: 'sex',
        text: {
            nl: 'Wat is jouw geslacht?',
            en: 'What is your gender?',
            fr: 'Quelle est votre sexe?'
        },
        type: QUESTION_TYPE.GENDER
    },
    birthDate: {
        id: 'birthDate',
        text: {
            nl: 'Wat is jouw geboortedatum?',
            en: 'What is your birthdate?',
            fr: 'Quelle est votre date de naissance?'
        },
        type: QUESTION_TYPE.DATE_TIME
    },
    profilePicture: {
        id: 'profilePicture',
        text: {
            nl: 'Selecteer een profielfoto',
            en: 'Select a profile picture',
            fr: 'Selecter une photo de profil'
        },
        type: QUESTION_TYPE.AVATAR
    }
};
