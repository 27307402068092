import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentDesktop, HomeLayoutDesktop } from 'pages/home/styling';
import Header from 'pages/home/header';
import MoodBox from 'pages/home/mood-box';
import HomeChallenge from 'components/home-challenge';
import { ROUTES } from 'routes/list';
import HomeProfile from 'pages/home/profile';
import TrajectCurrent from 'pages/traject/current';
import SeeAllHeader from 'pages/home/see-all-header';
import TeamEvents from 'pages/home/team-events';

const HomeDesktopPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <HomeLayoutDesktop hasNavBar>
            <ContentDesktop>
                <Header />
                <HomeProfile />
                <HomeChallenge />
                <MoodBox />
                <div className='traject'>
                    <SeeAllHeader
                        title={t('HOME.TRAJECT.TITLE')}
                        navPath={ROUTES.TRAJECT.path()}
                    />
                    <TrajectCurrent />
                </div>
                <TeamEvents />
            </ContentDesktop>
        </HomeLayoutDesktop>
    );
};

export default HomeDesktopPage;
