import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import styled from 'styled-components';

type Props = {
    value: any;
    label: string;
    className: string | undefined;
    onChange: (value: any) => void;
};

const Root = styled(FormGroup)`
    flex-direction: unset !important;
`;

const BooleanField: React.FC<Props> = ({
    value,
    label,
    className = '',
    onChange
}: Props) => {
    const handleChange = (event: any) => {
        onChange(event.target.checked);
    };

    return (
        <Root>
            <FormControlLabel
                control={<Switch />}
                label={label}
                checked={value}
                labelPlacement='start'
                className={className}
                onChange={handleChange}
            />
        </Root>
    );
};

export default BooleanField;
