const generalError = 'ERRORS.GENERAL';
const accessDeniedError = 'ERRORS.ACCESS_DENIED';

const apiErrors = [
    {
        key: 'Enter a valid email address.',
        value: 'ERRORS.EMAIL_1'
    },
    {
        key: 'Code could not be found',
        value: 'ERRORS.CODE_1'
    },
    {
        key: 'Not found.',
        value: 'ERRORS.NOT_FOUND'
    }
];

export function transform(data: any): string {
    try {
        if (data && data.detail) {
            return handleDetailKey(data);
        }

        const dataIntoArray = Object.keys(data).map(
            (dataKey) => data[dataKey][0]
        );
        const key = dataIntoArray[0];
        const findKey = apiErrors.find((apiError) => apiError.key === key);
        return findKey && findKey.value ? findKey.value : generalError;
    } catch (e) {
        console.log(e);
        return generalError;
    }
}

export function throwGeneralError(): string {
    return generalError;
}

export function throwAccessDeniedError(): string {
    return accessDeniedError;
}

function handleDetailKey(data: any) {
    const key = data.detail;
    const findKey = apiErrors.find((apiError) => apiError.key === key);
    return findKey && findKey.value ? findKey.value : generalError;
}
