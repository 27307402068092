import { useSelector } from 'react-redux';
import { Store } from 'store';
import { USER_ROLES } from 'utils/enums';

const useHasAccess = (accessRole: USER_ROLES) => {
    const user = useSelector((store: Store) => store.user);

    switch (accessRole) {
        case USER_ROLES.HR:
            return user.isHr();
        case USER_ROLES.ADMIN:
            return user.isAdmin();
        default:
            return false;
    }
};

export { useHasAccess };
