import React from 'react';
import SingleApiRender, {
    Props as SingleApiRenderProps
} from 'components/api-render/single';
import { PageRoot } from 'pages';
import styled from 'styled-components';
import theme from 'utils/theme';
import PageHeader from 'components/page-header';

export type DetailItemType = {
    title: string;
    asset: React.ReactNode;
};

type Props = SingleApiRenderProps<any> & {
    getItemType: (item: any) => DetailItemType;
};

const Root = styled(PageRoot)``;

const AssetContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25vh;
    margin-bottom: 32px;

    ${theme.mediaQueries.isTablet} {
        height: 30vh;
    }

    ${theme.mediaQueries.isDesktop} {
        height: 35vh;
    }

    img {
        height: 96%;
        object-fit: contain;
        border-radius: 20px;
        z-index: 10;
    }

    .effect {
        position: absolute;
        inset: 0;
        opacity: 0.4;
        filter: blur(10px);

        img {
            height: 100%;
            width: calc(100% + 2 * ${theme.space.page});
            object-fit: cover;
            z-index: 1;
        }
    }
`;

const DetailPageLayout: React.FC<Props> = ({
    keyState,
    promiseCall,
    renderItem,
    getItemType,
    onActionClick,
    ...otherProps
}: Props) => {
    return (
        <SingleApiRender
            keyState={keyState}
            promiseCall={promiseCall}
            renderItem={(item: any) => {
                const itemDetail = getItemType(item);
                return (
                    <Root>
                        <AssetContainer>
                            <div className='effect'>{itemDetail.asset}</div>
                            {itemDetail.asset}
                        </AssetContainer>
                        <PageHeader title={itemDetail.title} />
                        {renderItem(item)}
                    </Root>
                );
            }}
            onActionClick={onActionClick}
            {...otherProps}
        />
    );
};

export default DetailPageLayout;
