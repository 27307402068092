import React, { useState } from 'react';
import dayjs from 'dayjs';
import { EmptyStateKey } from 'components/states/empty';
import { TeamEvent } from 'models/community';
import EventBox from 'pages/team-events/event-box';
import ListApiRender from 'components/api-render/list';
import { getTeamEvents } from 'api/team-events';
import styled from 'styled-components';
import theme from 'utils/theme';
import { STATES } from 'utils/enums';

type Props = {
    activeDay: dayjs.Dayjs;
    onActionClick?: () => void;
    onStateChanged?: Function;
};

const Root = styled.div<{ hasTeamEvents: boolean }>`
    .render-helper {
        display: grid;
        grid-gap: 24px;

        ${theme.mediaQueries.isTablet} {
            grid-template-columns: 1fr ${({ hasTeamEvents }) =>
                    hasTeamEvents ? '1fr' : ''};
        }
    }
`;

const ListTeamEvents: React.FC<Props> = ({
    activeDay,
    onActionClick,
    onStateChanged = () => {}
}: Props) => {
    const [hasTeamEvents, setHasTeamEvents] = useState(false);

    const handleTeamEventsChanged = (newState: STATES) => {
        setHasTeamEvents(newState === STATES.RESULTS);
        onStateChanged(newState);
    };

    const handleFetchCall = (): Promise<TeamEvent[]> => {
        return getTeamEvents(activeDay, activeDay);
    };

    return (
        <Root hasTeamEvents={hasTeamEvents}>
            <ListApiRender
                keyState={EmptyStateKey.NO_TEAM_EVENTS}
                renderItem={(teamEvent: TeamEvent) => (
                    <EventBox key={teamEvent.id} event={teamEvent} />
                )}
                promiseCall={handleFetchCall}
                promiseParams={activeDay}
                onActionClick={onActionClick}
                onStateChanged={handleTeamEventsChanged}
            />
        </Root>
    );
};

export default ListTeamEvents;
