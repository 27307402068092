import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from 'components/button';
import DateTimePicker from 'components/date-time-picker';
import Form from 'components/form';
import { TeamEvent } from 'models/community';
import InputField from 'components/text-field';
import { IntensityTypes } from 'utils/enums';
import CommunityIntensitySelector from 'pages/team-events/form/selectIntensity';
import useFormValidation, { FormValidations } from 'hooks/useValidation';
import dayjs from 'dayjs';
import { getValueFromObject } from 'utils/get';
import { useSearchParams } from 'react-router-dom';

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;

    .submit {
        margin-top: auto;
    }
`;

type Props = {
    event?: TeamEventFormType;
    isSubmitting?: boolean;
    onSubmit: (formValues: TeamEventFormType) => void;
};

export type TeamEventFormType = {
    activity: string;
    title: string;
    intensity: IntensityTypes | string;
    location: string;
    beginDate: dayjs.Dayjs;
    duration: number;
    description: string;
};

const TeamEventsForm: React.FC<Props> = ({ event, isSubmitting, onSubmit }) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const handleChange = (key: string, value: any) => {
        setFormValues((prev: TeamEvent) => ({ ...prev, [key]: value }));
    };

    const handleSubmit = (formValues: TeamEventFormType) => {
        if (onSubmit) {
            onSubmit(formValues);
        }
    };

    const getDefaultBeginDate = () => {
        const beginDate = searchParams.get('begin');
        const beginDateDayJS = dayjs(beginDate, 'DD-MM-YYYY');
        return beginDateDayJS.isValid() ? beginDateDayJS : dayjs();
    };

    const { formValues, setFormValues, formErrors, setIsSubmitted } =
        useFormValidation(
            {
                title: [FormValidations.REQUIRED],
                location: [FormValidations.REQUIRED],
                begin_date: [FormValidations.REQUIRED],
                duration: [FormValidations.REQUIRED]
            },
            {
                title: getValueFromObject(event, 'title', ''),
                location: getValueFromObject(event, 'location', ''),
                beginDate: getValueFromObject(
                    event,
                    'beginDate',
                    getDefaultBeginDate()
                ),
                duration: getValueFromObject(event, 'duration', ''),
                description: getValueFromObject(event, 'description', ''),
                intensity: getValueFromObject(
                    event,
                    'intensity',
                    IntensityTypes.MEDIUM
                )
            },
            handleSubmit
        );

    return (
        <StyledForm onHasSubmitted={setIsSubmitted}>
            <InputField
                type='text'
                value={formValues.title}
                placeholder={t('COMMUNITY_ADD.FORM.TITLE.PLACEHOLDER')}
                label={t('COMMUNITY_ADD.FORM.TITLE.LABEL')}
                onChange={(value: any) => handleChange('title', value)}
                errorText={t(formErrors.title)}
            />
            <InputField
                type='text'
                value={formValues.location}
                placeholder={t('COMMUNITY_ADD.FORM.LOCATION.PLACEHOLDER')}
                label={t('COMMUNITY_ADD.FORM.LOCATION.LABEL')}
                onChange={(value: string) => handleChange('location', value)}
                errorText={t(formErrors.location)}
            />
            <DateTimePicker
                label={t('COMMUNITY_ADD.FORM.BEGIN_DATE')}
                value={formValues.beginDate}
                onChange={(value: string) => handleChange('beginDate', value)}
            />
            <InputField
                type='number'
                value={formValues.duration}
                placeholder={t('COMMUNITY_ADD.FORM.DURATION.PLACEHOLDER')}
                label={t('COMMUNITY_ADD.FORM.DURATION.LABEL')}
                onChange={(value: number) => handleChange('duration', value)}
                errorText={t(formErrors.duration)}
            />
            <InputField
                type='text'
                value={formValues.description}
                label={t('COMMUNITY_ADD.FORM.DESCRIPTION.LABEL')}
                placeholder={t('COMMUNITY_ADD.FORM.DESCRIPTION.PLACEHOLDER')}
                onChange={(value: string) => handleChange('description', value)}
            />
            <CommunityIntensitySelector
                value={formValues.intensity}
                onChange={(value: IntensityTypes) =>
                    handleChange('intensity', value)
                }
            />
            <Button type='submit' className='submit' isLoading={isSubmitting}>
                {t('COMMUNITY_ADD.FORM.SUBMIT')}
            </Button>
        </StyledForm>
    );
};

export default TeamEventsForm;
