import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import assets from 'assets';
import {
    NavBar as StyledNavBar,
    NavLink,
    NavLogo
} from 'components/navbar/styling';
import { ROUTES } from 'routes/list';

const NavBar: React.FC = () => {
    const { t } = useTranslation();
    const loc = useLocation();

    const getClassName = (route: any, extraClasses: string = ''): string => {
        const classes: string[] = [];
        const isActive = loc.pathname === route.path();

        if (isActive) {
            classes.push('active');
        }
        classes.push(extraClasses);

        return classes.join(' ');
    };

    return (
        <StyledNavBar className='nav-bar'>
            <NavLogo to={ROUTES.HOME.path()}>
                <img src={assets.LOGO_BLUE} alt='Coach Me logo' />
            </NavLogo>
            <NavLink
                to={ROUTES.HOME.path()}
                className={getClassName(ROUTES.HOME, 'home')}
            >
                {loc.pathname === ROUTES.HOME.path() ? (
                    <assets.HOME_FILLED_ICON />
                ) : (
                    <assets.HOME_ICON />
                )}

                <p>{t('NAVBAR.HOME')}</p>
            </NavLink>
            <NavLink
                to={ROUTES.TRAJECT.path()}
                className={getClassName(ROUTES.TRAJECT)}
            >
                <assets.GOAL_ICON />
                <p>{t('NAVBAR.TRAJECT')}</p>
            </NavLink>
            <NavLink
                to={ROUTES.COMMUNITY.path()}
                className={getClassName(ROUTES.COMMUNITY)}
            >
                <assets.COMMUNITY_ICON />
                <p>{t('NAVBAR.COMMUNITY')}</p>
            </NavLink>
            <NavLink
                to={ROUTES.USER_PROFILE.path()}
                className={getClassName(ROUTES.USER_PROFILE)}
            >
                <assets.PROFILE_ICON />
                <p>{t('NAVBAR.PROFILE')}</p>
            </NavLink>
        </StyledNavBar>
    );
};

export default NavBar;
