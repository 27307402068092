import React, { BaseSyntheticEvent } from 'react';
import ButtonStyle from 'components/button/styling';
import { ButtonVariantType, ButtonTypeType } from 'components/button/types';
import { LoadingButton } from 'components/button/loading';

type Props = {
    children: React.ReactNode;
    className?: any;
    type?: ButtonTypeType;
    variant?: ButtonVariantType;
    disabled?: boolean;
    onClick?: (event: any) => void;
    isLoading?: boolean;
};

const Button: React.FC<Props> = ({
    children,
    className = '',
    type = 'button',
    variant = 'primary',
    disabled = false,
    onClick,
    isLoading = false
}: Props) => {
    const handleClick = (event: BaseSyntheticEvent) => {
        if (onClick) {
            onClick(event);
        }
    };

    const getLoadingStateOrChildren = () => {
        if (isLoading) {
            return (
                <LoadingButton>
                    <div className='dot-flashing'></div>
                </LoadingButton>
            );
        }
        return children;
    };

    return (
        <ButtonStyle
            className={`button ${className}`}
            type={type}
            variant={variant}
            btnDisabled={disabled}
            onClick={handleClick}
        >
            {getLoadingStateOrChildren()}
        </ButtonStyle>
    );
};

export default Button;
