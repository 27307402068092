import React, { useState } from 'react';
import styled from 'styled-components';
import { Answer } from 'pages/assessment/inputs/styling';

const StyledScaleInput = styled.div`
    .scale-container {
        display: grid;
        gap: 8px;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, 56px);
    }

    .error {
        margin-top: 16px;
        margin-left: 16px;
        color: #d32f2f;
    }
`;

type Props = {
    amount: number;
    onChange: (amount: number) => void;
    errorText: string;
};

const ScaleInput: React.FC<Props> = ({ amount, onChange, errorText }) => {
    const [activeIndex, setActiveIndex] = useState<number>();

    const numberClick = (index: number) => {
        setActiveIndex(index);
        makeAnimation(index);
        onChange(index + 1);
    };

    const makeAnimation = (index: number) => {
        setTimeout(() => {
            setActiveIndex(-1);
            setTimeout(() => {
                setActiveIndex(index);
            }, 100);
        }, 100);
    };

    return (
        <StyledScaleInput>
            <div className='scale-container'>
                {[...Array(amount).keys()].map((number) => (
                    <Answer
                        key={number}
                        isActive={activeIndex === number}
                        onClick={() => numberClick(number)}
                    >
                        {number + 1}
                    </Answer>
                ))}
            </div>
            {errorText && <div className='error'>{errorText}</div>}
        </StyledScaleInput>
    );
};

export default ScaleInput;
