import { DateTimePicker as MuiDateTimePicker } from '@mui/lab';
import TextField from '@mui/material/TextField';
import { Dayjs } from 'dayjs';
import React from 'react';

type Props = {
    label: string;
    value: Dayjs;
    onChange: Function;
};

const DateTimePicker: React.FC<Props> = ({
    label,
    value = new Date(),
    onChange
}) => {
    return (
        <MuiDateTimePicker
            label={label}
            value={value}
            inputFormat='DD/MM/YYYY HH:mm'
            onChange={(target) => onChange(target)}
            renderInput={(params) => <TextField {...params} />}
        />
    );
};

export default DateTimePicker;
