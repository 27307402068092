import { Box } from 'components/box/styling';
import styled from 'styled-components';
import theme from 'utils/theme';

export const MoodBox = styled(Box)`
    .mood-active {
        background-color: #3b81fa !important;
    }

    h3 {
        font-weight: 600;
        font-size: 16px;
        color: #0c182d;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        color: #0c182d;
        opacity: 50%;
    }

    .mood-selection-container {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;

        img {
            object-fit: cover;
            width: auto;
            height: 30px;
        }
    }

    .mood-selection {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mood-img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: #f1f5fd;
        border-radius: 50%;
        margin-bottom: 4px;
        margin-top: 4px;
    }

    .submit-container {
        margin-top: 32px;

        input {
            border-color: ${theme.colors.border};
        }
    }

    .submit-buttons {
        margin-left: auto;
    }
`;

export const SubmittedContainer = styled(Box)`
    min-height: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;
