import { post } from 'api';
import { Auth, AuthLocal, AuthServer } from 'models/auth';

export async function getValidationCode(email: string): Promise<void> {
    await post('/api/v1/auth/get-code', { email }).then((res) => {
        Promise.resolve();
    });
}

export async function validateCode(email: string, code: number): Promise<Auth> {
    return await post('/api/v1/auth/validate-code', { email, code }).then(
        (res) => {
            return new AuthServer(res.data);
        }
    );
}

export async function logout(): Promise<void> {
    await post('/api/v1/auth/logout', new AuthLocal().getApiJSON());
}
