import React from 'react';
import { DatePicker as MuiDatePicker, DesktopDatePicker } from '@mui/lab';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
    value: Dayjs;
    label: string;
    errorText?: string;
    noPopup?: boolean;
    onChange: Function;
};

const DatePicker: React.FC<Props> = ({
    value = dayjs(),
    onChange,
    label,
    noPopup = false,
    errorText
}) => {
    const pickerProps = {
        label: label,
        inputFormat: 'DD/MM/YYYY',
        value: value,
        renderInput: (params: any) => {
            return (
                <TextField
                    sx={{ width: '100%' }}
                    {...params}
                    error={Boolean(errorText)}
                    helperText={errorText}
                />
            );
        },
        disableOpenPicker: noPopup,
        onChange: (target: any) => onChange(target),
        onError: () => 'invalidDate'
    };

    if (noPopup) {
        return <DesktopDatePicker {...pickerProps} />;
    }

    return <MuiDatePicker {...pickerProps} />;
};

export default DatePicker;
