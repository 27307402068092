import { AVAILABLE_LANGUAGES, AVAILABLE_SEX } from 'utils/enums';
import assets from 'assets';
import { Option } from 'components/form/bigSelector';
import { ICONS } from 'assets/icons';

export const getSexOptions = (t: any): Option[] => [
    {
        label: t(`USER-PROFILE-FORM.SEX.OPTIONS.${AVAILABLE_SEX.MALE}`),
        value: AVAILABLE_SEX.MALE,
        icon: <ICONS.MARS />
    },
    {
        label: t(`USER-PROFILE-FORM.SEX.OPTIONS.${AVAILABLE_SEX.FEMALE}`),
        value: AVAILABLE_SEX.FEMALE,
        icon: <ICONS.VENUS />
    },
    {
        label: t(`USER-PROFILE-FORM.SEX.OPTIONS.${AVAILABLE_SEX.OTHER}`),
        value: AVAILABLE_SEX.OTHER,
        icon: <ICONS.TRANSGENDER />
    },
    {
        label: t(
            `USER-PROFILE-FORM.SEX.OPTIONS.${AVAILABLE_SEX.NOT_WANT_TO_SAY}`
        ),
        value: AVAILABLE_SEX.NOT_WANT_TO_SAY,
        icon: <ICONS.USER />
    }
];

export const getLanguageOptions = (t: any): Option[] => [
    {
        label: t(
            `USER-PROFILE-FORM.APP_LANGUAGE.OPTIONS.${AVAILABLE_LANGUAGES.NL}`
        ),
        value: AVAILABLE_LANGUAGES.NL,
        icon: <assets.DUTCH_FLAG_ICON />,
        iconActiveKeepColor: true
    },
    {
        label: t(
            `USER-PROFILE-FORM.APP_LANGUAGE.OPTIONS.${AVAILABLE_LANGUAGES.EN}`
        ),
        value: AVAILABLE_LANGUAGES.EN,
        icon: <assets.USA_FLAG_ICON />,
        iconActiveKeepColor: true
    },
    {
        label: t(
            `USER-PROFILE-FORM.APP_LANGUAGE.OPTIONS.${AVAILABLE_LANGUAGES.FR}`
        ),
        value: AVAILABLE_LANGUAGES.FR,
        icon: <assets.FRENCH_FLAG_ICON />,
        iconActiveKeepColor: true
    }
];
