import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { Auth, AuthLocal, AuthServer } from 'models/auth';
import {
    transform,
    throwGeneralError,
    throwAccessDeniedError
} from 'utils/transformApiErrors';
import { ROUTES } from 'routes/list';
import { sleep } from 'utils/sleep';
import { clearCache, getCachedResult, handleCache } from 'api/cache';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

let isRefreshingTheToken = false;

const performRefreshSequence = async (
    auth: AuthLocal,
    config: AxiosRequestConfig
) => {
    try {
        await refreshToken(auth);
        return performRequest(config);
    } catch (e) {
        const location = window.location;
        location.href = `${location.origin}${ROUTES.LOGIN.path(
            location.pathname
        )}`;
        clearCache();
        return Promise.reject(e);
    }
};

const waitForRefreshToFinish = (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        await sleep(100);
        if (isRefreshingTheToken) {
            return waitForRefreshToFinish();
        }

        if (window.location.pathname.startsWith(ROUTES.LOGIN.path())) {
            reject(new Error('Refresh token failed'));
            return;
        }
        resolve();
    });
};

const performRequest = async (
    config: AxiosRequestConfig,
    hasAuthHeaders: boolean = true,
    waitForRefreshToken: boolean = true
): Promise<AxiosResponse> => {
    if (isRefreshingTheToken && waitForRefreshToken) {
        try {
            await waitForRefreshToFinish();
        } catch (e) {
            clearCache();
            return Promise.reject(e);
        }
    }

    const auth = new AuthLocal();

    const headers = {
        ...(hasAuthHeaders && {
            ...auth.getHeaders()
        }),
        ...config.headers
    };

    return axios
        .create({
            baseURL: BASE_URL,
            headers
        })
        .request(config)
        .then((response: AxiosResponse) => {
            if (config.url && config.method) {
                handleCache(config.url, config.method, response);
            }

            return response;
        })
        .catch(async (err: AxiosError) => {
            if (!err || !err.response) {
                return Promise.reject(throwGeneralError());
            }

            if (err.response.status === 401) {
                if (isRefreshingTheToken) {
                    try {
                        await waitForRefreshToFinish();
                        return performRequest(config);
                    } catch (e) {
                        clearCache();
                        return Promise.reject(e);
                    }
                }
                return performRefreshSequence(auth, config);
            }

            if (err.response.status === 403) {
                return Promise.reject(throwAccessDeniedError());
            }

            return Promise.reject(transform(err.response.data));
        });
};

export async function get(url: string): Promise<AxiosResponse> {
    const responseFromCache = getCachedResult(url);

    if (responseFromCache) {
        return Promise.resolve(responseFromCache);
    }

    return performRequest({
        method: 'GET',
        url
    });
}

export async function put(url: string, data: any = {}): Promise<AxiosResponse> {
    return performRequest({
        method: 'PUT',
        data,
        url
    });
}

export async function post(
    url: string,
    data: any = {}
): Promise<AxiosResponse> {
    return performRequest({
        method: 'POST',
        data,
        url
    });
}

export async function postFile(
    url: string,
    file: File
): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return performRequest({
        method: 'POST',
        data: formData,
        url,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export async function remove(
    url: string,
    data: any = {}
): Promise<AxiosResponse> {
    return performRequest({
        method: 'DELETE',
        data,
        url
    });
}

async function refreshToken(currentAuth: Auth): Promise<Auth> {
    try {
        isRefreshingTheToken = true;
        const response = await performRequest(
            {
                method: 'POST',
                url: '/api/v1/auth/refresh-token',
                data: currentAuth.getApiJSON()
            },
            false,
            false
        );
        return new AuthServer(response.data);
    } catch (e) {
        return Promise.reject(e);
    } finally {
        isRefreshingTheToken = false;
    }
}
