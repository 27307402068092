import { FEEDBACK_EMOJI_TYPE, USER_ROLES } from 'utils/enums';
import dayjs from 'dayjs';
import { getValueFromObject } from 'utils/get';
import { LocalstorageKeys } from 'utils/localstorage';
import { AVAILABLE_LANGUAGES, AVAILABLE_SEX } from 'utils/enums';

export abstract class User {
    protected _id!: string;
    protected _firstName!: string;
    protected _lastName!: string;
    protected _email!: string;
    protected _onboardingDone!: boolean;
    protected _picture!: string;
    protected _birthDate!: dayjs.Dayjs;
    protected _appLanguage!: AVAILABLE_LANGUAGES;
    protected _sex!: AVAILABLE_SEX;
    protected _type!: USER_ROLES;
    protected _calendarUrl!: string;

    constructor(user: any) {
        this._convertRawUser(user);
    }

    get id(): string {
        return this._id;
    }

    get onboardingDone(): boolean {
        return this._onboardingDone;
    }

    get picture(): string {
        return this._picture;
    }

    get firstName(): string {
        return this._firstName;
    }

    get lastName(): string {
        return this._lastName;
    }

    get email(): string {
        return this._email;
    }

    get displayName(): string {
        if (this._firstName && this._lastName) {
            return `${this._firstName} ${this._lastName}`;
        }
        return this._email;
    }

    get birthDate(): dayjs.Dayjs {
        return this._birthDate;
    }

    get appLanguage(): AVAILABLE_LANGUAGES {
        return this._appLanguage;
    }

    get sex(): AVAILABLE_SEX {
        return this._sex;
    }

    public isHr(): boolean {
        return [USER_ROLES.ADMIN, USER_ROLES.HR].includes(this._type);
    }

    public isAdmin(): boolean {
        return this._type === USER_ROLES.ADMIN;
    }

    get calendarUrl(): string {
        return this._calendarUrl;
    }

    protected _convertRawUser(user: any): void {
        this._id = getValueFromObject(user, ['id', '_id'], '');
        this._firstName = getValueFromObject(
            user,
            ['first_name', 'firstName'],
            ''
        );
        this._lastName = getValueFromObject(
            user,
            ['last_name', 'lastName'],
            ''
        );
        this._email = getValueFromObject(user, ['email', '_email'], '');
        this._onboardingDone = getValueFromObject(
            user,
            ['onboarding_done', 'onboardingDone'],
            false
        );
        this._picture = getValueFromObject(user, ['picture', '_picture'], '');
        this._birthDate = dayjs(
            getValueFromObject(
                user,
                ['birth_date', 'birthDate', '_birth_date'],
                '1985-01-01'
            )
        );
        this._appLanguage = getValueFromObject(
            user,
            ['appLanguage', '_appLanguage', 'app_language'],
            AVAILABLE_LANGUAGES.EN
        );
        this._sex = getValueFromObject(
            user,
            ['sex', '_sex'],
            AVAILABLE_SEX.NOT_WANT_TO_SAY
        );
        this._type = getValueFromObject(
            user,
            ['type', '_type'],
            USER_ROLES.NORMAL
        );
        this._calendarUrl = getValueFromObject(
            user,
            ['calendar_url', 'calendarUrl'],
            ''
        );
    }
}

export class UserLocal extends User {
    constructor() {
        super({});
        this._convertRawUser(this._getLocalUser());
    }

    public isUser(): boolean {
        return Boolean(this._id);
    }

    private _getLocalUser(): any {
        try {
            const user = localStorage.getItem(LocalstorageKeys.USER);

            if (!user) {
                throw new Error('No user');
            }

            const { id, fn, ln, e, od, p, b, s, al, t, cu } = JSON.parse(user);

            return {
                id,
                firstName: fn,
                lastName: ln,
                email: e,
                onboardingDone: od,
                picture: p,
                birthDate: dayjs(b),
                sex: s,
                appLanguage: al,
                type: t,
                calendarUrl: cu
            };
        } catch (e) {
            return {};
        }
    }
}

export class UserServer extends User {
    constructor(user: any) {
        super(user);
        this._save();
    }

    private _save(): void {
        localStorage.setItem(
            LocalstorageKeys.USER,
            JSON.stringify({
                id: this._id,
                fn: this._firstName,
                ln: this._lastName,
                e: this._email,
                od: this._onboardingDone,
                p: this._picture,
                b: this._birthDate,
                s: this._sex,
                al: this._appLanguage,
                t: this._type,
                cu: this._calendarUrl
            })
        );
    }
}

export class UserAvatar extends User {
    get fallback(): string {
        return this.displayName.substring(0, 1);
    }
}

export class UserFeeling {
    private readonly _id: string;
    private readonly _createdAt: dayjs.Dayjs;
    private readonly _feeling: FEEDBACK_EMOJI_TYPE;
    private readonly _description: string;

    constructor(feeling: any) {
        this._id = getValueFromObject(feeling, 'id', '');
        this._createdAt = dayjs(
            getValueFromObject(feeling, 'created_at', dayjs())
        );
        this._feeling = getValueFromObject(
            feeling,
            'feeling',
            FEEDBACK_EMOJI_TYPE.OK
        );
        this._description = getValueFromObject(feeling, 'description', '');
    }

    get id(): string {
        return this._id;
    }

    get createdAt(): dayjs.Dayjs {
        return this._createdAt;
    }

    get feeling(): FEEDBACK_EMOJI_TYPE {
        return this._feeling;
    }

    get description(): string {
        return this._description;
    }
}

export type UserProfileType = {
    profilePicture: string | File;
    firstName: string;
    lastName: string;
    sex: AVAILABLE_SEX;
    birthDate: dayjs.Dayjs;
    appLanguage: AVAILABLE_LANGUAGES;
};
