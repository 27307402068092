const tabletThreshold = 600;
const desktopThreshold = 1025;

const theme = {
    colors: {
        primary: '#367FFA',
        primary10: '#D6E5FE', // 10 percent
        primaryDark: '#0062FA',
        primaryVeryDark: '#13325F',
        background: '#F1F5FD',
        primaryGrey: '#8D8D8D',
        secondaryGrey: '#f2f2f5',
        trajectPrimary: '#58C7FB',
        success: '#38C976',
        warning: '#F99F38',
        error: '#E53935',
        white: '#fff',
        border: 'rgba(99, 102, 133, 0.15)',
        text_light: '#fff',
        text_dark: '#343a40',
        text_primary: '#636685'
    },
    mediaQueries: {
        tabletThreshold,
        desktopThreshold,
        isTablet: `@media (min-width:${tabletThreshold}px)`,
        isDesktop: `@media (min-width:${desktopThreshold}px)`
    },
    space: {
        page: '24px',
        modal: '12px',
        innerPageContent: desktopThreshold,
        minHeightFormFields: '56px',
        navbar: 64,
        screenHeight: `height: 100vh; height: calc(var(--vh, 1vh) * 100);`,
        screenMinHeight: `min-height: 100vh; min-height: calc(var(--vh, 1vh) * 100);`
    },
    zIndex: {
        header: 1,
        content: 2,
        calendar: 10,
        navBar: 15,
        pwaReload: 20
    }
};

export default theme;
