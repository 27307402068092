import {useEffect, useState} from 'react';

export enum FormValidations {
    REQUIRED = 'ERRORS.EMPTY',
}

export default function useFormValidation(
    validations: any,
    initialFormValues: any,
    onSubmit: (formValues: any) => void
) {
    const [formValues, setFormValues] = useState(initialFormValues);
    const [formErrors, setFormErrors] = useState<any>({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const validate = () => {
        const output: any = {};
        Object.keys(formValues).forEach((key: string) => {
            if (validations[key]) {
                validations[key].forEach((validation: FormValidations) => {
                    switch (validation) {
                        case FormValidations.REQUIRED: {
                            if (!Boolean(formValues[key])) {
                                output[key] = FormValidations.REQUIRED;
                            }
                            break;
                        }
                    }
                });
            }
        });

        return output;
    };

    useEffect(() => {
        if (!isSubmitted) {
            return;
        }
        if (Object.keys(validate()).length) {
            setIsSubmitted(false);
            return;
        }
        onSubmit(formValues);
    }, [isSubmitted]);

    useEffect(() => {
        if (!isSubmitted) {
            return;
        }
        setFormErrors(validate());
    }, [formValues, isSubmitted]);

    return {
        formValues,
        setFormValues,
        formErrors,
        setIsSubmitted
    };
}
