import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Loader from 'components/states/loader';
import EmptyState, { EmptyStateKey } from 'components/states/empty';
import ErrorBox from 'components/api-render/error';
import theme from 'utils/theme';

export type Props<T> = {
    keyState: EmptyStateKey;
    promiseCall: (fetchParam?: any) => Promise<T>;
    promiseParams?: any;
    renderItem: (item: T) => React.ReactNode;
    className?: string;
    onActionClick?: (event: React.BaseSyntheticEvent) => void;
};

const Root = styled.div`
    z-index: ${theme.zIndex.content};
`;

const SingleApiRender = <T extends unknown>({
    keyState,
    promiseCall,
    promiseParams,
    renderItem,
    className,
    onActionClick
}: Props<T>) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [item, setItem] = useState<T | null>(null);

    const fetchItem = async (fetchParam?: string) => {
        try {
            setIsLoading(true);
            setError('');

            const response = await promiseCall(fetchParam);
            setItem(response as T);
        } catch (e: any) {
            setError(e.toString());
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchItem(promiseParams);
    }, [promiseParams]);

    if (error) {
        return (
            <Root className={className}>
                <ErrorBox error={error} />
            </Root>
        );
    }

    return (
        <Root className={className}>
            <Loader enable={isLoading} />
            <EmptyState
                keyState={keyState}
                enable={!isLoading && !item}
                onActionClick={onActionClick}
            />
            {!isLoading && item && renderItem(item)}
        </Root>
    );
};

export default SingleApiRender;
