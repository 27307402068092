import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { Store } from 'store';
import { userUpdate } from 'store/user';
import Button from 'components/button';
import { getCalendarForUser } from 'api/team-events';
import { UserServer } from 'models/user';

const Root = styled.div``;

let timerForCopiedState: any = -1;
const timerAmountForCopied = 5000;

const LinKCalendar: React.FC = () => {
    const user = useSelector((store: Store) => store.user);
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [hasCopied, setHasCopied] = useState(false);

    const fetchCalendarForUser = async () => {
        try {
            setIsLoading(true);
            const url = await getCalendarForUser();
            dispatch(userUpdate(new UserServer({ ...user, calendarUrl: url })));
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleLinkCopied = () => {
        setHasCopied(true);

        clearTimeout(timerForCopiedState);

        timerForCopiedState = setTimeout(() => {
            setHasCopied(false);
        }, timerAmountForCopied);
    };

    useEffect(() => {
        if (!user.calendarUrl) {
            fetchCalendarForUser();
        }
    }, [user]);

    if (isMobile) {
        return null;
    }

    return (
        <Root className='link-calendar'>
            <CopyToClipboard text={user.calendarUrl} onCopy={handleLinkCopied}>
                <Button isLoading={isLoading}>
                    {t(`COMMUNITY.CALENDAR.${hasCopied ? 'COPIED' : 'COPY'}`)}
                </Button>
            </CopyToClipboard>
        </Root>
    );
};

export default LinKCalendar;
