import React, { useEffect, useState } from 'react';
import ScaleInput from 'pages/assessment/inputs/scale-input';
import { StyledQuestions } from 'pages/assessment/styling';
import Button from 'components/button';
import { Question as QuestionModel, QUESTION_TYPE } from 'models/question';
import InputField from 'components/text-field';
import BigSelector, { Option } from 'components/form/bigSelector';
import { getSexOptions } from 'forms/userProfile/options';
import { useTranslation } from 'react-i18next';
import DatePicker from 'components/date-picker';
import Form from 'components/form';
import useFormValidation, { FormValidations } from 'hooks/useValidation';
import { Description, SubHeader } from 'components/text';
import ImageSelector from 'components/form/imageSelector';
import { useTranslationTransform } from 'translations/translation';
import YesNoInput from 'pages/assessment/inputs/yes-no';
import RangeInput from 'pages/assessment/inputs/range-input';

type Props = {
    question: QuestionModel;
    toPreviousQuestion: () => void;
    currentQuestionIndex: number;
    isActive: boolean;
    onSubmit: (answer: any) => Promise<any>;
};
const Question: React.FC<Props> = ({
    question,
    toPreviousQuestion,
    currentQuestionIndex,
    isActive,
    onSubmit
}: Props) => {
    const { t } = useTranslation();
    const [sexOptions] = useState<Option[]>(getSexOptions(t));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const translationTransform = useTranslationTransform();

    const handleOnSubmit = async (values: any) => {
        try {
            setIsLoading(true);
            await onSubmit(values[question.id]);
        } catch (err) {
            console.error(err);
            // TODO: hamburger that shows error message maybe
        } finally {
            setIsLoading(false);
        }
    };

    const { formValues, setFormValues, formErrors, setIsSubmitted } =
        useFormValidation(
            { [question.id]: question.validations },
            { [question.id]: null },
            handleOnSubmit
        );

    const handleChange = (key: string, value: any) => {
        setFormValues((prev: any) => ({ ...prev, [key]: value }));
    };

    const shouldDisableNextButton = (): boolean => {
        if (question.validations.includes(FormValidations.REQUIRED)) {
            return !Boolean(formValues[question.id]);
        }
        return false;
    };

    const renderInputField = (question: QuestionModel) => {
        const handleChangeInput = (newValue: any) => {
            handleChange(question.id, newValue);
        };

        switch (question.type) {
            case QUESTION_TYPE.TEXT:
                return (
                    <InputField
                        value={formValues[question.id]}
                        label=''
                        sx={{ width: '100%' }}
                        errorText={t(formErrors[question.id])}
                        autoFocus={isActive}
                        onChange={handleChangeInput}
                    />
                );

            case QUESTION_TYPE.DATE_TIME:
                return (
                    <DatePicker
                        value={formValues[question.id]}
                        label={t('USER-PROFILE-FORM.BIRTH_DATE.LABEL')}
                        errorText={t(formErrors[question.id])}
                        noPopup
                        onChange={handleChangeInput}
                    />
                );

            case QUESTION_TYPE.GENDER:
                return (
                    <BigSelector
                        name='sex'
                        value=''
                        label=''
                        options={sexOptions}
                        onChange={handleChangeInput}
                        errorText={t(formErrors[question.id])}
                    />
                );

            case QUESTION_TYPE.AVATAR:
                return (
                    <div className='picture-container'>
                        <ImageSelector
                            name='profilePicture'
                            label={t('USER-PROFILE-FORM.PROFILE_PICTURE.LABEL')}
                            value={formValues.profilePicture}
                            onChange={handleChangeInput}
                            imgSize='200px'
                        />
                    </div>
                );

            case QUESTION_TYPE.YES_NO:
                return (
                    <YesNoInput
                        question={question}
                        onChange={handleChangeInput}
                    />
                );

            case QUESTION_TYPE.RANGE:
                return (
                    <RangeInput
                        question={question}
                        onChange={handleChangeInput}
                    />
                );
        }

        return (
            <ScaleInput
                amount={10}
                errorText={t(formErrors[question.id])}
                onChange={handleChangeInput}
            />
        );
    };

    useEffect(() => {
        if (isActive) {
            setIsSubmitted(false);
        }
    }, [isActive]);

    return (
        <StyledQuestions data-question={question.id}>
            <Form
                className='form root-container'
                onHasSubmitted={setIsSubmitted}
            >
                <div className='question-container'>
                    <div className='question-header'>
                        <SubHeader className='subheader text'>
                            {translationTransform(question.text)}
                        </SubHeader>
                    </div>
                    <div>{renderInputField(question)}</div>
                    <RenderLegend question={question} />
                </div>
                <div className='button-container'>
                    {currentQuestionIndex > 0 && (
                        <Button className='button' onClick={toPreviousQuestion}>
                            {t('ASSESSMENT.BACK')}
                        </Button>
                    )}
                    <Button
                        className='button'
                        type='submit'
                        isLoading={isLoading}
                        disabled={shouldDisableNextButton()}
                    >
                        {t('ASSESSMENT.NEXT')}
                    </Button>
                </div>
            </Form>
        </StyledQuestions>
    );
};

type LegendProps = {
    question: QuestionModel;
};

const RenderLegend: React.FC<LegendProps> = ({ question }: LegendProps) => {
    const { t } = useTranslation();
    const qsl = question.scores.length;

    if (question.type !== QUESTION_TYPE.RANGE || !qsl) {
        return null;
    }

    const min = question.scores[0].value;
    const max = question.scores[qsl - 1].value;

    return (
        <div className='question-indicators-container'>
            <Description className='indication-text'>
                {t('ASSESSMENT.QUESTION_LEGEND.MIN', { value: min })}
            </Description>
            <Description className='indication-text'>
                {t('ASSESSMENT.QUESTION_LEGEND.MAX', { value: max })}
            </Description>
        </div>
    );
};

export default Question;
