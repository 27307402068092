export enum AVAILABLE_LANGUAGES {
    NL = 'NL',
    EN = 'EN',
    FR = 'FR'
}

export enum AVAILABLE_SEX {
    MALE = 'M',
    FEMALE = 'F',
    OTHER = 'O',
    NOT_WANT_TO_SAY = 'X'
}

export enum ACHIEVEMENTS {
    LOGIN_WEEKLY = 'lw',
    GOALS_CREATED = 'gcr',
    GOALS_INFO_ADDED = 'gia',
    GOALS_COMPLETED = 'gco',
    TRAJECT_CREATED = 'tcr',
    TRAJECT_INFO_ADDED = 'tia',
    TRAJECT_COMPLETED = 'tco',
    COMMUNITY_TEAM_EVENT_CREATED = 'ctec',
    COMMUNITY_TEAM_EVENT_ATTENDED = 'ctea',
    COMMUNITY_CHALLENGE_ATTENDED = 'cca',
    HOW_YOU_DOING = 'hyd',
    USER_PROFILE_PICTURE_ADDED = 'uppa'
}

export enum STATES {
    ERROR = 'error',
    LOADING = 'loading',
    EMPTY = 'empty',
    RESULTS = 'results'
}

export enum GOAL_INFO_STATUS {
    IN_PROGRESS = 'ip',
    COMPLETED = 'c',
    DELETED = 'd'
}

export enum FEEDBACK_EMOJI_TYPE {
    ANGRY = 'hyda',
    SAD = 'hyds',
    OK = 'hydo',
    GOOD = 'hydg',
    HAPPY = 'hydh'
}

export enum IntensityTypes {
    EASY = 'e',
    MEDIUM = 'm',
    HARD = 'h'
}

export const INTENSITY_COLORS = {
    [IntensityTypes.EASY]: '#688E26',
    [IntensityTypes.MEDIUM]: '#ff5b20',
    [IntensityTypes.HARD]: '#ee100e'
};

export enum CMS_GOAL_TYPES {
    ONLY_CURRENT_SITUATION = 'ocs',
    FULL_SETUP = 'fs'
}

export enum CMS_GOAL_ANSWER_TYPES {
    TEXT = 'text',
    NUMBER = 'number',
    BOOLEAN = 'boolean'
}

export enum USER_ROLES {
    NORMAL = 'NORMAL',
    ADMIN = 'ADMIN',
    HR = 'HR'
}
