import { getValueFromObject } from 'utils/get';

const supportedLanguages = ['nl', 'en', 'fr'];

export class Translation {
    private readonly _nl: string;
    private readonly _en: string;
    private readonly _fr: string;

    constructor(translation: any) {
        this._nl = getValueFromObject(translation, 'nl', '');
        this._en = getValueFromObject(translation, 'en', '');
        this._fr = getValueFromObject(translation, 'fr', '');
    }

    public getText(language?: string): string {
        if (language && supportedLanguages.includes(language)) {
            return this._getTextFromCorrectLanguage(language);
        }

        return this._en || this._nl || this._fr || '';
    }

    private _getTextFromCorrectLanguage(language: string): string {
        switch (language) {
            case 'nl':
                return this._nl;
            case 'en':
                return this._en;
            case 'fr':
                return this._fr;
            default:
                return '';
        }
    }
}
