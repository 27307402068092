import React, { BaseSyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTranslationTransform } from 'translations/translation';
import { completeCurrentTraject } from 'api/traject';
import { showConfetti } from 'utils/visual-helper';
import { RootContainer } from 'pages/traject/styling';
import { Description, SubHeader } from 'components/text';
import Button from 'components/button';
import { Traject } from 'models/traject';

type Props = {
    traject: Traject;
};

const RenderCompletedTraject: React.FC<Props> = ({ traject }: Props) => {
    const { t } = useTranslation();
    const translateTransform = useTranslationTransform();

    const handleSubmit = async (event: BaseSyntheticEvent) => {
        try {
            event.stopPropagation();
            await completeCurrentTraject();
            await showConfetti();
            window.location.reload();
        } catch (e) {}
    };

    useEffect(() => {}, []);

    return (
        <RootContainer>
            <img
                className='img'
                src={traject.image}
                alt={translateTransform(traject.title)}
            />

            <SubHeader className='title'>
                {t('TRAJECT.COMPLETED_TRAJECT.TITLE')}
            </SubHeader>

            <Description className='description'>
                {t('TRAJECT.COMPLETED_TRAJECT.DESCRIPTION')}
            </Description>

            <Button className='submit' onClick={handleSubmit}>
                {t('TRAJECT.COMPLETED_TRAJECT.SUBMIT')}
            </Button>
        </RootContainer>
    );
};

export default RenderCompletedTraject;
