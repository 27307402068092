import { getValueFromObject } from 'utils/get';
import { LocalstorageKeys } from 'utils/localstorage';

const emptyAuth = {
    accessToken: '',
    refreshToken: ''
};

export abstract class Auth {
    protected _accessToken: string;
    protected _refreshToken: string;

    protected constructor(auth: any) {
        this._accessToken = getValueFromObject(
            auth,
            ['access_token', 'accessToken', '_accessToken'],
            ''
        );
        this._refreshToken = getValueFromObject(
            auth,
            ['refresh_token', 'refreshToken', '_refreshToken'],
            ''
        );
    }

    public update(accessToken: string, refreshToken: string): void {
        this._accessToken = accessToken;
        this._refreshToken = refreshToken;
    }

    public hasHeaders(): Boolean {
        return Boolean(this._accessToken && this._refreshToken);
    }

    public getHeaders(): any {
        if (!this.hasHeaders()) {
            return {};
        }

        return {
            Authorization: `Bearer ${this._accessToken}`
        };
    }

    public getApiJSON(): { access_token: string; refresh_token: string } {
        return {
            access_token: this._accessToken,
            refresh_token: this._refreshToken
        };
    }
}

export class AuthServer extends Auth {
    constructor(auth: any) {
        super(auth);
        this._save();
    }

    private _save(): void {
        localStorage.setItem(
            LocalstorageKeys.AUTH_TOKEN,
            JSON.stringify({
                at: this._accessToken,
                rt: this._refreshToken
            })
        );
    }
}

export class AuthLocal extends Auth {
    constructor() {
        super(emptyAuth);
        const { accessToken, refreshToken } = this._getLocalAuthTokens();
        this.update(accessToken, refreshToken);
    }

    private _getLocalAuthTokens(): {
        accessToken: string;
        refreshToken: string;
    } {
        try {
            const authToken = localStorage.getItem(LocalstorageKeys.AUTH_TOKEN);

            if (!authToken) {
                throw new Error('No auth token found');
            }

            const { at, rt } = JSON.parse(authToken);
            return { accessToken: at, refreshToken: rt };
        } catch (e) {
            return emptyAuth;
        }
    }
}
