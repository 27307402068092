import CommunityPage from 'pages/team-events';
import HomePage from 'pages/home';
import LoginPage from 'pages/login';
import OnBoardingPage from 'pages/onboarding';
import UserProfilePage from 'pages/userProfile';
import UserProfileEditPage from 'pages/userProfile/edit';
import TeamEventsAddPage from 'pages/team-events/add';
import TeamEventsEditPage from 'pages/team-events/edit';
import TeamEventsDetailPage from 'pages/team-events/detail';
import TrajectPage from 'pages/traject';
import TrajectDetailPage from 'pages/traject/detail';
import AssessmentPage from 'pages/assessment';
import StatisticsPage from 'pages/statistics';
import HowUDoingPage from 'pages/how-u-doing';

export const ROUTES = {
    ASSESSMENT: {
        path: () => '/assessment',
        element: <AssessmentPage />
    },
    HOME: {
        path: () => '/',
        element: <HomePage />
    },
    LOGIN: {
        path: (next?: string) => {
            if (next) {
                return `/login?next=${next}`;
            }
            return '/login';
        },
        element: <LoginPage />
    },
    ON_BOARDING: {
        path: () => '/on-boarding',
        element: <OnBoardingPage />
    },
    COMMUNITY: {
        path: () => '/community',
        element: <CommunityPage />
    },
    COMMUNITY_DETAIL: {
        path: (eventId: string = ':eventId') => `/community/${eventId}`,
        element: <TeamEventsDetailPage />
    },
    COMMUNITY_ADD: {
        path: (info?: any) => {
            if (info) {
                return `/community/add?begin=${info}`;
            }
            return '/community/add';
        },
        element: <TeamEventsAddPage />
    },
    COMMUNITY_EDIT: {
        path: (eventId: string = ':eventId') => `/community/edit/${eventId}`,
        element: <TeamEventsEditPage />
    },
    USER_PROFILE: {
        path: () => '/profile',
        element: <UserProfilePage />
    },
    USER_PROFILE_EDIT: {
        path: () => '/profile/edit',
        element: <UserProfileEditPage />
    },
    TRAJECT: {
        path: () => '/path',
        element: <TrajectPage />
    },
    TRAJECT_DETAILS: {
        path: () => '/path/today',
        element: <TrajectDetailPage />
    },
    STATISTICS: {
        path: () => '/statistics',
        element: <StatisticsPage />
    },
    HOW_U_DOING: {
        path: () => '/how-u-doing',
        element: <HowUDoingPage />
    }
};
