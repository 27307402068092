import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'components/form';
import FormError from 'components/form/formError';
import Button from 'components/button';
import { validateCode } from 'api/auth';
import Token from 'components/input/token';
import { CodeRoot } from 'pages/login/styling';
import BackButton from 'components/button/back';

type Props = {
    email: string;
    onSubmit: () => void;
    onCancel: () => void;
};

const LoginCodeValidation: React.FC<Props> = ({
    email,
    onSubmit,
    onCancel
}: Props) => {
    const { t } = useTranslation();

    const [code, setCode] = useState<string>('');
    const [formError, setFormError] = useState<string>('');

    const handleChangeToken = (newValue: string) => {
        setCode(newValue);
    };

    const handleSubmit = async () => {
        setFormError('');

        try {
            if (!code) {
                return;
            }

            await validateCode(email, +code);
            onSubmit();
        } catch (err: any) {
            setFormError(t(err));
        }
    };

    const handleBack = () => {
        onCancel();
    };

    return (
        <CodeRoot className='login-root'>
            <BackButton className='back' onClick={handleBack} />

            <h1 className='title'>{t('LOGIN.CODE.TITLE')}</h1>

            <p
                className='send-to'
                dangerouslySetInnerHTML={{
                    __html: t('LOGIN.CODE.SEND_TO', { email })
                }}
            />
            <p
                className='valid-until'
                dangerouslySetInnerHTML={{
                    __html: t('LOGIN.CODE.VALID_UNTIL')
                }}
            />
            <Form className='form' onSubmit={handleSubmit}>
                <Token onChange={handleChangeToken} />

                <FormError value={formError} />

                <Button
                    type='submit'
                    className='submit'
                    variant='primary'
                    disabled={!Boolean(code)}
                >
                    {t(`LOGIN.SUBMIT.CODE`)}
                </Button>
            </Form>
        </CodeRoot>
    );
};

export default LoginCodeValidation;
