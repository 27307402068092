import { ReactComponent as LOGIN_WEEKLY } from 'assets/achievements/Login_Weekly.svg';
import { ReactComponent as GOALS_CREATED } from 'assets/achievements/Goals_Created.svg';
import { ReactComponent as GOALS_INFO_ADDED } from 'assets/achievements/Goals_Info_Added.svg';
import { ReactComponent as GOALS_COMPLETED } from 'assets/achievements/Goals_Completed.svg';
import { ReactComponent as COMMUNITY_TEAM_EVENT_CREATED } from 'assets/achievements/Community_Team_Event_Created.svg';
import { ReactComponent as COMMUNITY_TEAM_EVENT_ATTENDED } from 'assets/achievements/Community_Team_Event_Attended.svg';
import { ReactComponent as COMMUNITY_CHALLENGE_ATTENDED } from 'assets/achievements/Challenge_Attended.svg';
import { ReactComponent as HOW_YOU_DOING } from 'assets/achievements/How_You_Doing.svg';
import { ReactComponent as USERPROFILE_PICTURE_UPDATED } from 'assets/achievements/Userprofile_Picture_Updated.svg';

const assets = {
    LOGIN_WEEKLY_ICON: LOGIN_WEEKLY,
    GOALS_CREATED_ICON: GOALS_CREATED,
    GOALS_INFO_ADDED_ICON: GOALS_INFO_ADDED,
    GOALS_COMPLETED_ICON: GOALS_COMPLETED,
    COMMUNITY_TEAM_EVENT_CREATED_ICON: COMMUNITY_TEAM_EVENT_CREATED,
    COMMUNITY_TEAM_EVENT_ATTENDED_ICON: COMMUNITY_TEAM_EVENT_ATTENDED,
    COMMUNITY_CHALLENGE_ATTENDED_ICON: COMMUNITY_CHALLENGE_ATTENDED,
    HOW_YOU_DOING_ICON: HOW_YOU_DOING,
    USER_PROFILE_PICTURE_ADDED: USERPROFILE_PICTURE_UPDATED
};

export default assets;
