import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import assets from 'assets';

type Props = {
    step: number;
    amountOfSteps: number;
    onClick: (event: BaseSyntheticEvent) => void;
};

type Step = {
    active: boolean;
    className: string;
    position: number;
};

const Root = styled.div`
    position: relative;
    height: 60px;
    width: 60px;

    .icon {
        height: 60px;
        width: 60px;
    }

    .step {
        position: absolute;
        border-width: 2px;

        &:nth-child(1) {
            top: -8px;
            left: -8px;
            bottom: 50%;
            right: calc(50% + 8px);
            border-left-style: solid;
            border-top-style: solid;
            border-top-left-radius: 8px;
        }

        &:nth-child(2) {
            top: -8px;
            right: -8px;
            bottom: 50%;
            left: calc(50% + 8px);
            border-right-style: solid;
            border-top-style: solid;
            border-top-right-radius: 8px;
        }

        &:nth-child(3) {
            top: calc(50% + 8px);
            right: -8px;
            bottom: -8px;
            left: -8px;
            border-bottom-style: solid;
            border-left-style: solid;
            border-right-style: solid;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        &_active {
            border-color: #3b81fa;
        }

        &_default {
            border-color: #b2cfff;
        }
    }
`;

const StepsButton: React.FC<Props> = ({
    step,
    amountOfSteps,
    onClick
}: Props) => {
    const [steps, setSteps] = useState<Step[]>([]);

    const fillSteps = () => {
        const helpSteps = [];
        for (let i = 0; i < amountOfSteps; i++) {
            const active = i < step;
            helpSteps.push({
                active,
                className: `step step_${i} step_${
                    active ? 'active' : 'default'
                }`,
                position: i
            });
        }

        setSteps(helpSteps);
    };

    const handleClick = (event: BaseSyntheticEvent) => {
        onClick(event);
    };

    useEffect(() => {
        fillSteps();
    }, [step, amountOfSteps]);

    return (
        <Root className='steps-btn' onClick={handleClick}>
            {steps.map(({ position, className }) => (
                <div key={position} className={className} />
            ))}

            <assets.BUTTON_STEPS_NO_BORDER_ICON className='icon' />

            {/*<Button className='step-btn' onClick={onClick}>*/}
            {/*    <assets.BACK_ARROW_ICON className='icon' />*/}
            {/*</Button>*/}
        </Root>
    );
};

export default StepsButton;
