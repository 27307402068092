import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import GroupAvatars from 'components/avatar/group';
import { EventBox as StyledEventBox } from './styling';
import CustomBox from 'components/box/custom';
import { TeamEvent } from 'models/community';
import { ROUTES } from 'routes/list';
import { convertDayjsToHHmm } from 'utils/dates';
import { Description } from 'components/text';
import { COLOR_PALLETES } from 'components/box/custom';
import { IntensityTypes } from 'utils/enums';
import assets from 'assets';

type Props = {
    event: TeamEvent;
};

const EventBox: React.FC<Props> = ({ event }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToDetailPage = () => {
        navigate(ROUTES.COMMUNITY_DETAIL.path(event.id));
    };

    const renderBackground = () => {
        switch (event.intensity) {
            case IntensityTypes.EASY:
                return <assets.TEAM_EVENT_EASY className='bg' />;

            case IntensityTypes.MEDIUM:
                return <assets.TEAM_EVENT_MEDIUM className='bg' />;

            case IntensityTypes.HARD:
                return <assets.TEAM_EVENT_HARD className='bg' />;
        }

        return null;
    };

    const color: string =
        COLOR_PALLETES.TEAM_EVENTS[
            // @ts-ignore
            Object.keys(IntensityTypes).find(
                // @ts-ignore
                (type: string) => IntensityTypes[type] === event.intensity
            )
        ];

    return (
        <StyledEventBox color={color}>
            <CustomBox
                text={`${convertDayjsToHHmm(
                    event.beginDate
                )} - ${convertDayjsToHHmm(
                    event.beginDate.add(event.duration, 'minutes')
                )}`}
                color={color}
                onClick={navigateToDetailPage}
            >
                <h1 className='name'>{event.title}</h1>
                <Description className='location'>{event.location}</Description>
                <div className='footer'>
                    <div className='participants'>
                        <GroupAvatars users={event.users} max={4} />
                    </div>
                    <FontAwesomeIcon className='arrow' icon={faChevronRight} />
                </div>
            </CustomBox>
            {renderBackground()}
            <span className='intensity-text'>
                {t(`COMMUNITY.INTENSITY.${event.intensity}`)}
            </span>
        </StyledEventBox>
    );
};

export default EventBox;
