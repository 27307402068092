import React from 'react';
import styled from 'styled-components';
import { Header } from 'components/text';
import { ICONS } from 'assets/icons';
import { Box } from 'components/box/styling';
import Button from 'components/button';
import { ROUTES } from 'routes/list';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ErrorRoot = styled(Box)`
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4em;

    .image {
        width: 100px;
        height: 100px;
        color: #ffd43b;
    }

    .button {
        width: 100%;
    }

    .title {
        text-align: center;
    }
`;

type Props = {
    canGoToHome?: boolean;
    error?: string;
};

const ErrorBox: React.FC<Props> = ({
    canGoToHome = false,
    error = 'ERRORS.ROUTES.TITLE'
}: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const returnToHomePage = () => {
        navigate(ROUTES.HOME.path());
    };

    return (
        <ErrorRoot>
            <ICONS.REGULAR_FACE_FROWN_OPEN className='image' />
            <Header className='title'>{t(error)}</Header>
            {canGoToHome && (
                <Button className='button' onClick={returnToHomePage}>
                    {t('ERRORS.ROUTES.BUTTON')}
                </Button>
            )}
        </ErrorRoot>
    );
};

export default ErrorBox;
