import React, { useEffect, useState } from 'react';
import { ProgressBar as StyledProgressBar } from 'components/states/progress-bar/styling';

type Props = {
    className?: string;
    value: number;
    max?: number;
};
const ProgressBar: React.FC<Props> = ({
    className,
    value,
    max = 100
}: Props) => {
    const [currentValue, setCurrentValue] = useState<number>(value);

    useEffect(() => {
        setCurrentValue(Math.round((value / max) * 100));
    }, [value, max]);

    return (
        <StyledProgressBar className={className} value={currentValue}>
            <div className='progress-value-bar' />
            <div className='progress-value'>{currentValue}%</div>
        </StyledProgressBar>
    );
};

export default ProgressBar;
