import assets from 'assets';
import { useTranslation } from 'react-i18next';
import { HomeChallenge as StyledHomeChallenge } from './styling';
const HomeChallenge = () => {
    const { t } = useTranslation();

    return (
        <StyledHomeChallenge
            className='coming-soon-container-root'
            bgUrl={assets.COMING_SOON_BACKGROUND}
        >
            <div className='coming-soon-container'>
                <h2 className='coming-soon-header'>
                    {t('HOME.CHALLENGE.COMING_SOON')}
                </h2>
                <assets.COMING_SOON_SHAPE className='coming-soon-svg' />
            </div>
            <div className='home-challenge--container'>
                <h2 className='home-challenge--days'>
                    {`30 ${t('HOME.CHALLENGE.DAYS')}`}
                </h2>
                <h1 className='home-challenge--challenge'>
                    {t('HOME.CHALLENGE.TITLE')}
                </h1>
                <p className='home-challenge--text'>
                    {t('HOME.CHALLENGE.TEXT')}
                </p>
            </div>
            <img
                className='woman-running-img'
                src={assets.WOMAN_RUNNING}
                alt=''
            />
        </StyledHomeChallenge>
    );
};

export default HomeChallenge;
