import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiPaginator } from 'models/apiPaginator';
import Loader from 'components/states/loader';
import EmptyState, { EmptyStateKey } from 'components/states/empty';
import { STATES } from 'utils/enums';
import ErrorBox from 'components/api-render/error';

type Props<T> = {
    keyState: EmptyStateKey;
    hasPagination?: boolean;
    promiseCall: (next?: string) => Promise<T[] | ApiPaginator<T>>;
    promiseParams?: any;
    renderItem: (item: T) => React.ReactNode;
    onActionClick?: (event: React.BaseSyntheticEvent) => void;
    onStateChanged?: Function;
    onResultsLoaded?: Function;
};

const Root = styled.div``;

const ListApiRender = <T extends unknown>({
    keyState,
    hasPagination = false,
    promiseCall,
    promiseParams = null,
    renderItem,
    onActionClick,
    onStateChanged = () => null,
    onResultsLoaded = () => null
}: Props<T>) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [items, setItems] = useState<T[]>([]);
    const [nextUrl, setNextUrl] = useState<string>('');

    const fetchItems = async (next?: string) => {
        try {
            setIsLoading(true);
            setError('');

            const response = await promiseCall(next);

            if (hasPagination) {
                setNextUrl((response as ApiPaginator<T>).next);
                setItems((response as ApiPaginator<T>).results);
            } else {
                setItems(response as T[]);
            }
        } catch (e: any) {
            setError(e.toString());
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        onResultsLoaded(items);
        onStateChanged(items.length ? STATES.RESULTS : STATES.EMPTY);
    }, [items]);

    useEffect(() => {
        fetchItems(nextUrl);
    }, [promiseParams, nextUrl]);

    if (error) {
        return (
            <Root className='render-helper'>
                <ErrorBox error={error} />
            </Root>
        );
    }

    return (
        <Root className='render-helper'>
            <Loader enable={isLoading} />
            <EmptyState
                keyState={keyState}
                enable={!isLoading && !items.length}
                onActionClick={onActionClick}
            />
            {!isLoading && items.map(renderItem)}
        </Root>
    );
};

export default ListApiRender;
