import { Box } from 'components/box/styling';
import styled from 'styled-components';

type HomeChallengeType = {
    bgUrl: string;
};

export const HomeChallenge = styled(Box)<HomeChallengeType>`
    background: url(${({ bgUrl }) => bgUrl});
    background-size: 100% 100%;
    position: relative;
    height: 150px;
    color: #ffffff;

    .coming-soon-container {
        position: absolute;
        width: 180px;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .coming-soon-header {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 40%;
            transform: translate(-50%, -50%);
        }

        .coming-soon-svg {
            width: 100%;
            height: 100%;
        }
    }

    .home-challenge--container {
        position: absolute;
        width: 60%;
        bottom: 16px;
        top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .home-challenge--days {
        font-size: 16px;
        letter-spacing: 1.3px;
        -webkit-text-stroke: 1px white;
        -webkit-text-fill-color: transparent;
    }

    .home-challenge--challenge {
    }

    .home-challenge--text {
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
    }

    .woman-running-img {
        height: 110%;
        position: absolute;
        right: 0.5rem;
        bottom: 0;
    }
`;
