import React, { BaseSyntheticEvent } from 'react';
import styled from 'styled-components';
import { DAY_INDICATOR_GROUP } from 'assets/dayIndicator';
import theme from 'utils/theme';
import { Box } from 'components/box/styling';
import { INTENSITY_COLORS, IntensityTypes } from 'utils/enums';

type Props = {
    children: React.ReactNode | React.ReactNode[];
    text: string;
    color: any;
    onClick?: (event: BaseSyntheticEvent) => void;
};

const Root = styled(Box)`
    position: relative;
    padding-top: 52px;
`;

const IndicatorContainer = styled.div<{ color: string }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
        z-index: 1;
        font-weight: 600;
        font-size: 20px;
        color: ${theme.colors.white};
        white-space: nowrap;
        padding-right: 32px;
    }

    .group {
        position: absolute;
        top: -32px;
        left: 0;
        width: 100%;

        .bg {
            fill: ${({ color }) => color};
        }
    }
`;

export const COLOR_PALLETES = {
    TRAJECT: 'rgb(78, 199, 252)',
    CHALLENGE: 'rgb(91, 81, 223)',
    TEAM_EVENTS: {
        EASY: INTENSITY_COLORS[IntensityTypes.EASY],
        MEDIUM: INTENSITY_COLORS[IntensityTypes.MEDIUM],
        HARD: INTENSITY_COLORS[IntensityTypes.HARD]
    }
};

const CustomBox: React.FC<Props> = ({
    text,
    children,
    color,
    onClick = () => {}
}: Props) => {
    return (
        <Root className='custom-box-container' onClick={onClick}>
            <IndicatorContainer color={color}>
                <DAY_INDICATOR_GROUP className='group' />
                <span className='text'>{text}</span>
            </IndicatorContainer>

            {children}
        </Root>
    );
};

export default CustomBox;
