import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDayjs';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'themes';
import './index.css';
import Routes from 'routes';
import 'translations';
import store from 'store';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <Routes />
                </LocalizationProvider>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
