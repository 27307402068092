import { Action } from 'store';
import { User, UserLocal } from 'models/user';

const USER_UPDATE = 'USER_UPDATE';

export type State = User;

export const userReducer = (state: State = new UserLocal(), action: Action) => {
    switch (action.type) {
        case USER_UPDATE: {
            return action.payload;
        }

        default:
            return state;
    }
};

export function userUpdate(user: User): Action {
    return {
        type: USER_UPDATE,
        payload: user
    };
}
