import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    convertDayjsToDMMMHHmm,
    convertMinutesToDurationHmm
} from 'utils/dates';
import { ROUTES } from 'routes/list';
import { addAttendee, deleteTeamEvent, removeAttendee } from 'api/team-events';
import { getTeamEvent } from 'api/team-events';
import Avatar from 'components/avatar/single';
import { Box } from 'components/box/styling';
import Button from 'components/button';
import PageHeader from 'components/page-header';
import { Title } from 'components/text';
import { TeamEventsDetailPageStyling } from 'pages/team-events/detail/styling';
import DeleteModal from 'components/modal/deleteModal';
import Tooltip from 'components/tooltip';
import { ButtonGroup } from 'components/button/styling';
import { TeamEvent } from 'models/community';
import { useNavigate } from 'react-router-dom';

const TeamEventsDetailPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { eventId } = useParams();
    const [event, setEvent] = useState<TeamEvent>(new TeamEvent({}));
    const [isToggled, setIsToggled] = useState<boolean>(false);

    const fetchEvent = async (eventId: string) => {
        const response = await getTeamEvent(eventId);
        setEvent(new TeamEvent(response));
    };

    const navigateToEditPage = () => {
        navigate(ROUTES.COMMUNITY_EDIT.path(eventId));
    };

    useEffect(() => {
        if (eventId) {
            fetchEvent(eventId);
        }
    }, [eventId]);

    if (!event) {
        return null;
    }

    const handleParticipation = async () => {
        if (event.isAttending) {
            await removeAttendee(event.id);
        } else {
            await addAttendee(event.id);
        }
        fetchEvent(event.id);
    };

    const handleDelete = async () => {
        await deleteTeamEvent(event.id);
        navigate(ROUTES.COMMUNITY.path());
    };

    const openDeleteModal = () => {
        setIsToggled(true);
    };

    const closeDeleteModal = () => {
        setIsToggled(false);
    };

    return (
        <TeamEventsDetailPageStyling>
            <PageHeader className='page-header' title={event.title} />
            <Box className='detail--container'>
                <p className='detail--description'>{event.description}</p>
                <div className='detail--location-time-duration-container'>
                    <div className='detail--subcontainer'>
                        <Title>{t('COMMUNITY_DETAIL.LOCATION')}</Title>
                        <p className='info-paragraph'>{event.location}</p>
                    </div>
                    <div className='detail--subcontainer'>
                        <Title>{t('COMMUNITY_DETAIL.DATE')}</Title>
                        <p className='info-paragraph'>
                            {convertDayjsToDMMMHHmm(event.beginDate)}
                        </p>
                    </div>
                    <div className='detail--subcontainer'>
                        <Title>{t('COMMUNITY_DETAIL.DURATION')}</Title>
                        <p className='info-paragraph'>
                            {convertMinutesToDurationHmm(event.duration)}
                        </p>
                    </div>
                </div>
                <div className='detail--user-container'>
                    {event.users.map((user) => {
                        return (
                            <div key={user.id} className='detail--user-row'>
                                <Avatar src={user.picture} />
                                <p>{user.displayName}</p>
                            </div>
                        );
                    })}
                </div>
            </Box>
            <Tooltip
                text={event.canAttend ? '' : t('COMMUNITY_DETAIL.EVENT_FULL')}
            >
                <Button
                    variant='primaryadd'
                    disabled={!event.canAttend}
                    onClick={handleParticipation}
                >
                    {t(
                        `COMMUNITY_DETAIL.${
                            event.isAttending ? 'STOP_ATTENDING' : 'ATTEND'
                        }`
                    )}
                </Button>
            </Tooltip>
            {event.isOrganiser && (
                <ButtonGroup className='organiser-actions'>
                    <Button
                        className='btn'
                        variant='delete'
                        onClick={openDeleteModal}
                    >
                        {t('COMMUNITY_DETAIL.DELETE')}
                    </Button>

                    <Button
                        className='btn'
                        variant='primary'
                        onClick={navigateToEditPage}
                    >
                        {t('COMMUNITY_DETAIL.EDIT')}
                    </Button>
                </ButtonGroup>
            )}
            <DeleteModal
                open={isToggled}
                title={`Delete ${event.title}`}
                content={<p>{t('MODALS.DELETE_TEAM_EVENT.CONTENT')}</p>}
                handleDelete={handleDelete}
                handleClose={closeDeleteModal}
            />
        </TeamEventsDetailPageStyling>
    );
};

export default TeamEventsDetailPage;
