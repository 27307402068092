import styled from 'styled-components';
import theme from 'utils/theme';

const ACHIEVEMENT_ITEM_SIZE = 60;
const ROOT_ROW_GAP = 48;

export const Root = styled.div`
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: ${ROOT_ROW_GAP}px;
    width: 100%;

    ${theme.mediaQueries.isTablet} {
        grid-template-columns: 1fr 1fr;
    }
`;

export const LevelsContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    grid-area: levels;

    .amounts {
        margin-left: 16px;
    }
`;

export const NextLevel = styled.div<{ levelWidth: number }>`
    position: relative;
    border: solid 2px ${theme.colors.border};
    border-radius: 20px;
    width: 100%;
    height: 20px;

    &:after {
        content: '';
        position: absolute;
        inset: 1px;
        width: ${({ levelWidth }) => levelWidth}%;
        background: linear-gradient(
            90deg,
            rgba(154, 191, 252, 1) 0%,
            rgba(54, 127, 250, 1) 100%
        );
        border-radius: 20px;
        z-index: 1;
    }
`;

export const AchievementItem = styled.div<{ hasLevel: boolean }>`
    position: relative;
    display: grid;
    grid-template-areas:
        'icon title '
        'icon content'
        'icon levels';
    grid-template-columns: 60px 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 8px;

    .achievement-icon {
        grid-area: icon;
        border: solid 1px ${theme.colors.border};
        border-radius: 8px;
        background-color: ${theme.colors.background};
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${ACHIEVEMENT_ITEM_SIZE}px;
        height: ${ACHIEVEMENT_ITEM_SIZE}px;

        svg {
            width: 80%;
            height: 80%;
            filter: ${({ hasLevel }) => !hasLevel && 'grayscale(1)'};
        }
    }

    .achievement-title {
        grid-area: title;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
    }

    .achievement-content {
        grid-area: content;
    }

    &:not(:last-of-type) {
        &:after {
            content: '';
            position: absolute;
            left: 10%;
            right: 10%;
            bottom: -${ROOT_ROW_GAP / 2}px;
            height: 1px;
            background-color: ${theme.colors.border};
        }
    }
`;
