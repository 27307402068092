import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LevelBox from 'pages/home/level-box';
import { useSelector } from 'react-redux';
import { Store } from 'store';
import { User } from 'models/user';
import theme from 'utils/theme';
import { Box } from 'components/box/styling';
import { ROUTES } from 'routes/list';

const Root = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .level-box {
        width: 100%;
    }
`;

const UserBox = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .profile-pic {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        object-fit: contain;
        border: solid 1px ${theme.colors.primary10};
    }

    .profile-displayname {
        color: ${theme.colors.text_primary};
    }
`;

const HomeProfile: React.FC = () => {
    const user: User = useSelector((store: Store) => store.user);

    return (
        <Root className='profile'>
            <UserBox to={ROUTES.USER_PROFILE.path()}>
                <img
                    className='profile-pic'
                    src={user.picture}
                    alt={user.displayName}
                />
                <p className='profile-displayname'>{user.displayName}</p>
            </UserBox>

            <LevelBox />
        </Root>
    );
};

export default HomeProfile;
