import dayjs from 'dayjs';
import { Translation } from 'models/translation';
import { getValueFromObject } from 'utils/get';
import { Article } from 'models/article';
import { getRandomNumber } from 'utils/numbers';
import { IntensityTypes } from 'utils/enums';

export class Traject {
    private _id: string;
    private _title: Translation;
    private _description: Translation;
    private _begin: dayjs.Dayjs;
    private _image: string;
    private _amountOfActionsCompleted: number;
    private _amountOfActions: number;
    private _actionOfTheDay: TrajectAction | undefined;
    private _mainActions: TrajectAction[];
    private _sideActions: TrajectAction[];

    constructor(traject: any) {
        this._id = getValueFromObject(traject, 'id', '');
        this._title = new Translation(getValueFromObject(traject, 'title', ''));
        this._description = new Translation(
            getValueFromObject(traject, 'description', '')
        );
        this._begin = dayjs(getValueFromObject(traject, 'begin', dayjs()));
        this._image = getValueFromObject(traject, 'image', '');
        this._amountOfActionsCompleted = getValueFromObject(
            traject,
            ['amount_of_actions_completed', 'amountOfActionsCompleted'],
            0
        );
        this._amountOfActions = getValueFromObject(
            traject,
            ['amount_of_actions', 'amountOfActions'],
            0
        );

        const actionOfTheDayID = getValueFromObject(
            traject,
            'action_of_the_day_id',
            null
        );
        const actionOfTheDay = getValueFromObject(
            traject,
            'action_of_the_day',
            null
        );
        const mainActions = getValueFromObject(
            traject,
            ['main_actions', 'mainActions'],
            []
        );
        const sideActions = getValueFromObject(
            traject,
            ['side_actions', 'sideActions'],
            []
        );

        this._mainActions = mainActions.map(
            (action: any) => new TrajectAction(action)
        );

        this._sideActions = sideActions.map(
            (action: any) => new TrajectAction(action)
        );

        this._actionOfTheDay = this._mainActions.find(
            (action: TrajectAction) => action.id === actionOfTheDayID
        );

        if (actionOfTheDay) {
            this._actionOfTheDay = new TrajectAction(actionOfTheDay);
        }
    }

    get title(): Translation {
        return this._title;
    }

    get description(): Translation {
        return this._description;
    }

    get begin(): dayjs.Dayjs {
        return this._begin;
    }

    get image(): string {
        return this._image;
    }

    get amountOfActionsCompleted(): number {
        return this._amountOfActionsCompleted;
    }

    get amountOfActions(): number {
        return this._amountOfActions;
    }

    get amountOfCompletedActionsPerActions(): string {
        return `${this._amountOfActionsCompleted} / ${this._amountOfActions}`;
    }

    get hasCompletedTraject(): boolean {
        return this.amountOfActions === this.amountOfActionsCompleted;
    }

    get actionOfTheDay(): TrajectAction | undefined {
        return this._actionOfTheDay;
    }

    get sideActions(): TrajectAction[] {
        return this._sideActions;
    }

    get articles(): Article[] {
        return this._mainActions
            .flatMap((action: TrajectAction) => action.articles)
            .reduce((output: Article[], currentArticle: Article) => {
                const indexOfArticle = output.findIndex(
                    (article: Article) => article.id === currentArticle.id
                );

                if (indexOfArticle === -1) {
                    return [...output, currentArticle];
                }

                return output;
            }, []);
    }
}

export class TrajectAction {
    private _id: string;
    private _title: Translation;
    private _description: Translation;
    private _questionToAskForCompletion: Translation;
    private _image: string;
    private _position: number;
    private _articles: Article[];
    private _tipsAndTricks: Translation[];
    private _responses: TrajectActionResponse[];

    constructor(action: any) {
        this._id = getValueFromObject(action, 'id', '');
        this._title = new Translation(getValueFromObject(action, 'title', ''));
        this._description = new Translation(
            getValueFromObject(action, 'description', '')
        );
        this._questionToAskForCompletion = new Translation(
            getValueFromObject(
                action,
                [
                    'question_to_ask_for_completion',
                    'questionToAskForCompletion'
                ],
                ''
            )
        );
        this._image = getValueFromObject(action, 'image', '');
        this._position = getValueFromObject(action, 'position', 1);

        const articles = getValueFromObject(action, 'articles', []);
        this._articles = articles.map((article: any) => new Article(article));

        const tipsAndTricks = getValueFromObject(action, 'tips_and_tricks', []);
        this._tipsAndTricks = tipsAndTricks.map(
            (tat: any) => new Translation(tat.text)
        );

        const responses = getValueFromObject(action, 'responses', []);
        this._responses = responses.map(
            (response: any) => new TrajectActionResponse(response)
        );
    }

    get id(): string {
        return this._id;
    }

    get title(): Translation {
        return this._title;
    }

    get description(): Translation {
        return this._description;
    }

    get questionToAskForCompletion(): Translation {
        return this._questionToAskForCompletion;
    }

    get image(): string {
        return this._image;
    }

    get articles(): Article[] {
        return this._articles;
    }

    get tipAndTrick(): Translation | null {
        if (!this._tipsAndTricks.length) {
            return null;
        }

        return this._tipsAndTricks[
            getRandomNumber(0, this._tipsAndTricks.length - 1)
        ];
    }

    get hasCompleted(): boolean {
        return Boolean(this._responses.length);
    }

    get hasCompletedPositive(): boolean {
        return (
            this.hasCompleted &&
            Boolean(
                this._responses.find(
                    (res: TrajectActionResponse) => res.completed
                )
            )
        );
    }

    get hasCompletedNegative(): boolean {
        return (
            this.hasCompleted &&
            !Boolean(
                this._responses.find(
                    (res: TrajectActionResponse) => res.completed
                )
            )
        );
    }
}

export class TrajectActionResponse {
    private _id: string;
    private _date: dayjs.Dayjs | null;
    private _completed: boolean;
    private _feeling: IntensityTypes;

    constructor(response: any) {
        this._id = getValueFromObject(response, 'id', '');

        const date = getValueFromObject(response, 'created_at', null);
        this._date = date ? dayjs(date) : null;

        this._completed = getValueFromObject(response, 'completed', false);
        this._feeling = getValueFromObject(
            response,
            'feeling',
            IntensityTypes.MEDIUM
        );
    }

    get completed(): boolean {
        return this._completed;
    }
}

export class NoTraject {}
