import { FEEDBACK_EMOJI_TYPE, IntensityTypes } from 'utils/enums';
import theme from 'utils/theme';

export function parseDifficultyInFeedbackEmoji(
    difficulty: IntensityTypes
): FEEDBACK_EMOJI_TYPE {
    switch (difficulty) {
        case IntensityTypes.EASY:
            return FEEDBACK_EMOJI_TYPE.HAPPY;

        case IntensityTypes.MEDIUM:
            return FEEDBACK_EMOJI_TYPE.GOOD;

        case IntensityTypes.HARD:
            return FEEDBACK_EMOJI_TYPE.ANGRY;
    }
}

export function parseFeedbackEmojiIntoDifficulty(
    fet: FEEDBACK_EMOJI_TYPE
): IntensityTypes {
    switch (fet) {
        case FEEDBACK_EMOJI_TYPE.HAPPY:
            return IntensityTypes.EASY;

        case FEEDBACK_EMOJI_TYPE.GOOD:
            return IntensityTypes.MEDIUM;

        case FEEDBACK_EMOJI_TYPE.ANGRY:
            return IntensityTypes.HARD;

        default:
            return IntensityTypes.MEDIUM;
    }
}

export function parseDifficultyInColor(
    difficulty: IntensityTypes | undefined
): string {
    if (!difficulty) {
        return theme.colors.text_dark;
    }

    switch (difficulty) {
        case IntensityTypes.EASY:
            return theme.colors.success;

        case IntensityTypes.MEDIUM:
            return theme.colors.warning;

        case IntensityTypes.HARD:
            return theme.colors.error;
    }
}
