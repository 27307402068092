import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import theme from 'utils/theme';
import NavBar from 'components/navbar';
import { routeChange } from 'store/routes';

export const PageRootStyle = styled.div<{
    hasNavBar?: boolean;
    isFullWidth?: boolean;
}>`
    width: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: ${theme.colors.background};

    .page-content {
        ${({ isFullWidth }) =>
            !isFullWidth && `max-width: ${theme.space.innerPageContent}px;`}
        width: 100%;
        min-height: calc(
            100vh - ${({ hasNavBar }) => (hasNavBar ? theme.space.navbar : 0)}px
        );
        margin: auto;
        padding: ${theme.space.page};
        display: flex;
        flex-direction: column;
    }

    ${theme.mediaQueries.isDesktop} {
        display: flex;
        flex-direction: column-reverse;
    }
`;

type Props = {
    children?: any;
    className?: any;
    isFullWidth?: boolean;
    hasNavBar?: boolean;
};

export const PageRoot: React.FC<Props> = ({
    children,
    className,
    isFullWidth = false,
    hasNavBar = false
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(routeChange(window.location.href));
    }, []);

    return (
        <PageRootStyle
            className={className}
            hasNavBar={hasNavBar}
            isFullWidth={isFullWidth}
        >
            <div className='page-content'>{children}</div>
            {hasNavBar && <NavBar />}
        </PageRootStyle>
    );
};
