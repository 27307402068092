import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import ListTeamEvents from 'pages/team-events/list';
import SeeAllHeader from 'pages/home/see-all-header';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/list';

const Root = styled.div``;

type Props = {};

const TeamEvents: React.FC<Props> = () => {
    const { t } = useTranslation();

    return (
        <Root className='team-events'>
            <SeeAllHeader
                title={t('HOME.TEAM_EVENTS.TITLE')}
                navPath={ROUTES.COMMUNITY.path()}
            />
            <ListTeamEvents activeDay={dayjs()} />
        </Root>
    );
};

export default TeamEvents;
