import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Form from 'components/form';
import { User, UserProfileType, UserServer } from 'models/user';
import { AVAILABLE_LANGUAGES, AVAILABLE_SEX } from 'utils/enums';
import BigSelector, { Option } from 'components/form/bigSelector';
import useWindowSize from 'hooks/useWindowReszie';
import { getLanguageOptions, getSexOptions } from 'forms/userProfile/options';
import ImageSelector from 'components/form/imageSelector';
import assets from 'assets';
import Button from 'components/button';
import { updateUserProfile } from 'api/user';
import { getValueFromObject } from 'utils/get';
import { useDispatch } from 'react-redux';
import { userUpdate } from 'store/user';
import InputField from 'components/text-field';
import DatePicker from 'components/date-picker';
import useFormValidation, { FormValidations } from 'hooks/useValidation';

const OneColumnThreshold = 600;

type UPType = 'onboarding' | 'userProfile';

type Props = {
    type?: UPType;
    submitText: string;
    user?: User;
    onSubmit: () => void;
};

const OneColumnAreas =
    '"profilepicture" "firstname" "lastname" "birthdate" "sex" "submit"';
const TwoColumnAreas =
    '"profilepicture profilepicture" "firstname lastname" "birthdate birthdate" "sex sex" "submit submit"';

const FormStyled = styled(Form)`
    .submit {
        margin-top: 24px;
        grid-area: submit;

        @media only screen and (min-width: ${OneColumnThreshold}px) {
            width: fit-content;
            margin-left: auto;
        }
    }
`;

const UserProfileForm: React.FC<Props> = ({
    type = 'userProfile',
    submitText,
    user,
    onSubmit
}: Props) => {
    const { t } = useTranslation();
    const windowSize = useWindowSize();
    const dispatch = useDispatch();

    const handleSubmit = async () => {
        try {
            await updateUserProfile(formValues, type === 'onboarding');
            dispatch(userUpdate(new UserServer(formValues)));

            onSubmit();
        } catch (e) {}
    };

    const [formUserData] = useState<UserProfileType>({
        profilePicture: getValueFromObject(user, 'picture', assets.LOGO_BLUE),
        firstName: getValueFromObject(user, 'firstName', ''),
        lastName: getValueFromObject(user, 'lastName', ''),
        sex: getValueFromObject(user, 'sex', AVAILABLE_SEX.NOT_WANT_TO_SAY),
        birthDate: dayjs(
            getValueFromObject(user, 'birthDate', dayjs()),
            'DD/MM/YYYY'
        ),
        appLanguage: getValueFromObject(
            user,
            'appLanguage',
            AVAILABLE_LANGUAGES.EN
        )
    });
    const [sexOptions] = useState<Option[]>(getSexOptions(t));
    const [languageOptions] = useState<Option[]>(getLanguageOptions(t));
    const [gridTemplateArea, setGridTemplateArea] = useState(OneColumnAreas);
    const [gridTemplateColumns, setGridTemplateColumns] = useState('1fr');
    const { formValues, setFormValues, formErrors, setIsSubmitted } =
        useFormValidation(
            {
                firstName: [FormValidations.REQUIRED],
                lastName: [FormValidations.REQUIRED]
            },
            formUserData,
            handleSubmit
        );

    const handleChange = (key: string, value: any) => {
        setFormValues((prev: any) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        let gridTA = OneColumnAreas;
        let gridTC = '1fr';

        if (windowSize.width > OneColumnThreshold) {
            gridTA = TwoColumnAreas;
            gridTC = '1fr 1fr';
        }

        setGridTemplateArea(gridTA);
        setGridTemplateColumns(gridTC);
    }, [windowSize]);

    return (
        <FormStyled
            gridTemplateArea={gridTemplateArea}
            gridTemplateColumns={gridTemplateColumns}
            onHasSubmitted={setIsSubmitted}
        >
            <ImageSelector
                name='profilepicture'
                label={t('USER-PROFILE-FORM.PROFILE_PICTURE.LABEL')}
                value={formValues.profilePicture}
                onChange={(newValue: File) =>
                    handleChange('profilePicture', newValue)
                }
            />
            <InputField
                value={formValues.firstName}
                label={t('USER-PROFILE-FORM.FIRST_NAME.LABEL')}
                onChange={(newValue: string) =>
                    handleChange('firstName', newValue)
                }
                errorText={t(formErrors.firstName)}
            />
            <InputField
                value={formValues.lastName}
                label={t('USER-PROFILE-FORM.LAST_NAME.LABEL')}
                onChange={(newValue: string) =>
                    handleChange('lastName', newValue)
                }
                errorText={t(formErrors.lastName)}
            />
            <BigSelector
                name='sex'
                value={formValues.sex}
                label={t('USER-PROFILE-FORM.SEX.LABEL')}
                options={sexOptions}
                onChange={(newValue: string) => handleChange('sex', newValue)}
            />
            <DatePicker
                value={formValues.birthDate}
                label={t('USER-PROFILE-FORM.BIRTH_DATE.LABEL')}
                onChange={(newValue: any) =>
                    handleChange('birthDate', dayjs(newValue))
                }
            />
            {/* TODO: V2  */}
            {/* <BigSelector
                name='applanguage'
                value={formUserData.appLanguage}
                label={t('USER-PROFILE-FORM.APP_LANGUAGE.LABEL')}
                options={languageOptions}
                onChange={(newValue: string) =>
                    handleChange('appLanguage', newValue)
                }
            /> */}
            <Button className='submit' type='submit'>
                {t(submitText)}
            </Button>
        </FormStyled>
    );
};

export default UserProfileForm;
