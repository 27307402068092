import styled from 'styled-components';
import theme from 'utils/theme';
import { HeaderStyled } from 'components/header';

export const StyledHeader = styled(HeaderStyled)<{ isMobile: boolean }>`
    height: 184px;

    .header-content {
        margin: 0 ${theme.space.page} 0 ${theme.space.page};
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 2fr);
        grid-template-areas:
            'greeting greeting .'
            'welcome-sub-text welcome-sub-text welcome-sub-text';

        .greeting {
            display: flex;
            align-items: center;
            grid-area: greeting;

            img {
                margin-left: 1rem;
                width: 24px;
                height: 24px;
            }

            h3 {
                color: #ffffff;
                grid-area: welcome-text;
                font-size: 24px;
                font-weight: 600;
            }
        }

        .intercom-custom-button {
            grid-area: intercom;
            display: none;
        }

        p {
            grid-area: welcome-sub-text;
            color: #f2f7ff;
            font-size: 16px;
            font-weight: 300;
            margin-top: 3px;
        }

        ${({ isMobile }) =>
            isMobile &&
            `
        grid-template-columns: repeat(2, 2fr) auto;
        grid-template-areas:
            'greeting greeting intercom'
            'welcome-sub-text welcome-sub-text intercom';
            
            .intercom-custom-button {
                display: inherit;
            }
        `}
    }
`;
