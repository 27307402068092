import { PageRoot } from 'pages';
import styled from 'styled-components';

export const TeamEventsDetailPageStyling = styled(PageRoot)`
    .detail--container {
        position: relative;
        margin-bottom: 24px;
    }

    .detail--description {
        margin-top: 24px;
        margin-bottom: 16px;
        color: #8d8d8d;
        font-weight: 500;
    }

    .detail--max-users {
        margin: 16px 0;
        color: #8d8d8d;
    }

    .detail--subcontainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        flex: 1 1 0px;
        .info-paragraph {
            color: #8d8d8d;
            font-size: 13px;
        }
    }

    .detail--location-time-duration-container {
        display: flex;
        margin-top: 8px;
        margin-bottom: 18px;
    }

    .detail--user-container {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        grid-gap: 24px;
    }

    .detail--user-row {
        display: flex;
        gap: 0 1em;
        align-items: center;
        flex: 1 1 auto;
    }

    .edit-icon {
        width: 16px;
        height: 16px;
    }

    .btn {
        box-shadow: 2px 2px 15px 1px #d9dde4;
        z-index: 10;
    }

    .delete {
        color: red;
    }

    .organiser-actions {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
    }

    .modal-footer-container {
        background-color: black;
    }
`;
