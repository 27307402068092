import dayjs from 'dayjs';
import { convertDayjsToUtc } from 'utils/dates';
import { TeamEvent } from 'models/community';
import { get, post, put, remove } from 'api';
import { TeamEventFormType } from 'pages/team-events/form';

export async function getTeamEvents(
    begin: dayjs.Dayjs,
    end: dayjs.Dayjs
): Promise<TeamEvent[]> {
    const formattedDate = (dateToTransform: dayjs.Dayjs) =>
        dateToTransform.format('YYYY-MM-DD');

    const response = await get(
        `/api/v1/team-events?begin=${formattedDate(begin)}&end=${formattedDate(
            end
        )}`
    );
    return response.data.map((item: any) => new TeamEvent(item));
}

export async function getTeamEventsCheckPeriod(
    begin: dayjs.Dayjs,
    end: dayjs.Dayjs
): Promise<dayjs.Dayjs[]> {
    const formattedDate = (dateToTransform: dayjs.Dayjs) =>
        dateToTransform.format('YYYY-MM-DD');

    const response = await get(
        `/api/v1/team-events/check-period?begin=${formattedDate(
            begin
        )}&end=${formattedDate(end)}`
    );
    return response.data.map((item: string) => dayjs(item));
}

export async function getTeamEvent(eventId: string): Promise<any> {
    const response = await get(`/api/v1/team-events/${eventId}`);
    return response.data;
}

export async function updateTeamEvent(
    teamEvent: TeamEventFormType,
    eventId: string
): Promise<any> {
    put(`/api/v1/team-events/${eventId}`, {
        ...teamEvent,
        begin_date: teamEvent.beginDate
    });
}

export async function deleteTeamEvent(eventId: string): Promise<any> {
    remove(`/api/v1/team-events/${eventId}`);
}

export async function addTeamEvent(values: TeamEventFormType): Promise<any[]> {
    const response = await post('/api/v1/team-events', {
        title: values.title,
        begin_date: convertDayjsToUtc(values.beginDate),
        duration: values.duration,
        description: values.description,
        location: values.location,
        intensity: values.intensity
    });

    await addAttendee(response.data.id);

    return [];
}

export async function addAttendee(teamEventId: string): Promise<void> {
    await post(`/api/v1/team-events/${teamEventId}/attend`);
}

export async function removeAttendee(teamEventId: string): Promise<void> {
    await remove(`/api/v1/team-events/${teamEventId}/attend`);
}

export async function getCalendarForUser(): Promise<string> {
    const response = await post(`/api/v1/team-events/calendar`);
    return response.data.url;
}
