import { Translation } from 'models/translation';
import { FormValidations } from 'hooks/useValidation';
import { getValueFromObject } from 'utils/get';

export enum QUESTION_TYPE {
    NUMBER_SCALE = 'NUMBER_SCALE',
    YES_NO = 'YN',
    RANGE = 'R',
    OPTIONS = 'O',
    SORT = 'S',
    INPUT = 'I',
    TEXT = 'TEXT',
    GENDER = 'GENDER',
    DATE_TIME = 'DATE_TIME',
    AVATAR = 'AVATAR'
}

export class QuestionScore {
    private _score: number;
    private _value: string;

    constructor(score: any) {
        this._score = getValueFromObject(score, 'score', 5);
        this._value = getValueFromObject(score, 'value', '');
    }

    get score(): number {
        return this._score;
    }

    get value(): string {
        return this._value;
    }
}

export class Question {
    private _id: string;
    private _text: Translation;
    private _type: QUESTION_TYPE;
    // this is the value of the answer
    private _answer: any;
    private _validations: FormValidations[];
    private _scores: QuestionScore[];

    constructor(
        question: any,
        formValidation: FormValidations[] = [FormValidations.REQUIRED]
    ) {
        this._id = question.id;
        this._text = new Translation(question.text);
        this._type = getValueFromObject(
            question,
            'type',
            QUESTION_TYPE.NUMBER_SCALE
        );
        this._validations = formValidation;

        const scores = getValueFromObject(question, 'scores', []);
        this._scores = scores.map((score: any) => new QuestionScore(score));
    }

    get id(): string {
        return this._id;
    }

    get text(): Translation {
        return this._text;
    }

    get type(): QUESTION_TYPE {
        return this._type;
    }

    get answer(): any {
        return this._answer;
    }

    set answer(answer: any) {
        this._answer = answer;
    }

    get validations(): FormValidations[] {
        return this._validations;
    }

    get scores(): QuestionScore[] {
        return this._scores;
    }
}
