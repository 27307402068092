import Form from 'components/form';
import Input from 'components/input';
import React, { useState } from 'react';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import { getValidationCode } from 'api/auth';
import { EmailRoot, TermsPrivacyContainer } from 'pages/login/styling';
import assets from 'assets';
import { validateEmail } from 'utils/validate';

type Props = {
    onSubmit: (email: string) => void;
};

const LoginEmail: React.FC<Props> = ({ onSubmit }: Props) => {
    const { t } = useTranslation();

    const [email, setEmail] = useState<string>('');
    const [formError, setFormError] = useState<string>('');

    const handleChange = (newValue: string) => {
        setEmail(newValue);
    };

    const handleSubmit = async () => {
        setFormError('');

        try {
            if (!email) {
                return;
            }

            if (!validateEmail(email)) {
                setFormError(t('ERRORS.EMAIL_1'));
                return;
            }

            await getValidationCode(email);
            onSubmit(email);
        } catch (err: any) {
            setFormError(t(err));
        }
    };

    return (
        <EmailRoot className='login-root'>
            <img
                className='logo'
                src={assets.LOGO_WHITE_BLUEBG}
                alt='Coach Me Logo'
            />
            <h1 className='title'>{t('LOGIN.EMAIL.TITLE')}</h1>
            <h3 className='subtitle'>{t('LOGIN.EMAIL.SUBTITLE')}</h3>
            <Form className='form' onSubmit={handleSubmit}>
                <Input
                    type='email'
                    value={email}
                    placeholder={t('LOGIN.EMAIL.PLACEHOLDER')}
                    error={formError}
                    required
                    onChange={handleChange}
                />

                <Button
                    type='submit'
                    variant='primary'
                    disabled={!Boolean(email)}
                >
                    {t(`LOGIN.SUBMIT.EMAIL`)}
                </Button>
            </Form>
            <TermsPrivacyContainer
                dangerouslySetInnerHTML={{
                    __html: t('LOGIN.EMAIL.TAC_PRIVACY')
                }}
            />
        </EmailRoot>
    );
};

export default LoginEmail;
