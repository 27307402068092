import styled from 'styled-components';
import theme from 'utils/theme';

export const AssessmentRoot = styled.div`
    margin: -${theme.space.page};
    background: ${theme.colors.background};

    .progress-bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }

    .subheader {
        margin-bottom: 8px;
    }

    @media only screen and (min-width: 800px) {
        .root-container {
            max-width: 400px;
            max-height: 600px;
            height: 100%;
            width: 100%;
            margin: auto;
        }
    }
`;

export const StyledQuestions = styled.div`
    ${theme.space.screenHeight};
    position: relative;
    display: flex;
    padding: ${theme.space.page};

    .form {
        flex: 1;
        grid-template-rows: 1fr auto;
    }

    .question-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .question-header {
        display: flex;
        column-gap: 2px;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px;

        .text {
            margin-left: 24px;
        }
    }

    .picture-container {
        display: flex;
        justify-content: center;
    }

    .question-indicators-container {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 16px;
        margin-top: 32px;
    }

    .button-container {
        display: flex;
        gap: 8px;
        margin-top: auto;
        width: 100%;

        .button {
            flex: 1;
        }
    }
`;

export const ScreenRoot = styled.div`
    ${theme.space.screenMinHeight};
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${theme.space.page};

    .root-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 24px;
    }

    .intro-container {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .header {
            margin-bottom: 32px;
            font-weight: 600;
        }
    }

    .traject-container {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: 'img title' 'img description';
        grid-column-gap: 32px;
        grid-row-gap: 12px;

        .traject__img {
            grid-area: img;
            width: 100%;
            max-height: 200px;
            object-fit: contain;
            border-radius: 20px;
        }

        .traject__title {
            grid-area: title;
        }

        .traject__description {
            grid-area: description;
        }
    }

    .logo {
        margin-bottom: 80px;
        height: 88px;
    }

    .cta-container {
        flex: 1;
        margin: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .estimation {
            display: flex;
            column-gap: 8px;
            margin-bottom: 8px;
            align-self: center;

            .clock {
                width: 12px;
            }
        }
    }
`;
