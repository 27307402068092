import React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from '@mui/material/Tooltip';
import { UserAvatar } from 'models/user';
import { makeStyles } from '@mui/styles';

type Props = {
    users: UserAvatar[];
    max?: number;
};

const useStyles: Function = makeStyles(() => ({
    MuiAvatarGroup: {
        width: '30px',
        height: '30px',
        backgroundColor: 'black'
    }
}));

// TODO: overwrite current GroupAvatar style
//https:blog.bitsrc.io/4-ways-to-override-material-ui-styles-43aee2348ded
const GroupAvatars: React.FC<Props> = ({ users, max = 4 }) => {
    const classes = useStyles();

    const parsedUsers = users.map((user) => new UserAvatar(user));

    return (
        <AvatarGroup
            max={max}
            spacing='small'
            classes={{ avatar: classes.MuiAvatarGroup }}
        >
            {parsedUsers.map((user: UserAvatar) => (
                <Tooltip key={user.id} title={user.displayName}>
                    <Avatar
                        alt={user.displayName}
                        src={user.picture}
                        sx={{ height: '30px', width: '30px' }}
                    >
                        {user.fallback}
                    </Avatar>
                </Tooltip>
            ))}
        </AvatarGroup>
    );
};

export default GroupAvatars;
