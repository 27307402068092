import React, { useState } from 'react';
import styled from 'styled-components';
import { Question, QuestionScore } from 'models/question';
import { useTranslation } from 'react-i18next';
import { Answer } from 'pages/assessment/inputs/styling';

type Props = {
    question: Question;
    onChange: (newScore: number) => void;
};

type AnswerTypes = 'YES' | 'NO';

const Root = styled.div`
    display: grid;
    grid-template-columns: 100px 100px;
    grid-column-gap: 32px;
    justify-content: center;
`;

const YesNoInput: React.FC<Props> = ({ question, onChange }: Props) => {
    const { t } = useTranslation();
    const [active, setActive] = useState<AnswerTypes | undefined>(undefined);

    const handleClick = (value: AnswerTypes) => {
        const score = question.scores.find(
            (qs: QuestionScore) => qs.value === value
        );
        const defaultScore = value === 'YES' ? 10 : 0;

        onChange(score ? score.score : defaultScore);
        setActive(value);
        makeAnimation(value);
    };

    const makeAnimation = (value: AnswerTypes) => {
        setTimeout(() => {
            setActive(undefined);
            setTimeout(() => {
                setActive(value);
            }, 100);
        }, 100);
    };

    return (
        <Root>
            <Answer
                isActive={active === 'YES'}
                onClick={() => handleClick('YES')}
            >
                {t('ASSESSMENT.INPUTS.YES_NO.YES')}
            </Answer>
            <Answer
                isActive={active === 'NO'}
                onClick={() => handleClick('NO')}
            >
                {t('ASSESSMENT.INPUTS.YES_NO.NO')}
            </Answer>
        </Root>
    );
};

export default YesNoInput;
