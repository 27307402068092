import React from 'react';
import styled from 'styled-components';
import { Header as HeaderText } from 'components/text';
import { Link } from 'react-router-dom';
import assets from 'assets';
import { useTranslation } from 'react-i18next';

type Props = {
    title: string;
    navPath: string;
};

const Root = styled.div`
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .see-all-link {
        display: flex;
        align-items: center;
    }

    a {
        padding-right: 8px;
        color: #8d8d8d;
    }

    svg {
        width: 8px;
        height: 8px;
    }
`;

const SeeAllHeader: React.FC<Props> = ({ title, navPath }: Props) => {
    const { t } = useTranslation();

    return (
        <Root>
            <HeaderText> {title}</HeaderText>
            <div className='see-all-link'>
                <Link to={navPath}>{t('HOME.SEE_ALL')}</Link>
                <assets.ARROW_LEFT_ICON />
            </div>
        </Root>
    );
};

export default SeeAllHeader;
