import dayjs, { Dayjs } from 'dayjs';
import { UserAvatar } from 'models/user';
import { getValueFromObject } from 'utils/get';
import { IntensityTypes } from 'utils/enums';

export class TeamEvent {
    private _id: string;
    private _title: string;
    private _description: string;
    private _begin_date: Dayjs;
    private _location: string;
    private _intensity: IntensityTypes;
    private _duration: number;
    private _users: UserAvatar[];
    private _is_attending: boolean;
    private _max_users: number;
    private _is_organiser: boolean;

    constructor(teamEvent: any) {
        this._id = teamEvent.id;
        this._title = teamEvent.title;
        this._description = teamEvent.description;
        this._begin_date = dayjs(teamEvent.begin_date);
        this._location = teamEvent.location;
        this._intensity = teamEvent.intensity;
        this._duration = teamEvent.duration;
        const users = getValueFromObject(teamEvent, 'users', []);
        this._users = users.map((user: any) => new UserAvatar(user));
        this._is_attending = teamEvent.is_attending;
        this._max_users = teamEvent.max_users;
        this._is_organiser = teamEvent.is_organiser;
    }

    get id(): string {
        return this._id;
    }

    get isAttending(): boolean {
        return this._is_attending;
    }

    get canAttend(): boolean {
        if (this.isAttending) {
            return true;
        }
        return this.users.length + 1 < this.maxUsers;
    }

    get maxUsers(): number {
        return this._max_users;
    }

    get isOrganiser(): boolean {
        return this._is_organiser;
    }

    get title(): string {
        return this._title;
    }

    get description(): string {
        return this._description;
    }

    get beginDate(): Dayjs {
        return this._begin_date;
    }

    get location(): string {
        return this._location;
    }

    get intensity(): IntensityTypes {
        return this._intensity;
    }

    get duration(): number {
        return this._duration;
    }

    get users(): UserAvatar[] {
        return this._users;
    }
}
