import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';

export type Option = {
    value: any;
    label: string;
    icon?: React.ReactNode;
    iconActiveKeepColor?: boolean;
};

type Props = {
    name?: string;
    label: string;
    value: string;
    options: Option[];
    onChange: (newValue: string) => void;
    errorText?: string;
};

type RootProps = {
    name?: string;
};

const Root = styled.div<RootProps>`
    display: flex;
    flex-direction: column;
    grid-area: ${({ name }) => name};
    font-size: 14px;
    .options {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(0, 150px));
        justify-content: center;
        width: 100%;
    }

    .label {
        margin-bottom: 8px;
        color: ${theme.colors.primaryGrey};
    }

    .error-box {
        color: #d32f2f;
        grid-column: span 2;
    }
`;

type OptionItemProps = {
    active: boolean;
    hasIcon: boolean;
    iconActiveKeepColor: boolean;
};

const OptionItem = styled.div<OptionItemProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: ${({ hasIcon }) => (hasIcon ? 'space-between' : 'center')};
    flex-direction: column;
    padding: 24px;
    background-color: #fff;
    border-radius: 20px;
    border: solid 2px
        ${({ active }) => (active ? theme.colors.primary : 'transparent')};

    .option-icon {
        margin-bottom: 4px;
        width: 32px;
        height: 32px;

        svg,
        path {
            fill: ${({ active, iconActiveKeepColor }) =>
                active && !iconActiveKeepColor && theme.colors.primary};
        }
        svg {
            stroke-width: bold;
            width: 100%;
            height: 100%;
        }
    }

    .option-label {
        text-align: center;
        color: ${({ active }) => (active ? theme.colors.primary : 'inherit')};
        font-weight: ${({ active }) => (active ? 500 : 'normal')};
    }
`;

const BigSelector: React.FC<Props> = ({
    name,
    label,
    value,
    options,
    onChange,
    errorText
}: Props) => {
    const [active, setActive] = useState<string>(value);

    const handleOptionClick = (value: string) => {
        setActive(value);
        onChange(value);
    };

    return (
        <Root name={name}>
            <span className='label'>{label}</span>
            <div className='options'>
                {options.map((option: Option) => (
                    <OptionItem
                        key={option.value}
                        active={active === option.value}
                        hasIcon={Boolean(option.icon)}
                        iconActiveKeepColor={Boolean(
                            option.iconActiveKeepColor
                        )}
                        onClick={() => handleOptionClick(option.value)}
                    >
                        {Boolean(option.icon) && (
                            <div className='option-icon'>{option.icon}</div>
                        )}
                        <span className='option-label'>{option.label}</span>
                    </OptionItem>
                ))}
                {errorText && <div className='error-box'>{errorText}</div>}
            </div>
        </Root>
    );
};

export default BigSelector;
