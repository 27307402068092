import assets from 'assets';
import Button from 'components/button';
import { Description, Header, SubHeader } from 'components/text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/list';
import { ScreenRoot } from 'pages/assessment/styling';
import { NoTraject, Traject } from 'models/traject';
import { useTranslationTransform } from 'translations/translation';

type Props = {
    traject: Traject | NoTraject;
};

const EndScreen: React.FC<Props> = ({ traject }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const translateTransform = useTranslationTransform();

    const navigateToHome = () => {
        navigate(ROUTES.HOME.path());
    };

    return (
        <ScreenRoot>
            <div className='root-container'>
                <div className='intro-container'>
                    <img
                        className='logo'
                        src={assets.LOGO_WHITE_BLUEBG}
                        alt='Coach Me Logo'
                    />
                    <div className='text-container'>
                        <Header className='header'>
                            {t('ASSESSMENT.END_SCREEN.TITLE')}
                        </Header>
                        <p className='subheader'>
                            {t('ASSESSMENT.END_SCREEN.SUB_TITLE_1')}
                        </p>
                        <p className='subheader'>
                            {t('ASSESSMENT.END_SCREEN.SUB_TITLE_2')}
                        </p>
                    </div>
                </div>
                {Boolean(traject) && traject instanceof Traject && (
                    <div className='traject-container'>
                        <img
                            className='traject__img'
                            src={traject.image}
                            alt={translateTransform(traject.title)}
                        />

                        <SubHeader className='traject__title'>
                            {translateTransform(traject.title)}
                        </SubHeader>

                        <Description className='traject__description'>
                            {translateTransform(traject.description)}
                        </Description>
                    </div>
                )}
                <div className='cta-container'>
                    <Button className='cta-btn' onClick={navigateToHome}>
                        {t('ASSESSMENT.END_SCREEN.END')}
                    </Button>
                </div>
            </div>
        </ScreenRoot>
    );
};

export default EndScreen;
