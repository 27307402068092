import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';

type Props = {
    amountOfBoxes?: number;
    numbersOnly?: boolean;
    onChange: (token: string) => void;
};

type InputProps = {
    value: string;
    index: number;
    numbersOnly: boolean;
    onChange: (index: number, value: string) => void;
    onSwitchInput: (newIndex: number) => void;
};

const backspaceKey = 8;
const deleteKey = 46;
const leftKey = 37;
const rightKey = 39;

const Input: React.FC<InputProps> = ({
    index,
    value = '',
    numbersOnly,
    onChange,
    onSwitchInput
}: InputProps) => {
    const [item, setItem] = useState(value);

    const handleChange = (event: any) => {
        try {
            const eventValue = event.key;
            // get last element of string => single character per input
            const valueSingleElement = eventValue.slice(-1);

            if (
                numbersOnly &&
                !Number.isSafeInteger(Number.parseInt(valueSingleElement))
            ) {
                return;
            }

            onChange(index, valueSingleElement);
            setItem(valueSingleElement);
        } catch (e) {}
    };

    const handleKeyDown = (event: any) => {
        if ([backspaceKey, deleteKey].includes(event.keyCode)) {
            setItem('');
            onChange(index, '');
        }
    };

    useEffect(() => {
        setItem(value === ' ' ? '' : value);
    }, [value]);

    useEffect(() => {
        const callback = (event: any) => {
            if (
                document.activeElement &&
                document.activeElement.id === `COACHME_INPUT_${index}` &&
                event &&
                event.keyCode
            ) {
                if (event.keyCode === leftKey) {
                    onSwitchInput(index - 1);
                }

                if (event.keyCode === rightKey) {
                    onSwitchInput(index + 1);
                }
            }
        };

        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    return (
        <input
            id={`COACHME_INPUT_${index}`}
            value={item}
            autoComplete='off'
            aria-autocomplete='none'
            type={numbersOnly ? 'number' : 'text'}
            onKeyPress={handleChange}
            onKeyDown={handleKeyDown}
            onChange={() => {}}
        />
    );
};

const InputToken: React.FC<Props> = ({
    amountOfBoxes = 6,
    numbersOnly = true,
    onChange
}: Props) => {
    const [value, setValue] = useState('');
    const [boxes, setBoxes] = useState<any>();

    const goToInputBox = (newIndex: number) => {
        setTimeout(() => {
            const findElement = document.getElementById(
                `COACHME_INPUT_${newIndex}`
            );
            if (!findElement) {
                return;
            }
            findElement.focus();
        }, 1);
    };

    const handleUpdate = (index: number, newValue: string) => {
        const temp = value.split('');

        if (value.length !== amountOfBoxes) {
            for (let i = 0; i < amountOfBoxes; i++) {
                temp.push(' ');
            }
        }

        temp[index] = newValue === '' ? ' ' : newValue;

        setValue(temp.join(''));
        if (newValue !== '') {
            goToInputBox(index + 1);
        }
    };

    const getBoxes = (valueOfBoxes: string = '') => {
        const output = [];

        for (let i = 0; i < amountOfBoxes; i++) {
            output.push(
                <Input
                    key={i}
                    index={i}
                    numbersOnly={numbersOnly}
                    value={
                        valueOfBoxes.length >= i
                            ? valueOfBoxes.substring(i, i + 1)
                            : ' '
                    }
                    onChange={handleUpdate}
                    onSwitchInput={goToInputBox}
                />
            );
        }

        return <> {output.map((element) => element)} </>;
    };

    useEffect(() => {
        onChange(value.trim().length === amountOfBoxes ? value : '');
        setBoxes(getBoxes(value));
    }, [value]);

    useEffect(() => {
        setBoxes(getBoxes());

        const callback = (event: any) => {
            try {
                const paste = // @ts-ignore
                (event.clipboardData || window.clipboardData).getData('text');
                event.preventDefault();
                event.stopPropagation();

                if (numbersOnly && !Number.isInteger(Number.parseInt(paste))) {
                    console.warn('No numbers pasted');
                    return;
                }

                const tempPaste =
                    paste.length > amountOfBoxes
                        ? paste.substring(0, amountOfBoxes!)
                        : paste;

                setValue(tempPaste);
                setTimeout(() => {
                    goToInputBox(
                        tempPaste.length === amountOfBoxes
                            ? tempPaste.length - 1
                            : tempPaste.length
                    );
                }, 100);
            } catch (e) {}
        };

        setTimeout(() => {
            goToInputBox(0);
        }, 50);

        document.addEventListener('paste', callback);
        return () => {
            document.removeEventListener('paste', callback);
        };
    }, []);

    return <Container className='token-input'>{boxes}</Container>;
};

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 50px);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    width: 100%;
    justify-content: center;

    input {
        width: 50px;
        height: 50px;
        text-align: center;
        outline: none;
        font-weight: bold;
        font-size: 32px;
        caret-color: ${theme.colors.primary};
        border: solid 1px ${theme.colors.border};
        border-radius: 4px;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            -moz-appearance: textfield;
        }

        &:focus {
            border: solid thin ${theme.colors.primary};
        }
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: repeat(3, 50px);
    }
`;

export default InputToken;
