import styled from 'styled-components';

type Props = {
    value: number;
};
export const ProgressBar = styled.div<Props>`
    width: auto;
    height: 10px;
    background-color: #f1f5fd;
    border-radius: 50px;

    .progress-value-bar {
        background-color: #3173fa;
        width: ${({ value }) => value}%;
        height: 100%;
        border-radius: 50px;
        transition: all 0.2s ease-in-out;
    }

    .progress-value {
        margin: 0 50%;
        margin-top: 5px;
        font-weight: 600;
        font-size: 12px;
        color: #0c182d;
        opacity: 0.5;
    }
`;
