import { get, post } from 'api';
import { NoTraject, Traject } from 'models/traject';
import { IntensityTypes } from 'utils/enums';
import { Assessment } from 'models/assessment';

export async function getCurrentTraject(): Promise<Traject | NoTraject> {
    try {
        const response = await get('/api/v1/trajects/current');
        return new Traject(response.data);
    } catch (e: any) {
        if (e.toString() === 'ERRORS.NOT_FOUND') {
            return new NoTraject();
        }
        return Promise.reject(e);
    }
}

export async function getCurrentTrajectDetail(): Promise<Traject | NoTraject> {
    try {
        const response = await get('/api/v1/trajects/current/detail');
        return new Traject(response.data);
    } catch (e: any) {
        if (e.toString() === 'ERRORS.NOT_FOUND') {
            console.log('tepels in de keuken hoer');
            return new NoTraject();
        }
        return Promise.reject(e);
    }
}

export async function createTrajectBasedOnAssessment(
    assessment: Assessment
): Promise<Traject> {
    try {
        const response = await post('/api/v1/trajects/current', {
            assessment_id: assessment.id
        });
        return new Traject(response.data);
    } catch (e: any) {
        return Promise.reject(e);
    }
}

export async function completeCurrentTraject(): Promise<void> {
    await post('/api/v1/trajects/current/completed');
}

type UpdateTA = {
    trajectAction: any;
    completed: boolean;
    feeling: IntensityTypes;
};

export async function updateTrajectAction({
    trajectAction,
    completed,
    feeling
}: UpdateTA): Promise<void> {
    await post(`/api/v1/trajects/current/${trajectAction.id}`, {
        completed,
        feeling
    });
}

export async function getHistoryTrajects(): Promise<Traject[]> {
    const response = await get('/api/v1/trajects/history');
    return response.data.map((item: any) => new Traject(item));
}
