import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useWindowSize from 'hooks/useWindowReszie';
import { User } from 'models/user';
import { ROUTES } from 'routes/list';
import theme from 'utils/theme';
import IntercomIcon from 'components/intercom/icons';

type Props = {
    user: User | null;
};

type Settings = {
    api_base: string;
    app_id: string;
    name: string;
    email: string;
    created_at: string;
    hide_default_launcher: boolean;
};

const defaultSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'xua1zd5m'
};

const IntercomContainer: React.FC<Props> = ({ user }: Props) => {
    const loc = useLocation();
    const windowSize = useWindowSize();

    const shouldHide = (): boolean => {
        // Only show intercom on home page, on mobile screen
        const isHomePage = loc.pathname === ROUTES.HOME.path();
        return (
            !isHomePage ||
            windowSize.width < theme.mediaQueries.desktopThreshold
        );
    };

    const [settings, setSettings] = useState<Settings>({
        ...defaultSettings,
        email: '',
        name: '',
        created_at: Date.now().toString(),
        hide_default_launcher: shouldHide()
    });

    useEffect(() => {
        try {
            // @ts-ignore
            window.intercomSettings = settings;
            // @ts-ignore
            window.Intercom('update', window.intercomSettings);
        } catch (e) {
            console.log('INTERCOM', e);
        }
    }, [settings]);

    useEffect(() => {
        setSettings((prev: Settings) => ({
            ...prev,
            hide_default_launcher: shouldHide()
        }));
    }, [loc, windowSize]);

    useEffect(() => {
        if (!user) {
            return;
        }

        setSettings((prev: Settings) => ({
            ...prev,
            email: user.email,
            name: user.displayName,
            created_at: Date.now().toString()
        }));
    }, [user]);

    return null;
};

const Root = styled.div`
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 8px;
    background: #367ffa;
    box-shadow: inset -5px 5px 3px #2d6bd2, inset 5px -5px 3px #3f93ff;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 30px;
        height: 30px;
    }

    svg path {
        fill: white;
    }
`;

export const IntercomCustomButton: React.FC = () => {
    const showIntercom = (): void => {
        // @ts-ignore
        window.Intercom('show');
    };

    return (
        <Root className='intercom-custom-button' onClick={showIntercom}>
            <IntercomIcon />
        </Root>
    );
};

export default IntercomContainer;
