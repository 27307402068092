import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { INTENSITY_COLORS, IntensityTypes } from 'utils/enums';
import { shadeColor } from 'utils/color';

type Props = {
    value: IntensityTypes;
    onChange: (newValue: IntensityTypes) => void;
};

const Root = styled.div`
    .label {
        color: rgba(0, 0, 0, 0.87);
        padding-bottom: 8px;
        padding-left: 4px;
    }
`;

const OptionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Option = styled.div<{ isActive: boolean; color: string }>`
    background-color: ${({ color }) => color};
    width: 100px;
    height: 100px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px
        ${({ isActive, color }) =>
            isActive ? `${shadeColor(color, -40)}` : 'transparent'};
`;

const CommunityIntensitySelector: React.FC<Props> = ({
    value = IntensityTypes.EASY,
    onChange
}: Props) => {
    const { t } = useTranslation();

    const handleOnClick = (option: IntensityTypes) => {
        onChange(option);
    };

    return (
        <Root>
            <p className='label'>{t('COMMUNITY.INTENSITY.TITLE')}</p>
            <OptionsContainer>
                {Object.keys(IntensityTypes).map((key: string) => {
                    // @ts-ignore
                    const type = IntensityTypes[key];
                    // @ts-ignore
                    const color = INTENSITY_COLORS[type];

                    return (
                        <Option
                            key={key}
                            isActive={type === value}
                            color={color}
                            onClick={() => handleOnClick(type)}
                        >
                            {t(`COMMUNITY.INTENSITY.${type}`)}
                        </Option>
                    );
                })}
            </OptionsContainer>
        </Root>
    );
};

export default CommunityIntensitySelector;
