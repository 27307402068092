import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Assessment,
    ASSESSMENT_STATUS,
    UserOnBoardingAssessment
} from 'models/assessment';
import { Question as QuestionModel } from 'models/question';
import StartScreen from 'pages/assessment/start-screen';
import { completeQuestion } from 'api/assessments';
import { AssessmentRoot } from 'pages/assessment/styling';
import Question from 'pages/assessment/question';
import { updateUserProfile } from 'api/user';
import { userUpdate } from 'store/user';
import { UserServer } from 'models/user';
import { UseModelState } from 'hooks/useModelState';
import EndScreen from 'pages/assessment/end-screen';
import ProgressBar from 'components/states/progress-bar';
import { createTrajectBasedOnAssessment } from 'api/traject';
import { NoTraject, Traject } from 'models/traject';

type Props = {
    assessmentObject: Assessment;
    isOnBoarding: boolean;
};

const RenderAssessment: React.FC<Props> = ({
    assessmentObject,
    isOnBoarding
}: Props) => {
    const dispatch = useDispatch();
    const {
        state: { model: assessment },
        handleStateChange
    } = UseModelState<Assessment>(assessmentObject);
    const [traject, setTraject] = useState<Traject | NoTraject>(
        new NoTraject()
    );

    const handleUserSubmit = async (
        nextQuestion: QuestionModel | undefined
    ) => {
        if (!(assessment instanceof UserOnBoardingAssessment) || nextQuestion) {
            return;
        }

        const userFormValues = assessment.getAllAnswers();
        try {
            await updateUserProfile(userFormValues, true);
            dispatch(userUpdate(new UserServer(userFormValues)));
            assessment.completeOnBoarding();
            handleStateChange();
        } catch (err) {
            console.log(err, 'error');
        }
    };

    const handleAssessmentSubmit = async (
        score: number,
        nextQuestion: QuestionModel | undefined
    ) => {
        try {
            await completeQuestion(
                score,
                assessment.currentQuestion.id,
                assessment.id
            );

            assessment.goToNextQuestion();

            if (!nextQuestion) {
                assessment.complete();
                const response = await createTrajectBasedOnAssessment(
                    assessment
                );
                setTraject(response);
            }

            handleStateChange();
        } catch (e) {
            console.log(e);
        }
    };

    const handleSubmit = async (answer: any) => {
        assessment.currentQuestion.answer = answer;
        const nextQuestion = assessment.getNextQuestion();

        try {
            if (assessment.status === ASSESSMENT_STATUS.CREATED) {
                assessment.goToNextQuestion();
                handleStateChange();
                await handleUserSubmit(nextQuestion);
            }

            if (assessment.status === ASSESSMENT_STATUS.IN_PROGRESS) {
                await handleAssessmentSubmit(answer, nextQuestion);
            }

            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };

    const toPreviousQuestion = () => {
        assessment.goToPreviousQuestion();
        handleStateChange();
    };

    const startAssessment = () => {
        assessment.start();
        handleStateChange();
    };

    const renderAssessment = (assessmentToRender: Assessment) => {
        if (assessmentToRender.status === ASSESSMENT_STATUS.COMPLETED) {
            return <EndScreen traject={traject} />;
        }

        const isOnBoardingFlow =
            assessmentToRender.status === ASSESSMENT_STATUS.ON_BOARDED;
        const isAssessmentFlow =
            !isOnBoarding &&
            assessmentToRender.status === ASSESSMENT_STATUS.CREATED;

        if (isOnBoardingFlow || isAssessmentFlow) {
            return <StartScreen onStartAssessment={startAssessment} />;
        }

        const question = assessment.currentQuestion;

        return (
            <>
                <RenderProgressBar assessment={assessment} />
                <Question
                    key={question.id}
                    question={question}
                    toPreviousQuestion={toPreviousQuestion}
                    currentQuestionIndex={assessment.getQuestionIndex(question)}
                    isActive={question.id === assessment.currentQuestion?.id}
                    onSubmit={handleSubmit}
                />
            </>
        );
    };

    return <AssessmentRoot>{renderAssessment(assessment)}</AssessmentRoot>;
};

type ProgressBarProps = {
    assessment: Assessment;
};

const RenderProgressBar: React.FC<ProgressBarProps> = ({
    assessment
}: ProgressBarProps) => {
    if (
        [ASSESSMENT_STATUS.CREATED, ASSESSMENT_STATUS.IN_PROGRESS].includes(
            assessment.status
        )
    ) {
        return (
            <ProgressBar
                className='progress-bar'
                max={assessment.questions.length}
                value={assessment.getAnswersCount()}
            />
        );
    }

    return null;
};

export default RenderAssessment;
