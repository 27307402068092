import React from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';

export const HeaderStyled = styled.div<{ noExtraMargin?: boolean }>`
    position: relative;
    width: calc(100% + (2 * ${theme.space.page}));
    min-height: 124px;
    background: url('dummy/header_background.png'),
        linear-gradient(110.39deg, #0062fa 1.83%, #79a8ff 99.07%) no-repeat;
    background-size: cover;
    margin: -${theme.space.page};
    margin-bottom: ${({ noExtraMargin }) => noExtraMargin && 0};
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${theme.mediaQueries.isDesktop} {
        margin: 0;
        width: fit-content;
        height: fit-content;
        max-width: unset;
        min-height: unset;
        padding: 32px;
        border-radius: 8px;
    }
`;

const HeaderTitleH1 = styled.h1`
    color: ${theme.colors.white};
`;

type HeaderProps = {
    children: any;
    noExtraMargin?: boolean;
};

const Header: React.FC<HeaderProps> = ({
    children,
    noExtraMargin = false
}: HeaderProps) => {
    return (
        <HeaderStyled className='page-header' noExtraMargin={noExtraMargin}>
            {children}
        </HeaderStyled>
    );
};

export const HeaderTitle: React.FC<{ title: string }> = ({ title }) => {
    return <HeaderTitleH1>{title}</HeaderTitleH1>;
};

export default Header;
