import { get } from 'lodash';
import { v4 as uuid } from 'uuid';
import { Dayjs } from 'dayjs';

export function getValueFromObject(
    object: any,
    properties: string | string[],
    defaultValue: any = null
): any {
    if (typeof properties === 'string') {
        return get(object, properties, defaultValue);
    }

    let output = null;
    for (let i = 0; i < properties.length; i++) {
        const val = get(object, properties[i], null);
        if (val) {
            output = val;
            break;
        }
    }

    return output || defaultValue;
}

export function getRandomUUID(): string {
    return uuid();
}

export function getRandomGridAreaName(): string {
    return `grid-area-${getRandomUUID()}`;
}

export function getDateInDDMMYYYY(date: Dayjs): string {
    return date.format('DD/MM/YYYY');
}
