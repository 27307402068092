import { ReactComponent as QUESTIONS_BG } from 'assets/backgrounds/questions_base.svg';
import TRAJECT_BG from 'assets/backgrounds/trajectBG.png';

//https://msurguy.github.io/triangles/
import { ReactComponent as TEAM_EVENT_EASY } from 'assets/backgrounds/team-events-easy.svg';
import { ReactComponent as TEAM_EVENT_MEDIUM } from 'assets/backgrounds/team-events-medium.svg';
import { ReactComponent as TEAM_EVENT_HARD } from 'assets/backgrounds/team-events-hard.svg';

export default {
    QUESTIONS_BG: QUESTIONS_BG,
    TEAM_EVENT_EASY: TEAM_EVENT_EASY,
    TEAM_EVENT_MEDIUM: TEAM_EVENT_MEDIUM,
    TEAM_EVENT_HARD: TEAM_EVENT_HARD,
    TRAJECT_BG: TRAJECT_BG
};
