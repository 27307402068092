import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import styled from 'styled-components';
import PageHeader from 'components/page-header';
import { PageRoot } from 'pages';
import { addTeamEvent } from 'api/team-events';
import { ROUTES } from 'routes/list';
import TeamEventsForm from 'pages/team-events/form';

const RootStyling = styled(PageRoot)``;

const TeamEventsAddPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (formValues: any) => {
        try {
            setIsSubmitting(true);
            await addTeamEvent(formValues);
            navigate(ROUTES.COMMUNITY.path());
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <RootStyling>
            <PageHeader title={t('COMMUNITY_ADD.TITLE')} />
            <TeamEventsForm
                isSubmitting={isSubmitting}
                onSubmit={(formValues: any) => handleSubmit(formValues)}
            />
        </RootStyling>
    );
};

export default TeamEventsAddPage;
