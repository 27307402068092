import { get, put, post, postFile } from 'api';
import { User, UserFeeling, UserProfileType, UserServer } from 'models/user';
import { FEEDBACK_EMOJI_TYPE } from 'utils/enums';

export async function getMe(): Promise<User> {
    return await get('/api/v1/users/me').then((res) => {
        return new UserServer(res.data);
    });
}

export async function updateUserProfile(
    userProfile: UserProfileType,
    isOnboarding: boolean = false
): Promise<void> {
    const calls = [];

    if (userProfile.profilePicture instanceof File) {
        calls.push(updateUserProfilePicture(userProfile.profilePicture));
    }

    calls.push(updateUserProfileData(userProfile));

    await Promise.all(calls);

    if (isOnboarding) {
        await completeOnBoarding();
    }
}

async function updateUserProfileData(
    userProfile: UserProfileType
): Promise<void> {
    await put('/api/v1/users/me', {
        first_name: userProfile.firstName,
        last_name: userProfile.lastName,
        sex: userProfile.sex,
        birth_date: userProfile.birthDate.format('YYYY-MM-DD'),
        app_language: userProfile.appLanguage
    });
}

async function updateUserProfilePicture(file: File): Promise<void> {
    await postFile('/api/v1/users/me/picture', file);
}

async function completeOnBoarding(): Promise<void> {
    await post('/api/v1/users/me/on-boarding');
}

export async function getFeelings(): Promise<UserFeeling[]> {
    const response = await get('/api/v1/users/me/feeling');
    return response.data.map((item: any) => new UserFeeling(item));
}

export async function createFeeling(
    feeling: FEEDBACK_EMOJI_TYPE,
    description: string
): Promise<void> {
    await post('/api/v1/users/me/feeling', {
        feeling,
        description
    });
}

export async function iAmHere(): Promise<void> {
    await post('/api/v1/users/me/iamhere');
}

export async function saveNotificationEndPoint(
    subscription: PushSubscription
): Promise<void> {
    try {
        const userAgent = navigator.userAgent.match(
            /(firefox|msie|chrome|safari|trident)/gi
        );
        const firstBrowser = userAgent && userAgent.length ? userAgent[0] : '';
        const browser = firstBrowser.toLowerCase();

        const keys = subscription.toJSON().keys || {
            auth: '',
            p256dh: ''
        };

        await post('/api/v1/users/me/notifications', {
            end_point: subscription.endpoint,
            browser,
            auth: keys['auth'],
            p256dh: keys['p256dh']
        });
    } catch (e) {}
}
