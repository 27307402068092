export enum STATISTICS_EVENT_TYPE {
    USER_AMOUNT = 'ua',
    USER_POINTS = 'up',
    AVERAGE_USER_POINTS = 'aup',
    ASSESSMENTS_STARTED = 'as',
    ASSESSMENTS_COMPLETED = 'ac',
    ACTION_POINTS_COMPLETED = 'apc',
    ASSESSMENT_RESULTS = 'ar',
    TRAJECTS_STARTED = 'ts',
    TRAJECTS_COMPLETED = 'tc',
    TEAM_EVENTS_CREATED = 'tec',
    TEAM_EVENTS_ATTENDED = 'tea',
    CHALLENGE_RESULTS = 'cr',
    HOW_YOU_DOING_ANGRY = 'hyda',
    HOW_YOU_DOING_SAD = 'hyds',
    HOW_YOU_DOING_OK = 'hydo',
    HOW_YOU_DOING_GOOD = 'hydg',
    HOW_YOU_DOING_HAPPY = 'hydh'
}
