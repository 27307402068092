import { FEEDBACK_EMOJI_TYPE } from 'utils/enums';
import { getValueFromObject } from 'utils/get';
import { ICONS } from 'assets/icons';
import FeedbackEmoji from 'components/feedback/emojiList';
import { STATISTICS_EVENT_TYPE } from 'utils/enums/statisticEventTypes';

export class Statistic {
    private readonly _event: STATISTICS_EVENT_TYPE;
    private readonly _amount: number;
    private readonly _icon: React.ReactNode;
    private readonly _color: string;

    constructor(statistic: any) {
        this._event = getValueFromObject(statistic, 'event');
        this._amount = getValueFromObject(statistic, 'amount', 0);
        this._icon = parseStatisticImage(
            getValueFromObject(statistic, 'event')
        );
        this._color = parseStatisticColor(
            getValueFromObject(statistic, 'event')
        );
    }

    get event(): STATISTICS_EVENT_TYPE {
        return this._event;
    }

    get amount(): number {
        return Math.round(this._amount);
    }

    get icon(): React.ReactNode {
        return this._icon;
    }

    get color(): string {
        return this._color;
    }
}

export class Statistics {
    private readonly _stats: Statistic[];

    constructor(statistics: any) {
        this._stats = statistics.map(
            (statistic: any) => new Statistic(statistic)
        );
    }

    get user(): Statistic[] {
        return [
            this._findStatisticOrDefault(STATISTICS_EVENT_TYPE.USER_AMOUNT),
            this._findStatisticOrDefault(
                STATISTICS_EVENT_TYPE.AVERAGE_USER_POINTS
            ),
            this._findStatisticOrDefault(STATISTICS_EVENT_TYPE.USER_POINTS)
        ];
    }

    get howYouDoings(): Statistic[] {
        return [
            this._findStatisticOrDefault(
                STATISTICS_EVENT_TYPE.HOW_YOU_DOING_ANGRY
            ),
            this._findStatisticOrDefault(
                STATISTICS_EVENT_TYPE.HOW_YOU_DOING_SAD
            ),
            this._findStatisticOrDefault(
                STATISTICS_EVENT_TYPE.HOW_YOU_DOING_OK
            ),
            this._findStatisticOrDefault(
                STATISTICS_EVENT_TYPE.HOW_YOU_DOING_GOOD
            ),
            this._findStatisticOrDefault(
                STATISTICS_EVENT_TYPE.HOW_YOU_DOING_HAPPY
            )
        ];
    }

    get assessment(): Statistic[] {
        return [
            this._findStatisticOrDefault(
                STATISTICS_EVENT_TYPE.ASSESSMENTS_STARTED
            ),
            this._findStatisticOrDefault(
                STATISTICS_EVENT_TYPE.ASSESSMENTS_COMPLETED
            )
        ];
    }

    get traject(): Statistic[] {
        return [
            this._findStatisticOrDefault(
                STATISTICS_EVENT_TYPE.TRAJECTS_STARTED
            ),
            this._findStatisticOrDefault(
                STATISTICS_EVENT_TYPE.TRAJECTS_COMPLETED
            )
        ];
    }

    get getTotalAmountHowYouDoings(): number {
        return this.howYouDoings.reduce(
            (accummulator: number, howYouDoing: Statistic) =>
                accummulator + howYouDoing.amount,
            0
        );
    }

    private _findStatisticOrDefault(event: STATISTICS_EVENT_TYPE): Statistic {
        return (
            this._stats.find((stat: Statistic) => stat.event === event) ||
            new Statistic({ event })
        );
    }
}

const parseStatisticColor = (event: string): string => {
    switch (event) {
        default:
            return '#E5F0FF';
    }
};

const parseStatisticImage = (event: string): JSX.Element => {
    switch (event) {
        case STATISTICS_EVENT_TYPE.USER_AMOUNT:
            return <ICONS.USERS />;
        case STATISTICS_EVENT_TYPE.USER_POINTS:
            return <ICONS.COINS />;
        case STATISTICS_EVENT_TYPE.ASSESSMENTS_STARTED:
            return <ICONS.CLIPBOARD />;
        case STATISTICS_EVENT_TYPE.ASSESSMENTS_COMPLETED:
            return <ICONS.CLIPBOARD_CHECK />;
        case STATISTICS_EVENT_TYPE.ASSESSMENT_RESULTS:
            return <ICONS.CLIPBOARD_LIST />;
        case STATISTICS_EVENT_TYPE.ACTION_POINTS_COMPLETED:
            return <ICONS.FLAG_CHECKERED />;
        case STATISTICS_EVENT_TYPE.TRAJECTS_COMPLETED:
            return <ICONS.FLAG_CHECKERED />;
        case STATISTICS_EVENT_TYPE.TEAM_EVENTS_CREATED:
            return <ICONS.PEOPLE_GROUP />;
        case STATISTICS_EVENT_TYPE.TEAM_EVENTS_ATTENDED:
            return <ICONS.PERSON_CIRCLE_PLUS />;
        case STATISTICS_EVENT_TYPE.CHALLENGE_RESULTS:
            return <ICONS.LIST />;
        case STATISTICS_EVENT_TYPE.HOW_YOU_DOING_ANGRY:
            return (
                <FeedbackEmoji
                    activeValue={FEEDBACK_EMOJI_TYPE.ANGRY}
                    activeEmojisKeys={[FEEDBACK_EMOJI_TYPE.ANGRY]}
                    showLabel={false}
                />
            );
        case STATISTICS_EVENT_TYPE.HOW_YOU_DOING_SAD:
            return (
                <FeedbackEmoji
                    activeValue={FEEDBACK_EMOJI_TYPE.SAD}
                    activeEmojisKeys={[FEEDBACK_EMOJI_TYPE.SAD]}
                    showLabel={false}
                />
            );
        case STATISTICS_EVENT_TYPE.HOW_YOU_DOING_OK:
            return (
                <FeedbackEmoji
                    activeValue={FEEDBACK_EMOJI_TYPE.OK}
                    activeEmojisKeys={[FEEDBACK_EMOJI_TYPE.OK]}
                    showLabel={false}
                />
            );
        case STATISTICS_EVENT_TYPE.HOW_YOU_DOING_GOOD:
            return (
                <FeedbackEmoji
                    activeValue={FEEDBACK_EMOJI_TYPE.GOOD}
                    activeEmojisKeys={[FEEDBACK_EMOJI_TYPE.GOOD]}
                    showLabel={false}
                />
            );
        case STATISTICS_EVENT_TYPE.HOW_YOU_DOING_HAPPY:
            return (
                <FeedbackEmoji
                    activeValue={FEEDBACK_EMOJI_TYPE.HAPPY}
                    activeEmojisKeys={[FEEDBACK_EMOJI_TYPE.HAPPY]}
                    showLabel={false}
                />
            );
        case STATISTICS_EVENT_TYPE.TRAJECTS_STARTED:
            return <ICONS.PLAYER />;
        case STATISTICS_EVENT_TYPE.AVERAGE_USER_POINTS:
            return <ICONS.COINS />;
        default:
            return <></>;
    }
};
