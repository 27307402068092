import React, { BaseSyntheticEvent } from 'react';
import styled from 'styled-components';

type Props = {
    gridTemplateArea?: string;
    gridTemplateColumns?: string;
    className?: any;
    children: React.ReactNode | React.ReactNode[];
    onSubmit?: (event: BaseSyntheticEvent) => void;
    // Works together with the useValidation hook
    onHasSubmitted?: (submitted: boolean) => void;
};

type FormStyledProps = {
    gridTemplateArea: string;
    gridTemplateColumns: string;
};

const FormStyled = styled.form<FormStyledProps>`
    display: grid;
    grid-gap: 32px;
    grid-template-areas: ${({ gridTemplateArea }) => gridTemplateArea};
    grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
`;

const Form: React.FC<Props> = ({
    children,
    gridTemplateArea = '',
    gridTemplateColumns = '1fr',
    className = '',
    onSubmit = () => null,
    onHasSubmitted = () => null
}: Props) => {
    const handleSubmit = (event: BaseSyntheticEvent) => {
        event.preventDefault();
        onHasSubmitted(true);
        onSubmit(event);
    };

    return (
        <FormStyled
            className={className}
            gridTemplateArea={gridTemplateArea}
            gridTemplateColumns={gridTemplateColumns}
            onSubmit={handleSubmit}
        >
            {children}
        </FormStyled>
    );
};

export default Form;
