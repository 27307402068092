import { AxiosResponse, Method } from 'axios';
import dayjs from 'dayjs';

const MINUTES_OF_CACHE = 2;

type CacheItem = {
    url: string;
    response: AxiosResponse;
    updatedOn: dayjs.Dayjs;
};

let cache: CacheItem[] = [];

function findCacheItem(url: string) {
    return (cacheItem: CacheItem) => cacheItem.url === url;
}

export function getCachedResult(url: string): AxiosResponse | null {
    const cacheItem = cache.find(findCacheItem(url));

    if (
        cacheItem &&
        dayjs().diff(cacheItem.updatedOn, 'minutes') <= MINUTES_OF_CACHE
    ) {
        return cacheItem.response;
    }

    return null;
}

export function handleCache(
    url: string,
    method: Method,
    response: AxiosResponse
): void {
    if (method.toLowerCase() === 'get') {
        updateResponse(url, response);
    } else {
        invalidateCache(url);
    }
}

export function clearCache(): void {
    cache = [];
}

function updateResponse(url: string, response: AxiosResponse): void {
    const findIndexCallInCache = cache.findIndex(findCacheItem(url));

    const element: CacheItem = {
        url,
        response,
        updatedOn: dayjs()
    };

    if (findIndexCallInCache === -1) {
        cache.push(element);
        return;
    }

    cache[findIndexCallInCache] = element;
}

function invalidateCache(url: string): void {
    try {
        if (shouldNotInvalidate(url)) {
            return;
        }

        // 4 => /api/v1/users => split will result in ["","api","v1","users"]
        const firstElements = url.split('/').slice(0, 4).join('/');

        for (let i = cache.length - 1; i >= 0; i--) {
            if (cache[i].url.startsWith(firstElements)) {
                cache.splice(i, 1);
            }
        }
    } catch (e) {}
}

function shouldNotInvalidate(url: string): boolean {
    return ['/api/v1/users/me/iamhere'].includes(url);
}
