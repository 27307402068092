import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Day } from 'components/calendar/styling';
import RootCalendar, { ACTIVE_SHOW } from 'components/calendar/root';
import { ICONS } from 'assets/icons';
import styled from 'styled-components';
import { Box } from 'components/box/styling';
import theme from 'utils/theme';

type Props = {
    activeDays: dayjs.Dayjs[];
    onActiveDaySwitch?: (activeDay: dayjs.Dayjs) => void;
    onActiveShowSwitch?: (day: dayjs.Dayjs, activeShow: ACTIVE_SHOW) => void;
};

const Root = styled(Box)`
    position: relative;
    z-index: ${theme.zIndex.calendar};

    display: flex;
    flex-direction: column;

    .arrow-active-show {
        margin-left: auto;
        cursor: pointer;
    }
`;

const Calendar: React.FC<Props> = ({
    activeDays = [],
    onActiveDaySwitch = () => null,
    onActiveShowSwitch = () => null
}) => {
    const hasEventOnDay = (day: dayjs.Dayjs | undefined): boolean => {
        if (!day) {
            return false;
        }

        return Boolean(
            activeDays.find((aDay: dayjs.Dayjs) => aDay.isSame(day, 'date'))
        );
    };
    const [activeShow, setActiveShow] = useState<ACTIVE_SHOW>(ACTIVE_SHOW.WEEK);

    return (
        <Root>
            <RootCalendar
                activeShow={activeShow}
                renderItem={({ day, index, activeDay, todayDay, onClick }) => {
                    const hasEvent = hasEventOnDay(day);
                    return (
                        <Day
                            key={day ? day.format() : index}
                            isActive={Boolean(day?.isSame(activeDay, 'date'))}
                            isToday={Boolean(day?.isSame(todayDay, 'date'))}
                            hasEvent={hasEvent}
                            onClick={() => onClick(day)}
                        >
                            {Boolean(day) && day?.format('DD')}
                        </Day>
                    );
                }}
                onActiveDaySwitch={onActiveDaySwitch}
                onActiveShowSwitch={onActiveShowSwitch}
            />

            {activeShow === ACTIVE_SHOW.MONTH && (
                <ICONS.ARROW_UP
                    className='arrow-active-show'
                    onClick={() => setActiveShow(ACTIVE_SHOW.WEEK)}
                />
            )}
            {activeShow === ACTIVE_SHOW.WEEK && (
                <ICONS.ARROW_DOWN
                    className='arrow-active-show'
                    onClick={() => setActiveShow(ACTIVE_SHOW.MONTH)}
                />
            )}
        </Root>
    );
};

export default Calendar;
