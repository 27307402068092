import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PageRoot } from 'pages';
import { Store } from 'store';
import Header from 'components/page-header';
import UserProfileForm from 'forms/userProfile';
import { useNavigate } from 'react-router-dom';

const Root = styled(PageRoot)``;

const UserProfileEditPage: React.FC = () => {
    const user = useSelector((store: Store) => store.user);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate(-1);
    };

    return (
        <Root>
            <Header title={t('USER_PROFILE_EDIT.TITLE')} />

            <UserProfileForm
                submitText={t('USER_PROFILE_EDIT.SUBMIT')}
                user={user}
                onSubmit={handleSubmit}
            />
        </Root>
    );
};

export default UserProfileEditPage;
