import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from 'components/button';
import { useTranslationTransform } from 'translations/translation';
import { TrajectAction } from 'models/traject';
import { FEEDBACK_EMOJI_TYPE, IntensityTypes } from 'utils/enums';
import { updateTrajectAction } from 'api/traject';
import theme from 'utils/theme';
import FeedbackEmoji from 'components/feedback/emojiList';
import {
    parseDifficultyInFeedbackEmoji,
    parseFeedbackEmojiIntoDifficulty
} from 'pages/goals/detail/utils';

type Props = { trajectAction: TrajectAction };

type Values = {
    completed: boolean;
    feeling: IntensityTypes;
    hasCompleted: boolean;
    showFeelings: boolean;
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 16px;
    color: ${theme.colors.white};

    p {
        text-align: center;
        font-weight: bold;
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: auto;
        margin-top: 80px;
        grid-gap: 16px;

        .button {
            min-height: unset;
            min-width: 100px;
        }

        .cancel {
            color: ${theme.colors.white};
            border-color: ${theme.colors.white};
            background-color: ${theme.colors.primaryVeryDark};
        }

        .submit {
            color: ${theme.colors.primaryVeryDark};
            background-color: ${theme.colors.white};
            border-color: ${theme.colors.white};
        }
    }

    .feedback {
        margin-top: 32px;
    }

    .label-text {
        color: ${theme.colors.white};
        opacity: 1;
    }

    .feedback {
        --normal-shadow: ${theme.colors.white};
        --normal-shadow-top: ${theme.colors.white};
        --normal-mouth: ${theme.colors.white};
        --normal-eye: ${theme.colors.white};
    }
`;

const CompleteBox: React.FC<Props> = ({ trajectAction }: Props) => {
    const { t } = useTranslation();
    const translateTransform = useTranslationTransform();

    const [values, setValues] = useState<Values>({
        completed: false,
        feeling: IntensityTypes.MEDIUM,
        hasCompleted: trajectAction.hasCompleted,
        showFeelings: false
    });

    const handleChange = () => {
        setValues((prev: Values) => ({
            ...prev,
            hasCompleted: false
        }));
    };

    const handleComplete = async (completed: boolean) => {
        if (!completed) {
            try {
                await updateTrajectAction({
                    trajectAction,
                    feeling: IntensityTypes.HARD,
                    completed: false
                });
                setValues((prev: Values) => ({
                    ...prev,
                    completed,
                    feeling: IntensityTypes.HARD,
                    hasCompleted: true
                }));
            } catch (e) {}
            return;
        }

        setValues((prev: Values) => ({
            ...prev,
            completed,
            showFeelings: true
        }));
    };

    const handleFeelingUpdate = (newValue: FEEDBACK_EMOJI_TYPE) => {
        setValues((prev: Values) => ({
            ...prev,
            feeling: parseFeedbackEmojiIntoDifficulty(newValue)
        }));
    };

    const handleFeelingsSubmit = async () => {
        try {
            await updateTrajectAction({
                trajectAction,
                feeling: values.feeling,
                completed: values.completed
            });
            setValues((prev: Values) => ({
                ...prev,
                showFeelings: false,
                hasCompleted: true
            }));
        } catch (e) {
            // todo
        }
    };

    const handleFeelingCancel = () => {
        setValues((prev: Values) => ({ ...prev, showFeelings: false }));
    };

    if (values.hasCompleted) {
        return (
            <Root className='root'>
                <p>{t('TRAJECT.COMPLETED.TITLE')}</p>
                <div className='actions'>
                    <Button
                        variant='secondary'
                        type='button'
                        className='cancel'
                        onClick={handleChange}
                    >
                        {t('TRAJECT.COMPLETED.SUBMIT')}
                    </Button>
                </div>
            </Root>
        );
    }

    if (values.showFeelings) {
        return (
            <Root className='root'>
                <p>{t('TRAJECT.COMPLETE_FEELING.TITLE')}</p>
                <FeedbackEmoji
                    activeValue={parseDifficultyInFeedbackEmoji(values.feeling)}
                    activeEmojisKeys={[
                        FEEDBACK_EMOJI_TYPE.ANGRY,
                        FEEDBACK_EMOJI_TYPE.GOOD,
                        FEEDBACK_EMOJI_TYPE.HAPPY
                    ]}
                    labelKey='DIFFICULTY'
                    onSelect={handleFeelingUpdate}
                />
                <div className='actions'>
                    <Button
                        variant='secondary'
                        type='button'
                        className='cancel'
                        onClick={handleFeelingCancel}
                    >
                        {t('TRAJECT.COMPLETE_FEELING.CANCEL')}
                    </Button>
                    <Button
                        variant='primary'
                        type='button'
                        className='submit'
                        onClick={handleFeelingsSubmit}
                    >
                        {t('TRAJECT.COMPLETE_FEELING.SUBMIT')}
                    </Button>
                </div>
            </Root>
        );
    }

    return (
        <Root className='root'>
            <p>
                {translateTransform(trajectAction.questionToAskForCompletion) ||
                    t('TRAJECT.COMPLETE_QUESTION.TITLE')}
            </p>
            <div className='actions'>
                <Button
                    variant='secondary'
                    type='button'
                    className='cancel'
                    onClick={() => handleComplete(false)}
                >
                    {t('TRAJECT.COMPLETE_QUESTION.NO')}
                </Button>
                <Button
                    variant='primary'
                    type='button'
                    className='submit'
                    onClick={() => handleComplete(true)}
                >
                    {t('TRAJECT.COMPLETE_QUESTION.YES')}
                </Button>
            </div>
        </Root>
    );
};

export default CompleteBox;
