import { get } from 'api';
import { Achievement } from 'models/achievement';
import { ACHIEVEMENTS } from 'utils/enums';

export async function getAchievements(): Promise<Achievement[]> {
    const response = await get('/api/v1/achievements/me');
    return response.data.map(
        (achievement: any) => new Achievement(achievement)
    );
}

export async function getAchievementDetails(
    type: ACHIEVEMENTS
): Promise<Achievement> {
    const response = await get(`/api/v1/achievements/${type}/empty`);
    return new Achievement(response.data);
}
