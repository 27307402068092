import styled from 'styled-components';
import theme from 'utils/theme';
import { Box } from 'components/box/styling';

export const Root = styled(Box)`
    --size-day: 40px;

    .calendar-container {
        max-width: calc(8 * var(--size-day));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .add {
        margin-left: auto;
        padding: 8px 16px;
        min-height: 48px;
    }

    ${theme.mediaQueries.isTablet} {
        --size-day: 60px;
    }

    ${theme.mediaQueries.isDesktop} {
        --size-day: 80px;
    }
`;

export const MonthRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    width: calc(100% - var(--size-day));

    .month {
        text-align: center;
        margin: auto;

        .year {
            margin-left: 8px;
        }
    }

    .arrow {
        cursor: pointer;
        padding: 0 10px;
    }
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(7, var(--size-day));
    grid-gap: 4px;
    text-align: center;
`;

export const WeekDay = styled.div`
    font-weight: 500;
    width: var(--size-day);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Day = styled.div<{
    isActive: boolean;
    isToday: boolean;
    hasEvent: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size-day);
    height: 40px;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        z-index: -1;
        border-radius: 100%;
    }

    ${({ isActive }) =>
        isActive &&
        `
        color: ${theme.colors.white};
        
        &:before {
            background-color: ${theme.colors.primary};
        }
        
        &:hover {
            color: ${theme.colors.white} !important;
        }
        
        &:hover:before {
            background-color: ${theme.colors.primary} !important;
        }
  `};

    ${({ isToday, isActive }) =>
        isToday &&
        !isActive &&
        `
        &:before {
            border-radius: 30%;
            color: ${theme.colors.text_primary};
            background-color: ${theme.colors.primary10};
        }
  `};

    ${({ hasEvent, isActive }) =>
        hasEvent &&
        !isActive &&
        `
        color: ${theme.colors.primary};
    `};

    @media (hover: hover) {
        &:hover {
            color: ${theme.colors.text_primary};
        }

        &:hover:before {
            border-radius: 100%;
            background-color: ${theme.colors.primary10};
        }
    }
`;

export const DayGoalInfo = styled.div<{
    isToday: boolean;
    color: string;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size-day);
    height: 40px;
    color: ${({ color }) => color};

    ${({ isToday }) =>
        isToday &&
        `
    background-color: ${theme.colors.primary10};
    border-radius: 30%;
  `};
`;
