import React from 'react';
import { ACHIEVEMENTS } from 'utils/enums';
import { getValueFromObject } from 'utils/get';
import assets from 'assets/achievements';

export class Achievement {
    private readonly _id: string;
    private readonly _type: ACHIEVEMENTS;
    private readonly _currentAmount: number;
    private readonly _prevLevelAmount: number;
    private readonly _nextLevelAmount: number;
    private readonly _level: number;

    constructor(achievement: any) {
        this._id = getValueFromObject(achievement, 'id', '');
        this._type = getValueFromObject(achievement, ['_type', 'type'], '');
        this._currentAmount = getValueFromObject(
            achievement,
            ['_currentAmount', 'currentAmount', 'current_amount'],
            0
        );
        this._prevLevelAmount = getValueFromObject(
            achievement,
            ['_prevLevelAmount', 'prevLevelAmount', 'prev_level_amount'],
            0
        );
        this._nextLevelAmount = getValueFromObject(
            achievement,
            ['_nextLevelAmount', 'nextLevelAmount', 'next_level_amount'],
            0
        );
        this._level = getValueFromObject(achievement, ['_level', 'level'], 0);
    }

    get id(): string {
        return this._id;
    }

    get type(): ACHIEVEMENTS {
        return this._type;
    }

    get currentAmount(): number {
        return this._currentAmount;
    }

    get prevLevelAmount(): number {
        return this._prevLevelAmount;
    }

    get nextLevelAmount(): number {
        if (this._nextLevelAmount === -1) {
            return this.currentAmount;
        }

        return this._nextLevelAmount;
    }

    get level(): number {
        return this._level;
    }

    get icon(): React.ReactNode {
        switch (this._type) {
            case ACHIEVEMENTS.LOGIN_WEEKLY:
                return <assets.LOGIN_WEEKLY_ICON />;

            case ACHIEVEMENTS.GOALS_CREATED:
                return <assets.GOALS_CREATED_ICON />;

            case ACHIEVEMENTS.GOALS_INFO_ADDED:
                return <assets.GOALS_INFO_ADDED_ICON />;

            case ACHIEVEMENTS.GOALS_COMPLETED:
                return <assets.GOALS_COMPLETED_ICON />;

            case ACHIEVEMENTS.TRAJECT_CREATED:
                return <assets.GOALS_CREATED_ICON />;

            case ACHIEVEMENTS.TRAJECT_INFO_ADDED:
                return <assets.GOALS_INFO_ADDED_ICON />;

            case ACHIEVEMENTS.TRAJECT_COMPLETED:
                return <assets.GOALS_COMPLETED_ICON />;

            case ACHIEVEMENTS.COMMUNITY_TEAM_EVENT_CREATED:
                return <assets.COMMUNITY_TEAM_EVENT_CREATED_ICON />;

            case ACHIEVEMENTS.COMMUNITY_TEAM_EVENT_ATTENDED:
                return <assets.COMMUNITY_TEAM_EVENT_ATTENDED_ICON />;

            case ACHIEVEMENTS.COMMUNITY_CHALLENGE_ATTENDED:
                return <assets.COMMUNITY_CHALLENGE_ATTENDED_ICON />;

            case ACHIEVEMENTS.HOW_YOU_DOING:
                return <assets.HOW_YOU_DOING_ICON />;

            case ACHIEVEMENTS.USER_PROFILE_PICTURE_ADDED:
                return <assets.USER_PROFILE_PICTURE_ADDED />;

            default:
                return null;
        }
    }
}
