import React from 'react';
import { useTranslation } from 'react-i18next';
import assets from 'assets';
import ProgressBar from 'components/states/progress-bar';
import { Level } from 'models/level';
import { getUserLevel } from 'api/level';
import { EmptyStateKey } from 'components/states/empty';
import SingleApiRender from 'components/api-render/single';
import { Root } from 'pages/home/level-box/styling';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/list';

const LevelBox: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(ROUTES.USER_PROFILE.path());
    };

    return (
        <SingleApiRender
            keyState={EmptyStateKey.NO_LEVEL}
            promiseCall={getUserLevel}
            renderItem={(level: Level) => (
                <Root onClick={handleClick}>
                    <div className='status-info level-info'>
                        <assets.LEVEL_ICON />
                        <div className='status-info-subcontainer'>
                            <p>{t('HOME.STATUS.LEVEL')}</p>
                            <h2 className='status-level-title-two'>
                                {level!.level}
                            </h2>
                        </div>
                    </div>
                    <span className='status-vert-border status-border' />
                    <div className='status-info points-info'>
                        <assets.POINTS_ICON />
                        <div className='status-info-subcontainer'>
                            <p>{t('HOME.STATUS.POINTS')}</p>
                            <h2 className='status-level-title-two'>
                                {level!.points}
                            </h2>
                        </div>
                    </div>
                    <span className='status-hor-border status-border' />
                    <div className='status-info-subcontainer current-level'>
                        <h2 className='status-level-title'>{level!.level}</h2>
                        <p>{t('HOME.STATUS.LEVEL')}</p>
                    </div>
                    <ProgressBar
                        className='status-completed'
                        value={level!.percentageCompleted}
                    />
                    <div className='status-info-subcontainer next-level'>
                        <h2 className='status-level-title'>
                            {level!.level + 1}
                        </h2>
                        <p>{t('HOME.STATUS.LEVEL')}</p>
                    </div>
                </Root>
            )}
            className='level-box'
        />
    );
};

export default LevelBox;
