import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getFeelings } from 'api/user';
import { UserFeeling } from 'models/user';
import { PageRoot } from 'pages';
import PageHeader from 'components/page-header';
import { useTranslation } from 'react-i18next';
import { FEEDBACK_EMOJI_TYPE, STATES } from 'utils/enums';
import Loader from 'components/states/loader';
import FeedbackEmoji from 'components/feedback/emojiList';
import theme from 'utils/theme';
import { Box } from 'components/box/styling';
import { Title, Description } from 'components/text';
import { convertDayjsToDMMMHHmm } from 'utils/dates';

type Props = {};

const emptyAmounts = {
    [FEEDBACK_EMOJI_TYPE.ANGRY]: 0,
    [FEEDBACK_EMOJI_TYPE.SAD]: 0,
    [FEEDBACK_EMOJI_TYPE.OK]: 0,
    [FEEDBACK_EMOJI_TYPE.GOOD]: 0,
    [FEEDBACK_EMOJI_TYPE.HAPPY]: 0
};

const Root = styled(PageRoot)``;

const Container = styled(Box)`
    margin-bottom: 32px;

    .title {
        margin-bottom: 24px;
    }

    &.feelings-root {
        display: grid;
        grid-template-areas: 'title' 'container';
        grid-template-columns: 1fr;

        . title {
            grid-area: title;
        }

        .feelings-container {
            grid-area: container;
        }
    }
`;

const FeelingsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;

    ${theme.mediaQueries.isTablet} {
        grid-template-columns: 1fr 1fr;
    }

    .feeling {
        display: grid;
        grid-template-areas: 'feedback title' 'feedback description';
        grid-template-columns: 80px 1fr;
        grid-template-rows: auto 1fr;
        grid-column-gap: 32px;
        grid-row-gap: 8px;
        border: solid 1px ${theme.colors.border};
        border-radius: 8px;
        padding: 20px 16px;

        .feedback {
            grid-area: feedback;
        }

        .title {
            grid-area: title;
            margin: 0;
        }

        .description {
            grid-area: description;
        }
    }
`;

const Amounts = styled.div`
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, 1fr);

    ${theme.mediaQueries.isTablet} {
        grid-template-columns: repeat(5, 1fr);
    }

    .amount {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        max-width: 100px;
        margin: auto;
    }

    .feedback {
        flex: 1;
    }

    .number {
        flex: 1;
        text-align: center;
        font-weight: bold;
    }
`;

const HowUDoingPage: React.FC<Props> = ({}: Props) => {
    const { t } = useTranslation();
    const [feelings, setFeelings] = useState<UserFeeling[]>([]);
    const [amounts, setAmounts] = useState(emptyAmounts);
    const [states, setStates] = useState<STATES>(STATES.LOADING);

    const fetchFeelings = async () => {
        try {
            setStates(STATES.LOADING);
            const response = await getFeelings();
            setFeelings(response);
            setStates(STATES.RESULTS);
        } catch (e) {
            setStates(STATES.ERROR);
        }
    };

    useEffect(() => {
        let output = { ...emptyAmounts };
        feelings.forEach((feeling: UserFeeling) => {
            output[feeling.feeling] += 1;
        });
        setAmounts(output);
    }, [feelings]);

    useEffect(() => {
        fetchFeelings();
    }, []);

    return (
        <Root>
            <PageHeader title={t('HOW_U_DOIGN.TITLE')} />

            <Loader enable={states === STATES.LOADING} />

            <Container>
                <Title className='title'>
                    {t('HOW_U_DOIGN.AMOUNTS.TITLE')}
                </Title>
                <Amounts>
                    {Object.keys(amounts).map((amount: string) => {
                        const amountInFeedbackType =
                            amount as FEEDBACK_EMOJI_TYPE;
                        return (
                            <div key={amount} className='amount'>
                                <FeedbackEmoji
                                    activeValue={amountInFeedbackType}
                                    activeEmojisKeys={[amountInFeedbackType]}
                                />
                                <span className='number'>
                                    {amounts[amountInFeedbackType].toString()}
                                </span>
                            </div>
                        );
                    })}
                </Amounts>
            </Container>

            <Container className='feelings-root'>
                <Title className='title'>
                    {t('HOW_U_DOIGN.ACTIVITY.TITLE')}
                </Title>
                <FeelingsContainer className='feelings-container'>
                    {feelings.map((feeling: UserFeeling) => {
                        return (
                            <div key={feeling.id} className='feeling'>
                                <FeedbackEmoji
                                    activeValue={feeling.feeling}
                                    activeEmojisKeys={[feeling.feeling]}
                                />
                                <Title className='title'>
                                    {convertDayjsToDMMMHHmm(feeling.createdAt)}
                                </Title>
                                <Description className='description'>
                                    {feeling.description}
                                </Description>
                            </div>
                        );
                    })}
                </FeelingsContainer>
            </Container>
        </Root>
    );
};

export default HowUDoingPage;
