import { Box } from 'components/box/styling';
import styled from 'styled-components';
import theme from 'utils/theme';

export const Root = styled(Box)`
    display: grid;
    grid-template-areas:
        'level-info level-info vert-border points-info points-info'
        'hor-border hor-border hor-border hor-border hor-border'
        'current-level completed completed completed next-level';
    grid-template-columns: 1fr 1fr auto 1fr 1fr;
    grid-template-rows: 1fr auto 1fr;
    row-gap: 8px;
    align-items: center;
    min-height: 112px;
    z-index: ${theme.zIndex.content};
    cursor: pointer;

    img,
    svg {
        width: 24px;
        height: 24px;
        margin-right: 12px;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        color: #0c182d;
        opacity: 50%;
    }

    .status-vert-border {
        grid-area: vert-border;
        width: 1px;
        margin-bottom: -8px;
        align-self: stretch;
    }

    .status-hor-border {
        grid-area: hor-border;
        width: auto;
        height: 1.3px;
    }

    .status-border {
        opacity: 0.1;
        background-color: #8b8b8b;
        border-radius: 10px;
    }

    .points-info {
        grid-area: points-info;
    }

    .status-info-subcontainer {
        display: flex;
        flex-direction: column;

        .status-level-title {
            font-weight: 600;
            color: #0c182d;
            font-size: 12px;
        }
    }

    .status-info {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .status-level-title-two {
        font-size: 16px;
        color: #0c182d;
        font-weight: 600;
    }

    .level-info {
        grid-area: level-info;
    }

    .status-completed {
        grid-area: completed;
    }

    .current-level {
        grid-area: current-level;
    }

    .next-level {
        grid-area: next-level;
        align-items: flex-end;
    }
`;
