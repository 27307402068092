import { saveNotificationEndPoint } from 'api/user';

// https://www.attheminute.com/vapid-key-generator

const VAPID_PUBLIC: string = process.env.REACT_APP_VAPID_PUBLIC || '';

const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

const options = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC)
};

export async function subscribeToNotifications(
    pushManager: PushManager
): Promise<void> {
    const currentSub = await pushManager.getSubscription();

    if (currentSub) {
        await saveNotificationEndPoint(currentSub);
        return;
    }

    let permissionState = await pushManager.permissionState(options);

    if (permissionState === 'prompt') {
        await window.Notification.requestPermission();
        permissionState = await pushManager.permissionState(options);
    }

    if (permissionState === 'granted') {
        const subscription = await pushManager.subscribe(options);

        if (subscription) {
            // new window.Notification('hehe');
            await saveNotificationEndPoint(subscription);
        }
    }
}
