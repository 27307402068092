import styled from 'styled-components';
import theme from 'utils/theme';

export const Header = styled.h1`
    font-size: 24px;
    color: #0c182d;
    font-family: MetropolisBold, MetropolisRegular, -apple-system,
        BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const SubHeader = styled.h3`
    font-size: 20px;
    font-family: MetropolisBold, MetropolisRegular, -apple-system,
        BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Title = styled.h5`
    font-size: 16px;
    font-family: MetropolisSemiBold, MetropolisRegular, -apple-system,
        BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Description = styled.p`
    font-size: 14px;
    color: ${theme.colors.primaryGrey};
    line-height: 1.6;
    letter-spacing: 0.8px;
`;
