import assets from 'assets';
import React from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';

type Props = {
    value: string;
    extraSpacing?: boolean;
};

type FormErrorStylingType = {
    extraSpacing?: boolean;
};

const FormErrorStyling = styled.p<FormErrorStylingType>`
    min-height: 20px;
    margin-top: ${({ extraSpacing }) => (extraSpacing ? 24 : 4)}px;
    display: flex;
    align-items: center;

    .icon,
    .icon path {
        margin: 0 8px;
        fill: ${theme.colors.error};
    }

    .text {
        color: ${theme.colors.error};
        text-align: unset;
    }
`;

const FormError: React.FC<Props> = ({ value, extraSpacing = false }: Props) => {
    if (!value) {
        return null;
    }

    return (
        <FormErrorStyling className='form-error' extraSpacing={extraSpacing}>
            <assets.FORM_ERROR_ICON className='icon' />
            <span className='text'>{value}</span>
        </FormErrorStyling>
    );
};

export default FormError;
