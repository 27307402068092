import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { ROUTES } from 'routes/list';
import { PageRoot } from 'pages';
import { getTeamEvent, updateTeamEvent } from 'api/team-events';
import PageHeader from 'components/page-header';
import TeamEventsForm, { TeamEventFormType } from 'pages/team-events/form';
import SingleApiRender from 'components/api-render/single';
import { EmptyStateKey } from 'components/states/empty';
import { useNavigate } from 'react-router-dom';

const TeamEventsEditPage: React.FC = () => {
    const { eventId } = useParams();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (formValues: any) => {
        if (!eventId) {
            return;
        }

        try {
            setIsSubmitting(true);
            await updateTeamEvent(formValues, eventId);
            navigate(ROUTES.COMMUNITY.path());
        } catch (e) {
            console.log(e);
        } finally {
        }
    };
    const fetchEvent = async () => {
        if (!eventId) {
            return Promise.reject('No ID');
        }
        return getTeamEvent(eventId);
    };

    return (
        <PageRoot>
            <PageHeader title={t('COMMUNITY_EDIT.TITLE')} />
            <SingleApiRender
                keyState={EmptyStateKey.NO_TEAM_EVENT_DETAIL}
                promiseCall={fetchEvent}
                renderItem={(event: TeamEventFormType) => {
                    return (
                        <TeamEventsForm
                            event={event}
                            isSubmitting={isSubmitting}
                            onSubmit={handleSubmit}
                        />
                    );
                }}
            />
        </PageRoot>
    );
};

export default TeamEventsEditPage;
