import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Level } from 'models/level';
import theme from 'utils/theme';
import assets from 'assets';
import useWindowSize from 'hooks/useWindowReszie';

type Props = {
    level: Level;
};

const SIZE_1_LEVEL = 60;

const Root = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: ${(SIZE_1_LEVEL + 40) * 6}px;
    overflow: hidden;
    margin: auto;
`;

const LevelStyle = styled.div<{ active: boolean }>`
    color: ${({ active }) =>
        active ? theme.colors.white : theme.colors.text_dark};
    position: relative;
    width: ${SIZE_1_LEVEL}px;
    height: ${SIZE_1_LEVEL}px;

    &:before,
    &:after {
        content: '';
        z-index: ${({ active }) => (active ? 2 : 1)};
        position: absolute;
        top: 42%;
        transform: translateY(-50%);
        height: 4px;
        background-color: ${({ active }) =>
            active ? theme.colors.primary : '#E8EDF8'};
    }

    &:before {
        left: -20px;
        right: calc(100% - 12px);
    }

    &:after {
        left: calc(100% - 12px);
        right: -20px;
    }

    .icon {
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .content {
        z-index: 2;
        position: absolute;
        top: 42%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
    }
`;

type LevelType = {
    content: number;
    active: boolean;
};

const AMOUNT_LEVELS_ROOT_SIZE = {
    320: 3,
    420: 4,
    450: 5,
    [Number.MAX_SAFE_INTEGER]: 6
};

const Levels: React.FC<Props> = ({ level }: Props) => {
    const windowSize = useWindowSize();
    const rootElement = useRef<HTMLDivElement>(null);
    const [levels, setLevels] = useState<LevelType[]>([]);
    const [amountOfLevels, setAmountOfLevels] = useState(6);

    const calculateAmountOfLevels = () => {
        if (!rootElement.current) {
            return;
        }

        const element = rootElement.current;
        const elementWidth = element.clientWidth;

        const AMOUNT_LEVELS_ROOT_SIZE_keys = Object.keys(
            AMOUNT_LEVELS_ROOT_SIZE
        );

        for (let i = 0; i < AMOUNT_LEVELS_ROOT_SIZE_keys.length; i++) {
            const key: number = +AMOUNT_LEVELS_ROOT_SIZE_keys[i];

            if (elementWidth < key) {
                if (amountOfLevels !== AMOUNT_LEVELS_ROOT_SIZE[key]) {
                    setAmountOfLevels(AMOUNT_LEVELS_ROOT_SIZE[key]);
                }

                return;
            }
        }
    };

    useEffect(() => {
        const output: LevelType[] = [];
        const currentLevel = level.level;
        const isTooLowLevel = currentLevel <= amountOfLevels / 2;

        const startI = isTooLowLevel
            ? 0
            : Math.floor(currentLevel - amountOfLevels / 2);
        const endI = isTooLowLevel ? amountOfLevels : startI + amountOfLevels;

        for (let i = startI; i < endI; i++) {
            output.push({
                content: i + 1,
                active: i < currentLevel
            });
        }

        setLevels(output);
    }, [level, amountOfLevels]);

    useEffect(() => {
        calculateAmountOfLevels();
    }, [windowSize, rootElement]);

    return (
        <Root ref={rootElement}>
            {levels.map((l) => (
                <LevelStyle key={l.content} active={l.active}>
                    {l.active && <assets.LEVEL_ACTIVE_ICON className='icon' />}
                    {!l.active && (
                        <assets.LEVEL_DISABLED_ICON className='icon' />
                    )}
                    <span className='content'>{l.content}</span>
                </LevelStyle>
            ))}
        </Root>
    );
};

export default Levels;
