import styled from 'styled-components';
import { PageRoot } from 'pages';

export const HomeLayout = styled(PageRoot)`
    .page-content {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
    }
`;

export const HomeLayoutDesktop = styled(PageRoot)`
    .page-content {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
    }

    .header {
        height: auto;
        min-height: unset;
        margin: 0;
        width: 100%;

        .header-content {
            display: flex;
            flex-direction: column;
            margin: 0;
        }
    }
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, min-content);
    grid-row-gap: 32px;

    overflow: auto;
`;

export const ContentDesktop = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: inherit;
    grid-gap: 32px;
    grid-template-areas:
        'header profile'
        'comingsoon profile'
        'moodbox moodbox'
        'traject traject'
        'team-events team-events';

    overflow: auto;
    margin-top: 32px;

    .header {
        grid-area: header;
    }

    .profile {
        grid-area: profile;
    }

    .mood-box {
        grid-area: moodbox;
        width: 100%;
        margin: auto;
    }

    .coming-soon-container-root {
        grid-area: comingsoon;
    }

    .traject {
        grid-area: traject;
    }

    .team-events {
        grid-area: team-events;
    }
`;
