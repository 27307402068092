import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { clearStorage } from 'utils/localstorage';
import { RootStyling } from 'pages/login/styling';
import LoginEmail from 'pages/login/email';
import LoginCodeValidation from 'pages/login/code';
import { ROUTES } from 'routes/list';
import { User } from 'models/user';
import { getMe } from 'api/user';

type FormValues = {
    email: string;
    code: string;
};

enum FormValueKeys {
    EMAIL = 'EMAIL',
    CODE = 'CODE'
}

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [formValues, setFormValues] = useState<FormValues>({
        email: '',
        code: ''
    });
    const [formState, setFormState] = useState<FormValueKeys>(
        FormValueKeys.EMAIL
    );

    const handleEmailSubmit = (email: string) => {
        setFormState(FormValueKeys.CODE);
        setFormValues((prev) => ({ ...prev, email }));
    };

    const handleCodeSubmit = async () => {
        try {
            const user: User = await getMe();

            const nextInUrl = searchParams.get('next');

            if (nextInUrl && nextInUrl !== ROUTES.LOGIN.path()) {
                navigate(nextInUrl, { replace: true });
                return;
            }

            const nextPath = user.onboardingDone
                ? ROUTES.HOME.path
                : ROUTES.ON_BOARDING.path;

            navigate(nextPath(), { replace: true });
        } catch (e) {
            // todo
        }
    };

    const handleCodeCancel = () => {
        setFormState(FormValueKeys.EMAIL);
    };

    useEffect(() => {
        clearStorage();
    }, []);

    return (
        <RootStyling isFullWidth>
            {formState === FormValueKeys.EMAIL && (
                <LoginEmail onSubmit={handleEmailSubmit} />
            )}
            {formState === FormValueKeys.CODE && (
                <LoginCodeValidation
                    email={formValues.email}
                    onSubmit={handleCodeSubmit}
                    onCancel={handleCodeCancel}
                />
            )}
        </RootStyling>
    );
};

export default LoginPage;
