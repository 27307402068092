import { questions } from 'translations/user-onboarding-assessment-questions';
import { Question } from 'models/question';

export enum ASSESSMENT_STATUS {
    CREATED = '1',
    ON_BOARDED = '2',
    IN_PROGRESS = '3',
    COMPLETED = '4',
    BOUNCED = '5'
}

export class Assessment {
    private readonly _id: string;
    protected readonly _questions: Question[];
    protected _status: ASSESSMENT_STATUS;
    protected _currentQuestion: Question;

    constructor(assessment: any) {
        this._id = assessment.id;
        this._questions = assessment.questions.map(
            (question: any) => new Question(question)
        );
        this._status = parseStatus(assessment.status);
        this._currentQuestion = this._questions[0];
    }

    protected addQuestion(
        question: Question,
        index: number = this._questions.length
    ) {
        this._questions.splice(index, 0, question);
    }

    get id(): string {
        return this._id;
    }

    get questions(): Question[] {
        return this._questions;
    }

    get status(): ASSESSMENT_STATUS {
        return this._status;
    }

    get currentQuestion(): Question {
        return this._currentQuestion;
    }

    get isInProgress(): boolean {
        return this._status === ASSESSMENT_STATUS.IN_PROGRESS;
    }

    public getQuestionIndex(questionInput: Question): number {
        if (!this.currentQuestion) {
            return -1;
        }

        return this.questions.findIndex(
            (question: Question) => question.id === questionInput.id
        );
    }

    public goToNextQuestion() {
        const nextQuestion = this.getNextQuestion();

        if (!nextQuestion) {
            return null;
        }

        this._currentQuestion = nextQuestion;
    }

    public getNextQuestion(): Question {
        return this.questions[this.getQuestionIndex(this.currentQuestion) + 1];
    }

    public goToPreviousQuestion() {
        // TODO: duplication
        let prevQuestion = null;

        try {
            prevQuestion =
                this.questions[this.getQuestionIndex(this.currentQuestion) - 1];
            this._currentQuestion = prevQuestion;
        } catch (e) {
            console.error(e);
        }

        return this.currentQuestion;
    }

    public getAllAnswers() {
        let answers: any = {};
        this.questions.map((question) => {
            answers[question.id] = question.answer;
        });
        return answers;
    }

    public getAnswersCount() {
        const filledAnswers = this.questions.filter((question) => {
            return question.answer;
        });

        return filledAnswers.length;
    }

    public start() {
        this._status = ASSESSMENT_STATUS.IN_PROGRESS;
        this._currentQuestion = this.questions[0];
    }

    public complete() {
        this._status = ASSESSMENT_STATUS.COMPLETED;
    }
}

const parseStatus = (status: string): ASSESSMENT_STATUS => {
    switch (status) {
        case '1':
            return ASSESSMENT_STATUS.CREATED;
        case '2':
            return ASSESSMENT_STATUS.ON_BOARDED;
        case '3':
            return ASSESSMENT_STATUS.IN_PROGRESS;
        case '4':
            return ASSESSMENT_STATUS.COMPLETED;
        case '5':
            return ASSESSMENT_STATUS.BOUNCED;
        default:
            return ASSESSMENT_STATUS.CREATED;
    }
};

const amountOfUserQuestions = 5;

export class UserOnBoardingAssessment extends Assessment {
    constructor(assessment: any, seed: boolean = false) {
        super(assessment);
        if (seed) {
            this.addQuestion(new Question(questions.firstName), 0);
            this.addQuestion(new Question(questions.lastName), 1);
            this.addQuestion(new Question(questions.sex), 2);
            this.addQuestion(new Question(questions.birthDate), 3);
            this.addQuestion(new Question(questions.profilePicture, []), 4);
        }
        this._currentQuestion = this.questions[0];
    }

    get questions(): any[] {
        const questions = [...this._questions];
        if (this.status === ASSESSMENT_STATUS.IN_PROGRESS) {
            return questions.splice(amountOfUserQuestions);
        }
        return questions.splice(0, amountOfUserQuestions);
    }

    public completeOnBoarding() {
        this._status = ASSESSMENT_STATUS.ON_BOARDED;
    }
}
