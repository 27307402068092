import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PageRoot } from 'pages';
import Header, { HeaderTitle } from 'components/header';
import TrajectCurrent from 'pages/traject/current';

export const Root = styled(PageRoot)`
    .traject {
        margin-top: 32px;
    }

    .history-title {
        margin-bottom: 16px;
    }

    .container {
        display: flex;
        flex-direction: column;
    }
`;

const TrajectOverview: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Root hasNavBar>
            <Header noExtraMargin>
                <HeaderTitle title={t('TRAJECT.TITLE')} />
            </Header>

            <TrajectCurrent />
        </Root>
    );
};
export default TrajectOverview;
