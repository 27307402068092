import { Description } from 'components/text';
import { Statistic } from 'models/statistic';
import { StyledHowYouDoingStatisticContainer } from 'pages/statistics/styling';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACES } from 'translations';

type Props = {
    statistics: Statistic[];
    totalAmount: number;
};

export const RenderHowYouDoingStatistics: React.FC<Props> = ({
    statistics,
    totalAmount
}: Props) => {
    const { t } = useTranslation(TRANSLATION_NAMESPACES.STATISTICS);

    const getStatNumber = (statistic: Statistic): number => {
        if (totalAmount === 0) {
            return 0;
        }
        return Math.round((statistic.amount / totalAmount) * 100);
    };

    return (
        <StyledHowYouDoingStatisticContainer>
            <div className='how-you-doing-header-container'>
                <Description>{t(`HOW-YOU-DOING.TITLE`)}</Description>
                <div className='how-you-doing--total'>
                    <Description>{t(`HOW-YOU-DOING.AMOUNT`)}</Description>
                    <p className='statistic-number'>{totalAmount}</p>
                </div>
            </div>

            <div className='how-you-doing--grid-container'>
                {statistics.map((statistic: Statistic, index: number) => (
                    <div className='how-you-doing--item-container' key={index}>
                        <div className='icon-container'>{statistic.icon}</div>
                        <p className='statistic-number'>
                            {getStatNumber(statistic)}%
                        </p>
                    </div>
                ))}
            </div>
        </StyledHowYouDoingStatisticContainer>
    );
};
