import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { User, UserLocal } from 'models/user';
import { getMe, iAmHere } from 'api/user';
import { Store } from 'store';
import { AuthLocal } from 'models/auth';
import { sleep } from 'utils/sleep';
import { userUpdate } from 'store/user';
import { ROUTES } from 'routes/list';
import ScrollToTop from 'routes/scroll-to-top';
import ErrorBox from 'components/api-render/error';
import { PageRoot } from 'pages';
import Loader from 'components/states/loader';
import useWindowSize from 'hooks/useWindowReszie';
import IntercomContainer from 'components/intercom';
import PWAContainer from 'pwa';

const Router: React.FC = () => {
    const routes = useSelector((store: Store) => store.routes);
    const dispatch = useDispatch();
    const windowSize = useWindowSize();

    const [user, setUser] = useState<User | null>(null);
    const [userFirstTimeFetched, setUserFirstTimeFetched] =
        useState<boolean>(false);
    const [loaded, setLoaded] = useState<Boolean>(false);

    const goBackToLogin = async () => {
        window.location.replace(ROUTES.LOGIN.path());
        await sleep(1000);
    };

    const getUser = async () => {
        try {
            const localUser = new UserLocal();

            if (localUser.isUser()) {
                setUser(localUser);

                if (userFirstTimeFetched) {
                    return;
                }
            }

            setUserFirstTimeFetched(true);

            const serverUser = await getMe();
            setUser(serverUser);
        } catch (e) {}
    };

    const handlePageLoad = async () => {
        try {
            const auth = new AuthLocal();

            // REDIRECT TO LOGIN
            if (!auth.hasHeaders()) {
                const currentPathname = window.location.pathname;

                if (currentPathname === ROUTES.LOGIN.path()) {
                    return;
                }
                throw new Error('No auth headers');
            }

            await getUser();
        } catch (e) {
            await goBackToLogin();
        } finally {
            setLoaded(true);
        }
    };

    useEffect(() => {
        handlePageLoad();
    }, [routes]);

    useEffect(() => {
        if (!user) {
            return;
        }
        dispatch(userUpdate(user));
    }, [user]);

    useEffect(() => {
        if (!user?.id) {
            return;
        }
        iAmHere();
    }, [user?.id]);

    useEffect(() => {
        const vw = windowSize.width * 0.01;
        const vh = windowSize.height * 0.01;
        document.documentElement.style.setProperty('--vw', `${vw}px`);
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, [windowSize]);

    if (!loaded) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route
                        path='*'
                        element={
                            <PageRoot>
                                <Loader enable />
                            </PageRoot>
                        }
                    />
                </Routes>
            </BrowserRouter>
        );
    }

    return (
        <BrowserRouter>
            <ScrollToTop />
            <IntercomContainer user={user} />
            {Boolean(user) && <PWAContainer />}
            <Routes>
                {Object.values(ROUTES).map((route) => (
                    <Route
                        key={route.path()}
                        path={route.path()}
                        element={route.element}
                    />
                ))}
                <Route
                    path='*'
                    element={
                        <PageRoot>
                            <ErrorBox canGoToHome />
                        </PageRoot>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
