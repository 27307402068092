import styled from 'styled-components';
import { PageRoot } from 'pages/index';
import theme from 'utils/theme';

const MAX_WIDTH = '400px';

export const RootStyling = styled(PageRoot)`
    padding: 0 ${theme.space.page};
    display: flex;
    justify-content: center;
    align-items: center;

    .login-root {
        width: 100%;
        max-width: ${MAX_WIDTH};
        margin: auto;
        flex: 1;
    }

    form {
        max-width: ${MAX_WIDTH};
    }
`;

export const EmailRoot = styled.div`
    display: flex;
    flex-direction: column;

    .logo {
        margin-top: 64px;
        width: 100px;
        height: 100px;
    }

    .title {
        margin-top: 72px;
    }

    .subtitle {
        margin-top: 16px;
    }

    .form {
        margin-top: 64px;
    }
`;

export const TermsPrivacyContainer = styled.div`
    font-size: 12px;
    line-height: 2;
    margin-top: auto;
    padding-bottom: ${theme.space.page};

    text-align: center;
    max-width: ${MAX_WIDTH};

    a {
        text-decoration: underline;
        font-size: 12px;
        color: ${theme.colors.primaryGrey};
    }
`;

export const CodeRoot = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;

    margin-top: 32px;

    .title {
        margin-top: auto;
    }

    .send-to {
        margin-top: 32px;
    }

    .valid-until {
        margin-top: 16px;
        margin-bottom: 48px;
    }

    .form {
        grid-gap: 48px;
        margin-bottom: auto;
    }

    .form-error {
        margin-top: -48px;
        margin-left: 16px;
    }

    .submit {
    }
`;
