import styled from 'styled-components';
import theme from 'utils/theme';

type InputRootType = {
    disabled: boolean;
    error: boolean;
    name?: string;
};

export const InputRootStyling = styled.div<InputRootType>`
    grid-area: ${({ name }) => name};
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: ${({ disabled }) => disabled && 'none'};
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

    label {
        margin-bottom: 8px;
        color: ${theme.colors.primaryGrey};
    }

    input {
        border: solid 1px
            ${({ error }) => (error ? theme.colors.error : 'transparent')};
        outline: none;
        border-radius: 12px;
        padding: 8px 24px;
        font-size: 16px;
        min-height: ${theme.space.minHeightFormFields};
    }
`;
