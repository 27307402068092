import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from 'utils/theme';

export const NavBar = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: ${theme.space.navbar}px;
    background-color: #fff;
    box-shadow: 0 2px 50px rgba(174, 174, 174, 0.25);
    position: sticky;
    bottom: 0;
    z-index: ${theme.zIndex.navBar};
    margin: auto;
    width: 100%;

    ${theme.mediaQueries.isDesktop} {
        top: 0;
        bottom: unset;
        max-width: unset;
        justify-content: flex-end;
        padding-right: 40px;
    }
`;

export const NavLogo = styled(Link)`
    display: none;
    margin-right: auto;
    margin-left: 120px;
    cursor: pointer;

    img {
        height: 80%;
        width: 60px;
    }

    ${theme.mediaQueries.isDesktop} {
        display: unset;
    }
`;

export const NavLink = styled(Link)`
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #8d8d8d;
    fill: none;

    &.active {
        color: #3b81fa;

        svg,
        path {
            stroke: #3b81fa;
            fill: #3b81fa;
        }

        &.home {
            svg,
            path {
                stroke-width: 0;
                fill: #3b81fa;
            }
        }
    }

    &:hover {
        color: #3b81fa;

        svg,
        path {
            stroke: #3b81fa;
            fill: #3b81fa;
        }
    }

    p {
        font-size: 12px;
        margin-top: 3px;
    }

    svg {
        height: 24px;
        width: 24px;
        stroke: #8d8d8d;
        stroke-width: 3;
    }

    &.home {
        svg,
        path {
            stroke-width: 0;
            fill: #8d8d8d;
        }
    }

    ${theme.mediaQueries.isDesktop} {
        flex: unset;
        width: 100px;
    }
`;
