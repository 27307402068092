import dayjs, { Dayjs } from 'dayjs';

import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import 'dayjs/locale/fr';

import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export const convertDayjsToUtc = (date: Dayjs): string => {
    return dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
};

export const convertDayjsToDMMMHHmm = (date: Dayjs) => {
    return dayjs(date).format('D MMMM HH:mm');
};

export const convertMinutesToDurationHmm = (minutes: number) => {
    return dayjs.duration(minutes, 'minutes').format('H:mm');
};

export const convertDayjsToHHmm = (date: Dayjs) => {
    return dayjs(date).format('HH:mm');
};

export const updateLocale = (locale: 'nl') => {
    dayjs.locale(locale);
};
