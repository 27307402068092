import { createStore, combineReducers, Store as ReduxStore } from 'redux';
import { routeReducer as routes, State as RoutesType } from 'store/routes';
import { userReducer as user, State as UserType } from 'store/user';

export type Store = {
    routes: RoutesType;
    user: UserType;
};

const store: ReduxStore<Store> = createStore(
    combineReducers({
        routes,
        user
    })
);

export type Action = {
    type: string;
    payload?: any;
};

export default store;
