import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Root,
    EmptyRoot,
    DayIndicatorContainer
} from 'pages/traject/current/styling';
import SingleApiRender from 'components/api-render/single';
import { EmptyStateKey } from 'components/states/empty';
import { getCurrentTraject } from 'api/traject';
import { NoTraject, Traject } from 'models/traject';
import { useTranslationTransform } from 'translations/translation';
import { Title, Description, SubHeader } from 'components/text';
import Button from 'components/button';
import { DAY_INDICATOR_GROUP } from 'assets/dayIndicator';
import { ROUTES } from 'routes/list';
import RenderActionOfTheDay from 'pages/traject/current/action-of-the-day';
import RenderCompletedTraject from 'pages/traject/current/completed-traject';
import { ICONS } from 'assets/icons';

type Props = {
    traject: Traject;
};

const TrajectCurrent: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleGoToAssessment = () => {
        navigate(ROUTES.ASSESSMENT.path());
    };

    const handleGoToDetails = () => {
        navigate(ROUTES.TRAJECT_DETAILS.path());
    };

    return (
        <SingleApiRender
            keyState={EmptyStateKey.NO_TRAJECT_CURRENT}
            promiseCall={getCurrentTraject}
            renderItem={(traject: Traject | NoTraject) => {
                if (traject instanceof NoTraject) {
                    return (
                        <EmptyRoot className='traject'>
                            <Title>{t('TRAJECT.EMPTY.TITLE')}</Title>
                            <Description>
                                {t('TRAJECT.EMPTY.DESCRIPTION')}
                            </Description>
                            <Button onClick={handleGoToAssessment}>
                                {t('TRAJECT.EMPTY.SUBMIT')}
                            </Button>
                        </EmptyRoot>
                    );
                }

                const hasCompletedTraject = traject.hasCompletedTraject;

                return (
                    <Root className='traject' onClick={handleGoToDetails}>
                        <DayIndicatorContainer>
                            <DAY_INDICATOR_GROUP className='group' />
                            <span className='text'>{t('TRAJECT.TODAY')}</span>
                        </DayIndicatorContainer>

                        {!hasCompletedTraject && (
                            <>
                                <RenderActionOfTheDay
                                    actionOfTheDay={traject.actionOfTheDay}
                                />
                                <RenderFooter traject={traject} />
                            </>
                        )}

                        {hasCompletedTraject && (
                            <RenderCompletedTraject traject={traject} />
                        )}
                    </Root>
                );
            }}
        />
    );
};

const RenderFooter: React.FC<Props> = ({ traject }: Props) => {
    const { t } = useTranslation();
    const translateTransform = useTranslationTransform();

    const getActionText = () => {
        let output = 'TODO';

        if (
            traject.actionOfTheDay &&
            traject.actionOfTheDay.hasCompletedPositive
        ) {
            output = 'DONE_POSITIVE';
        }

        if (
            traject.actionOfTheDay &&
            traject.actionOfTheDay.hasCompletedNegative
        ) {
            output = 'DONE_NEGATIVE';
        }

        return t(`TRAJECT.CURRENT.ACTIONS.${output}`);
    };

    return (
        <div className='traject__footer'>
            <SubHeader className='title'>
                {translateTransform(traject.title)}
            </SubHeader>

            <p className='amount-of-actions'>
                {traject.amountOfCompletedActionsPerActions}{' '}
                {t('TRAJECT.ACTIONS')}
            </p>

            <div className='action'>
                <span className='text'>{getActionText()}</span>
                <ICONS.ARROW_CHEVRON_RIGHT className='icon' />
            </div>
        </div>
    );
};

export default TrajectCurrent;
