import React from 'react';
import styled from 'styled-components';
import { NoTraject, Traject, TrajectAction } from 'models/traject';
import { EmptyStateKey } from 'components/states/empty';
import { getCurrentTrajectDetail } from 'api/traject';
import DetailPageLayout from 'pages/detailPageLayout';
import { TipAndTrick } from 'pages/traject/styling';
import { useTranslationTransform } from 'translations/translation';
import { Description, Title } from 'components/text';
import assets from 'assets';
import { useTranslation } from 'react-i18next';
import { Article } from 'models/article';
import CompleteBox from 'pages/traject/detail/completeBox';
import TrajectHorizontalList from 'pages/traject/detail/horizontalList';
import { ICONS } from 'assets/icons';
import theme from 'utils/theme';
import { DAY_INDICATOR_GROUP } from 'assets/dayIndicator';
import Button from 'components/button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/list';

const Root = styled.div`
    .description {
        margin-bottom: 32px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
`;

const TrajectContainer = styled(Container)`
    display: grid;
    grid-template-areas:
        'img title'
        'img description';
    grid-template-columns: 1fr 2fr;
    grid-template-rows: min-content 1fr;
    border-radius: 20px;

    .title {
        grid-area: title;
    }

    .description {
        grid-area: description;
        margin: 0;
    }

    .img {
        grid-area: img;
        width: 100%;
        border-radius: 20px;
    }
`;

const fontSizeIcon = 40;

const ActionPointContainer = styled(Container)`
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: center;
    border-radius: 20px;
    position: relative;

    .root {
        z-index: 2;
        padding-top: 48px;
        padding-bottom: 0;
    }

    .bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: inherit;
    }

    .icon {
        display: none;
        position: absolute;
        top: -${fontSizeIcon / 2}px;
        left: 50%;
        transform: translateX(-50%);
        font-size: ${fontSizeIcon}px;
        z-index: 3;
        border-radius: 100%;
        background-color: ${theme.colors.background};
        color: ${theme.colors.primaryVeryDark};
        border: solid 3px ${theme.colors.background};

        ${theme.mediaQueries.isTablet} {
            display: unset;
        }
    }
`;

const DayIndicatorContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 40px;
    z-index: 2;

    .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-75%, -60%);
        font-weight: 600;
        font-size: 20px;
        color: ${theme.colors.white};
    }

    .group {
        position: absolute;
        top: -32px;
        left: 0;
        width: 100%;
    }
`;

const showExtras = false;

const TrajectDetailPage: React.FC = () => {
    const { t } = useTranslation();
    const translateTransform = useTranslationTransform();

    const getItemType = (traject: Traject | NoTraject) => {
        if (traject instanceof NoTraject) {
            return {
                title: t('TRAJECT.EMPTY.HEADER'),
                asset: (
                    <img
                        src={assets.LOGO_BLUE}
                        alt={t('TRAJECT.EMPTY.HEADER')}
                    />
                )
            };
        }

        const element = traject.actionOfTheDay || traject;

        return {
            title: translateTransform(element.title),
            asset: (
                <img
                    src={element.image}
                    alt={translateTransform(element.title)}
                />
            )
        };
    };

    return (
        <DetailPageLayout
            keyState={EmptyStateKey.NO_TRAJECT_CURRENT}
            promiseCall={getCurrentTrajectDetail}
            renderItem={(traject: Traject | NoTraject) => {
                console.log(traject, traject instanceof NoTraject);

                if (traject instanceof NoTraject) {
                    return (
                        <Root>
                            <RenderEmptyTraject />
                        </Root>
                    );
                }

                const actionOfTheDay = traject.actionOfTheDay;
                const tipAndTrick = actionOfTheDay
                    ? actionOfTheDay!.tipAndTrick
                    : null;

                return (
                    <Root>
                        {Boolean(actionOfTheDay) && (
                            <RenderActionOfTheDay action={actionOfTheDay!} />
                        )}
                        {!Boolean(actionOfTheDay) && (
                            <RenderEmptyActionOfTheDay />
                        )}
                        {Boolean(tipAndTrick) && (
                            <Container>
                                <TipAndTrick>
                                    <assets.TIP_AND_TRICK_LIGHT_BULB_ICON className='icon' />
                                    <span className='text'>
                                        {translateTransform(tipAndTrick!)}
                                    </span>
                                </TipAndTrick>
                            </Container>
                        )}

                        <Container>
                            <Title className='title'>
                                {t('TRAJECT.PART_OF_TRAJECT')}
                            </Title>

                            <TrajectContainer>
                                <Title className='title'>
                                    {translateTransform(traject.title)}
                                </Title>
                                <Description className='description'>
                                    {translateTransform(traject.description)}
                                </Description>
                                <img
                                    className='img'
                                    src={traject.image}
                                    alt={translateTransform(traject.title)}
                                />
                            </TrajectContainer>
                        </Container>

                        {showExtras && Boolean(traject.sideActions.length) && (
                            <Container>
                                <Title>
                                    {t('TRAJECT.SIDE_ACTION_POINTS_TITLE')}
                                </Title>
                                <TrajectHorizontalList
                                    items={traject.sideActions.map(
                                        (action: TrajectAction) => ({
                                            id: action.id,
                                            title: translateTransform(
                                                action.title
                                            ),
                                            description: translateTransform(
                                                action.description
                                            ),
                                            img: action.image
                                        })
                                    )}
                                />
                            </Container>
                        )}
                        {showExtras && Boolean(traject.articles.length) && (
                            <Container>
                                <Title>{t('TRAJECT.ARTICLES_TITLE')}</Title>
                                <TrajectHorizontalList
                                    items={traject.articles.map(
                                        (article: Article) => ({
                                            id: article.id,
                                            title: article.title,
                                            description: article.contentShort,
                                            img: article.coverPicture
                                        })
                                    )}
                                />
                            </Container>
                        )}
                    </Root>
                );
            }}
            getItemType={getItemType}
        />
    );
};

type PropsRAOTD = {
    action: TrajectAction;
};

const RootRender = styled.div``;

const RenderActionOfTheDay: React.FC<PropsRAOTD> = ({ action }: PropsRAOTD) => {
    const translateTransform = useTranslationTransform();
    const { t } = useTranslation();

    return (
        <RootRender>
            <Description className='description'>
                {translateTransform(action.description)}
            </Description>

            <ActionPointContainer>
                <DayIndicatorContainer>
                    <DAY_INDICATOR_GROUP className='group' />
                    <span className='text'>
                        {t('TRAJECT.DETAILS_QUESTION')}
                    </span>
                </DayIndicatorContainer>
                <ICONS.QUESTION className='icon' />
                <CompleteBox trajectAction={action} />
                <assets.QUESTIONS_BG className='bg' />
            </ActionPointContainer>
        </RootRender>
    );
};

const EmptyContainer = styled.div`
    color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 32px;

    .description {
        color: ${theme.colors.white};
        margin: 0;
    }

    .button {
        margin: auto;
    }
`;

const RenderEmptyActionOfTheDay: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate(ROUTES.ASSESSMENT.path());
    };

    return (
        <RootRender>
            <ActionPointContainer>
                <DayIndicatorContainer>
                    <DAY_INDICATOR_GROUP className='group' />
                    <span className='text'>
                        {t('TRAJECT.DETAILS_QUESTION')}
                    </span>
                </DayIndicatorContainer>
                <ICONS.QUESTION className='icon' />
                <EmptyContainer className='root'>
                    <Title className='title'>
                        {t('TRAJECT.EMPTY.ACTION.TITLE')}
                    </Title>
                    <Description className='description'>
                        {t('TRAJECT.EMPTY.ACTION.DESCRIPTION')}
                    </Description>
                    <Button onClick={handleSubmit}>
                        {t('TRAJECT.EMPTY.ACTION.SUBMIT')}
                    </Button>
                </EmptyContainer>
                <assets.QUESTIONS_BG className='bg' />
            </ActionPointContainer>
        </RootRender>
    );
};

const RenderEmptyTraject: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate(ROUTES.ASSESSMENT.path());
    };

    return (
        <RootRender>
            <ActionPointContainer>
                <DayIndicatorContainer>
                    <DAY_INDICATOR_GROUP className='group' />
                    <span className='text'>
                        {t('TRAJECT.DETAILS_QUESTION')}
                    </span>
                </DayIndicatorContainer>
                <ICONS.QUESTION className='icon' />
                <EmptyContainer className='root'>
                    <Title className='title'>{t('TRAJECT.EMPTY.TITLE')}</Title>
                    <Description className='description'>
                        {t('TRAJECT.EMPTY.DESCRIPTION')}
                    </Description>
                    <Button onClick={handleSubmit}>
                        {t('TRAJECT.EMPTY.SUBMIT')}
                    </Button>
                </EmptyContainer>
                <assets.QUESTIONS_BG className='bg' />
            </ActionPointContainer>
        </RootRender>
    );
};

export default TrajectDetailPage;
