import React from 'react';
import { useTranslation } from 'react-i18next';
import { Content, HomeLayout } from 'pages/home/styling';
import Header from 'pages/home/header';
import LevelBox from 'pages/home/level-box';
import MoodBox from 'pages/home/mood-box';
import HomeChallenge from 'components/home-challenge';
import { ROUTES } from 'routes/list';
import useWindowSize from 'hooks/useWindowReszie';
import themes from 'utils/theme';
import HomeDesktopPage from 'pages/home/index-desktop';
import TrajectCurrent from 'pages/traject/current';
import TeamEvents from 'pages/home/team-events';
import SeeAllHeader from 'pages/home/see-all-header';

const HomePage: React.FC = () => {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    if (width >= themes.mediaQueries.desktopThreshold) {
        return <HomeDesktopPage />;
    }

    return (
        <HomeLayout hasNavBar>
            <Header isMobile />
            <Content>
                <LevelBox />
                <MoodBox />
                <div>
                    <SeeAllHeader
                        title={t('HOME.TRAJECT.TITLE')}
                        navPath={ROUTES.TRAJECT.path()}
                    />
                    <TrajectCurrent />
                </div>
                <HomeChallenge />
                <TeamEvents />
            </Content>
        </HomeLayout>
    );
};

export default HomePage;
