import { createTheme } from '@mui/material';

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    width: '100%'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'center'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    borderRadius: '8px !important'
                }
            }
        }
    }
});
export { theme };
