import styled from 'styled-components';
import theme from 'utils/theme';

export const EventBox = styled.div<{ color: string }>`
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    color: ${theme.colors.white};
    overflow: hidden;

    .bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
    }

    .custom-box-container {
        background: transparent;
        z-index: 10;
    }

    .intensity-text {
        position: absolute;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 50px;
        z-index: 1;
        color: white;
        opacity: 0.2;
    }

    .full {
        position: absolute;
        right: 0;
        top: 0;
        padding: 4px 8px;
        padding-right: 16px;
        border-radius: 4px;
        border-top-right-radius: 20px;
        color: ${theme.colors.white};
        background-color: ${theme.colors.primary};
    }

    .name {
        font-size: 18px;
        font-weight: 600;
    }

    .location,
    .begin {
        margin-bottom: 8px;
        color: ${theme.colors.white};
    }

    .footer {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
    }

    .participants {
        width: fit-content;
        padding-left: 16px;
    }

    .arrow {
    }
`;
