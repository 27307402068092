import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import theme from 'utils/theme';
import { getTeamEventsCheckPeriod } from 'api/team-events';
import Button from 'components/button';
import HomeChallenge from 'components/home-challenge';
import { Header } from 'components/text';
import { PageRoot } from 'pages';
import Calendar from 'components/calendar';
import { ROUTES } from 'routes/list';
import { STATES } from 'utils/enums';
import HeaderPage, { HeaderTitle } from 'components/header';
import { useTranslation } from 'react-i18next';
import ListTeamEvents from 'pages/team-events/list';
import LinkCalendar from 'pages/team-events/link-calendar';
import { ACTIVE_SHOW } from 'components/calendar/root';

const RootStyling = styled(PageRoot)`
    .coming-soon-container-root {
        margin-top: 16px;
    }

    .date {
        color: #838383;
        text-align: left;
        margin-bottom: 16px;
    }

    .subheader {
        margin-top: 32px;
        margin-bottom: 24px;
    }

    .btn {
        width: fit-content;
        position: sticky;
        bottom: ${theme.space.navbar + 32}px;
        left: 100%;
        font-size: 32px;
        font-weight: 400;
        box-shadow: 2px 2px 15px 1px #d9dde4;
        margin-top: 32px;
        z-index: 10;
    }

    .render-helper {
        min-height: 250px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;

    ${theme.mediaQueries.isDesktop} {
        .page-header {
        }

        .coming-soon-container-root {
            width: 100%;
            max-width: 500px;
            margin: auto;
            margin-top: 0;
        }

        .calendar {
            margin: 0;
            display: flex;
        }

        .link-calendar {
        }

        .list {
        }

        .subheader {
            margin-top: 0;
        }
    }
`;

const TeamEventsPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [teamEventState, setTeamEventState] = useState<STATES>(STATES.EMPTY);
    const [activeDay, setActiveDay] = useState<dayjs.Dayjs>(dayjs());
    const [activeDaysWithEvent, setActiveDaysWithEvent] = useState<
        dayjs.Dayjs[]
    >([]);

    const navigateToAdd = () => {
        navigate(ROUTES.COMMUNITY_ADD.path(activeDay.format('DD-MM-YYYY')));
    };

    const handleActiveShowSwitch = async (
        day: dayjs.Dayjs,
        activeShow: ACTIVE_SHOW
    ) => {
        try {
            setActiveDaysWithEvent([]);
            const datesWithEvent = await getTeamEventsCheckPeriod(
                day.startOf(activeShow),
                day.endOf(activeShow)
            );
            setActiveDaysWithEvent(datesWithEvent);
        } catch (e) {}
    };

    return (
        <RootStyling hasNavBar>
            <Content>
                <HeaderPage noExtraMargin>
                    <HeaderTitle title={t('COMMUNITY.TITLE')} />
                </HeaderPage>
                <HomeChallenge />
                <Calendar
                    activeDays={activeDaysWithEvent}
                    onActiveDaySwitch={setActiveDay}
                    onActiveShowSwitch={handleActiveShowSwitch}
                />
                <LinkCalendar />
                <div className='list'>
                    <Header className='subheader'>
                        {t('COMMUNITY.EVENTS')}
                    </Header>
                    <ListTeamEvents
                        activeDay={activeDay}
                        onActionClick={navigateToAdd}
                        onStateChanged={setTeamEventState}
                    />
                    {teamEventState === STATES.RESULTS && (
                        <Button
                            className='btn'
                            variant='primary'
                            onClick={navigateToAdd}
                        >
                            +
                        </Button>
                    )}
                </div>
            </Content>
        </RootStyling>
    );
};

export default TeamEventsPage;
