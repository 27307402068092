import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/avatar-edit/avatar';
import Button from 'components/button';
import { ButtonGroup } from 'components/button/styling';
import styled from 'styled-components';
import { dataUrlToFile } from 'utils/files';
import Modal from 'components/modal';

type Props = {
    enabled: boolean;
    onConfirm: (newPicture: any) => void;
    onCancel: () => void;
};

const Content = styled.div``;

const UserProfilePictureModal: React.FC<Props> = ({
    enabled,
    onConfirm,
    onCancel
}: Props) => {
    const { t } = useTranslation();
    const [currentValue, setCurrentValue] = useState('');

    const handleConfirm = async () => {
        if (!currentValue) {
            return;
        }

        try {
            const newFile = await dataUrlToFile(
                currentValue,
                'user-profile.png'
            );
            onConfirm(newFile);
        } catch (e) {}
    };

    const handleCropAvatar = (preview: any) => {
        setCurrentValue(preview);
    };

    const handleCancelAvatar = () => {
        setCurrentValue('');
    };

    return (
        <Modal
            open={enabled}
            title={<h4>{t('MODALS.USER_PROFILE_PICTURE.TITLE')}</h4>}
            content={
                <Content>
                    <Avatar
                        width={200}
                        height={200}
                        cropRadius={100}
                        label={t('MODALS.USER_PROFILE_PICTURE.CHOOSE')}
                        exportAsSquare
                        onCrop={handleCropAvatar}
                        onClose={handleCancelAvatar}
                    />
                </Content>
            }
            footer={
                <ButtonGroup>
                    <Button variant='secondary' onClick={onCancel}>
                        {t('MODALS.USER_PROFILE_PICTURE.CANCEL')}
                    </Button>
                    <Button onClick={handleConfirm}>
                        {t('MODALS.USER_PROFILE_PICTURE.CONFIRM')}
                    </Button>
                </ButtonGroup>
            }
            onCancel={onCancel}
        />
    );
};

export default UserProfilePictureModal;
