import {
    FontAwesomeIcon,
    FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import {
    faPenToSquare,
    faTrashCan,
    faPlus,
    faEllipsisVertical,
    faXmark,
    IconDefinition,
    faMars,
    faVenus,
    faTransgender,
    faUser,
    faPeopleLine,
    faClipboard,
    faClock,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faSquareCheck,
    faUsers,
    faCoins,
    faFlagCheckered,
    faPeopleGroup,
    faPersonCirclePlus,
    faClipboardCheck,
    faClipboardList,
    faList,
    faPlay,
    faQuestionCircle,
    faChevronRight,
    faThumbsUp
} from '@fortawesome/free-solid-svg-icons';

import { faFaceFrownOpen as regularFaFaceFrownOpen } from '@fortawesome/free-regular-svg-icons';

type Props = Omit<FontAwesomeIconProps, 'icon'>;

const getFontAwesomeComponent = (icon: IconDefinition, props: Props) => {
    return <FontAwesomeIcon {...props} icon={icon} />;
};

export const ICONS = {
    CHECK: (props: Props) => getFontAwesomeComponent(faSquareCheck, props),
    EDIT_SQUARE: (props: Props) =>
        getFontAwesomeComponent(faPenToSquare, props),
    TRASH_CAN: (props: Props) => getFontAwesomeComponent(faTrashCan, props),
    PLUS: (props: Props) => getFontAwesomeComponent(faPlus, props),
    DOTS_VERTICAL: (props: Props) =>
        getFontAwesomeComponent(faEllipsisVertical, props),
    CLOSE: (props: Props) => getFontAwesomeComponent(faXmark, props),
    MARS: (props: Props) => getFontAwesomeComponent(faMars, props),
    VENUS: (props: Props) => getFontAwesomeComponent(faVenus, props),
    TRANSGENDER: (props: Props) =>
        getFontAwesomeComponent(faTransgender, props),
    USER: (props: Props) => getFontAwesomeComponent(faUser, props),
    PEOPLE_LINE: (props: Props) => getFontAwesomeComponent(faPeopleLine, props),
    REGULAR_FACE_FROWN_OPEN: (props: Props) =>
        getFontAwesomeComponent(regularFaFaceFrownOpen, props),
    CLIPBOARD: (props: Props) => getFontAwesomeComponent(faClipboard, props),
    CLOCK: (props: Props) => getFontAwesomeComponent(faClock, props),
    ARROW_RIGHT: (props: Props) => getFontAwesomeComponent(faArrowRight, props),
    ARROW_CHEVRON_RIGHT: (props: Props) =>
        getFontAwesomeComponent(faChevronRight, props),
    ARROW_UP: (props: Props) => getFontAwesomeComponent(faArrowUp, props),
    ARROW_DOWN: (props: Props) => getFontAwesomeComponent(faArrowDown, props),
    USERS: (props: Props) => getFontAwesomeComponent(faUsers, props),
    COINS: (props: Props) => getFontAwesomeComponent(faCoins, props),
    FLAG_CHECKERED: (props: Props) =>
        getFontAwesomeComponent(faFlagCheckered, props),
    PEOPLE_GROUP: (props: Props) =>
        getFontAwesomeComponent(faPeopleGroup, props),
    PERSON_CIRCLE_PLUS: (props: Props) =>
        getFontAwesomeComponent(faPersonCirclePlus, props),
    CLIPBOARD_CHECK: (props: Props) =>
        getFontAwesomeComponent(faClipboardCheck, props),
    CLIPBOARD_LIST: (props: Props) =>
        getFontAwesomeComponent(faClipboardList, props),
    LIST: (props: Props) => getFontAwesomeComponent(faList, props),
    PLAYER: (props: Props) => getFontAwesomeComponent(faPlay, props),
    QUESTION: (props: Props) =>
        getFontAwesomeComponent(faQuestionCircle, props),
    THUMBS_UP: (props: Props) => getFontAwesomeComponent(faThumbsUp, props)
};
