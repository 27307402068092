import styled from 'styled-components';

export const Root = styled.div`
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 1fr;
    padding-bottom: 10vh;

    .img {
        margin: auto;
        margin-top: 10vh;
        width: 80%;
        max-width: 500px;

        svg {
            width: 100%;
            height: auto;
        }
    }

    .title {
        margin-top: 32px;
        text-align: center;
    }

    .description {
        margin-top: 32px;
        text-align: center;
        margin-bottom: 80px;
    }

    .steps-btn {
        margin: auto;
        margin-bottom: unset;
        cursor: pointer;
    }
`;
