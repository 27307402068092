import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { updateLocale } from 'utils/dates';

import translationNL from 'translations/nl/main.json';
import achievementsNL from 'translations/nl/achievements.json';
import feedbackNL from 'translations/nl/feedback.json';
import calendarNL from 'translations/nl/calendar.json';
import statisticsNL from 'translations/nl/statistics.json';
import notificationsNL from 'translations/nl/notifications.json';

export enum TRANSLATION_NAMESPACES {
    MAIN = 'translation',
    ACHIEVEMENTS = 'achievements',
    FEEDBACK = 'feedback',
    CALENDAR = 'calendar',
    STATISTICS = 'statistics',
    NOTIFICATION = 'notification'
}

// the translations
const resources = {
    nl: {
        [TRANSLATION_NAMESPACES.MAIN]: translationNL,
        [TRANSLATION_NAMESPACES.ACHIEVEMENTS]: achievementsNL,
        [TRANSLATION_NAMESPACES.FEEDBACK]: feedbackNL,
        [TRANSLATION_NAMESPACES.CALENDAR]: calendarNL,
        [TRANSLATION_NAMESPACES.STATISTICS]: statisticsNL,
        [TRANSLATION_NAMESPACES.NOTIFICATION]: notificationsNL
    }
};

i18n.on('initialized', (options: InitOptions) => {
    switch (options.lng) {
        case 'nl': {
            updateLocale('nl');
        }
    }
});

i18n.on('languageChanged', (language: string) => {
    console.log(language);
});

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'nl',
        fallbackLng: 'nl',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
