import { useState } from 'react';
import { getRandomUUID } from 'utils/get';

export const UseModelState = <T>(model: T) => {
    const [state, setState] = useState<{ model: T; id: string }>({
        model,
        id: getRandomUUID()
    });

    const handleStateChange = () => {
        setState((prev: any) => ({ ...prev, id: getRandomUUID() }));
    };

    return { state, handleStateChange };
};
