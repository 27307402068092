import React from 'react';
import styled from 'styled-components';
import { Description, Title } from 'components/text';
import { Box } from 'components/box/styling';

type ItemType = {
    id: string;
    title: string;
    description: string;
    img: string;
};

type Props = {
    items: ItemType[];
};

const Root = styled.div`
    display: flex;
    gap: 16px;
    overflow: auto;
`;

const Item = styled(Box)`
    min-width: 300px;

    .action_title {
    }

    .action_description {
        margin: 16px 0;
    }

    img {
        width: 100%;
        max-height: 240px;
        object-fit: cover;
        border-radius: 8px;
    }
`;

const TrajectHorizontalList: React.FC<Props> = ({ items }: Props) => {
    return (
        <Root>
            {items.map((item: ItemType) => (
                <Item key={item.id}>
                    <Title className='action_title'>{item.title}</Title>
                    <Description className='action_description'>
                        {item.description}
                    </Description>
                    <img src={item.img} alt={item.title} />
                </Item>
            ))}
        </Root>
    );
};

export default TrajectHorizontalList;
