import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { Root, MonthRow, Row, WeekDay } from 'components/calendar/styling';
import { TRANSLATION_NAMESPACES } from 'translations';

type RenderItem = {
    day: dayjs.Dayjs | undefined;
    index: number;
    activeDay: dayjs.Dayjs;
    todayDay: dayjs.Dayjs;
    onClick: (day: dayjs.Dayjs | undefined) => void;
};

type Props = {
    activeShow: ACTIVE_SHOW;
    renderItem: (renderItem: RenderItem) => React.ReactNode;
    onActiveDaySwitch?: (activeDay: dayjs.Dayjs) => void;
    onActiveShowSwitch?: (day: dayjs.Dayjs, activeShow: ACTIVE_SHOW) => void;
};

export enum ACTIVE_SHOW {
    WEEK = 'week',
    MONTH = 'month'
}

const RootCalendar: React.FC<Props> = ({
    activeShow = ACTIVE_SHOW.MONTH,
    renderItem,
    onActiveDaySwitch = () => null,
    onActiveShowSwitch = () => null
}) => {
    const { t } = useTranslation(TRANSLATION_NAMESPACES.CALENDAR);

    const [beginOfActiveShow, setBeginOfActiveShow] = useState<dayjs.Dayjs>(
        dayjs().startOf(activeShow)
    );
    const [calendarData, setCalendarData] = useState<dayjs.Dayjs[]>([]);
    const [activeDay, setActiveDay] = useState<dayjs.Dayjs>(dayjs());
    const [todayDay] = useState<dayjs.Dayjs>(dayjs());

    const getDays = (cData: dayjs.Dayjs[]): (dayjs.Dayjs | undefined)[] => {
        return getDaysInActiveShow(cData);
    };

    const getDaysInActiveShow = (
        cData: dayjs.Dayjs[]
    ): (dayjs.Dayjs | undefined)[] => {
        const output = [];

        let beginOfActiveShowDay = beginOfActiveShow.get('day') - 1;

        if (beginOfActiveShowDay === -1) {
            beginOfActiveShowDay = 6;
        }

        for (let i = 0; i < beginOfActiveShowDay; i++) {
            output.push(undefined);
        }

        const dataLength = cData.length;

        for (let i = 0; i < dataLength; i++) {
            output.push(cData[i]);
        }

        return output;
    };

    const handleClickDay = (day: dayjs.Dayjs | undefined) => {
        if (!day) {
            return;
        }

        setActiveDay(day);
        onActiveDaySwitch(day);
    };

    const handleArrowClick = (amountToUpdate: number) => {
        setBeginOfActiveShow((prev) => prev.add(amountToUpdate, activeShow));
        setActiveDay((prev) => prev.add(amountToUpdate, activeShow));
    };

    useEffect(() => {
        const amountOfDaysInActiveSwitch =
            activeShow === ACTIVE_SHOW.WEEK ? 7 : activeDay.daysInMonth();
        const output = [];

        for (let i = 0; i < amountOfDaysInActiveSwitch; i++) {
            output.push(beginOfActiveShow.add(i, 'days'));
        }

        setCalendarData(output);
        onActiveShowSwitch(beginOfActiveShow, activeShow);
    }, [beginOfActiveShow]);

    useEffect(() => {
        setBeginOfActiveShow(dayjs().startOf(activeShow));
    }, [activeShow]);

    return (
        <Root className='calendar'>
            <div className='calendar-container'>
                <MonthRow>
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        className='arrow'
                        onClick={() => handleArrowClick(-1)}
                    />
                    <h3 className='month'>
                        <span className='name'>
                            {t(`MONTHS.${beginOfActiveShow.get('month')}`)}
                        </span>
                        <span className='year'>
                            '{beginOfActiveShow.format('YY')}
                        </span>
                    </h3>

                    <FontAwesomeIcon
                        icon={faArrowRight}
                        className='arrow'
                        onClick={() => handleArrowClick(1)}
                    />
                </MonthRow>

                <Row>
                    <WeekDay>{t('DAYS.MONDAY.SHORT')}</WeekDay>
                    <WeekDay>{t('DAYS.TUESDAY.SHORT')}</WeekDay>
                    <WeekDay>{t('DAYS.WEDNESDAY.SHORT')}</WeekDay>
                    <WeekDay>{t('DAYS.THURSDAY.SHORT')}</WeekDay>
                    <WeekDay>{t('DAYS.FRIDAY.SHORT')}</WeekDay>
                    <WeekDay>{t('DAYS.SATURDAY.SHORT')}</WeekDay>
                    <WeekDay>{t('DAYS.SUNDAY.SHORT')}</WeekDay>
                </Row>

                <Row>
                    {getDays(calendarData).map(
                        (day: dayjs.Dayjs | undefined, index: number) => {
                            return renderItem({
                                day,
                                index,
                                activeDay,
                                todayDay,
                                onClick: handleClickDay
                            });
                        }
                    )}
                </Row>
            </div>
        </Root>
    );
};

export default RootCalendar;
