import React, { useEffect, useRef, useState } from 'react';
import { TextField as MuiTextField } from '@mui/material';
import BooleanField from 'components/text-field/boolean';

type Props = {
    type?: 'text' | 'number' | 'boolean' | any;
    value: string | number | any;
    label: string;
    placeholder?: string;
    onChange: (value: any) => void;
    errorText?: string;
    minValue?: number;
    sx?: any;
    className?: string;
    autoFocus?: boolean;
};
const InputField: React.FC<Props> = ({
    type = 'text',
    value,
    label,
    placeholder,
    onChange,
    errorText,
    minValue = 0,
    sx = '',
    className,
    autoFocus = false
}) => {
    const inputRef = useRef();
    const [isShrinked, setIsShrinked] = useState(false);

    const shouldShrink = () => {
        if (isValueOrZero(value)) {
            return setIsShrinked(true);
        }
        setIsShrinked(false);
    };

    const isValueOrZero = (value: number | string) => {
        if (value || value === 0) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        shouldShrink();
    }, [value]);

    useEffect(() => {
        if (autoFocus && inputRef && inputRef.current) {
            setTimeout(() => {
                (inputRef.current as any).focus();
            }, 100);
        }
    }, [autoFocus]);

    if (type === 'boolean') {
        return (
            <BooleanField
                value={value}
                label={label}
                className={className}
                onChange={onChange}
            />
        );
    }

    return (
        <MuiTextField
            inputRef={inputRef}
            type={type}
            value={value?.toString()}
            label={label}
            placeholder={placeholder}
            InputProps={{ inputProps: { min: minValue } }}
            onChange={(event) => onChange(event.target.value)}
            onFocus={() => setIsShrinked(true)}
            onBlur={() => (isValueOrZero(value) ? null : setIsShrinked(false))}
            variant='outlined'
            // This prevents the label from overlapping the value
            InputLabelProps={{ shrink: isShrinked }}
            error={Boolean(errorText)}
            helperText={errorText}
            sx={sx}
            className={className}
        />
    );
};

export default InputField;
