import React, { useState } from 'react';
import styled from 'styled-components';
import { Answer } from 'pages/assessment/inputs/styling';
import { Question, QuestionScore } from 'models/question';

const StyledScaleInput = styled.div<{ amountOfScores: number }>`
    display: grid;
    gap: 8px;
    justify-content: center;
    grid-template-columns: repeat(
        ${({ amountOfScores }) => Math.ceil(amountOfScores / 2)},
        56px
    );
`;

type Props = {
    question: Question;
    onChange: (score: number) => void;
};

const RangeInput: React.FC<Props> = ({ question, onChange }: Props) => {
    const [active, setActive] = useState<string>('');

    const numberClick = (questionScore: QuestionScore) => {
        setActive(questionScore.value);
        makeAnimation(questionScore.value);
        onChange(questionScore.score);
    };

    const makeAnimation = (value: string) => {
        setTimeout(() => {
            setActive('');
            setTimeout(() => {
                setActive(value);
            }, 100);
        }, 100);
    };

    return (
        <StyledScaleInput amountOfScores={question.scores.length}>
            {question.scores.map((qs: QuestionScore) => (
                <Answer
                    key={qs.value}
                    isActive={active === qs.value}
                    onClick={() => numberClick(qs)}
                >
                    {qs.value}
                </Answer>
            ))}
        </StyledScaleInput>
    );
};

export default RangeInput;
