import Button from 'components/button';
import Modal from 'components/modal';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean;
    title: string;
    content: React.ReactNode;
    handleClose: () => void;
    handleDelete: () => void;
};

const StyledFooter = styled.div`
    .cancel-btn {
        margin-right: 8px;
    }
`;

const DeleteModal: React.FC<Props> = ({
    open,
    title,
    content,
    handleClose,
    handleDelete
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            title={title}
            content={content}
            footer={
                <StyledFooter>
                    <Button
                        className='cancel-btn'
                        onClick={handleClose}
                        variant='secondary'
                    >
                        {t('MODALS.DELETE.CANCEL')}
                    </Button>
                    <Button onClick={handleDelete} variant='primary'>
                        {t('MODALS.DELETE.CONFIRM')}
                    </Button>
                </StyledFooter>
            }
        >
            {content}
        </Modal>
    );
};

export default DeleteModal;
