import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Title } from 'components/text';
import assets from 'assets';
import { Box } from 'components/box/styling';
import Button from 'components/button';
import { ICONS } from 'assets/icons';

export enum EmptyStateKey {
    NO_LEVEL = 'NO_LEVEL',
    NO_CURRENT_GOALS = 'NO_CURRENT_GOALS',
    NO_HISTORY_GOALS = 'NO_HISTORY_GOALS',
    NO_TEAM_EVENTS = 'NO_TEAM_EVENTS',
    NO_TEAM_EVENT_DETAIL = 'NO_TEAM_EVENT_DETAIL',
    NO_GOAL_DETAIL = 'NO_GOAL_DETAIL',
    NO_ASSESSMENT = 'NO_ASSESSMENT',
    NO_GOAL_SELECT = 'NO_GOAL_SELECT',
    NO_GOAL_ADD = 'NO_GOAL_ADD',
    NO_TRAJECT_CURRENT = 'NO_TRAJECT_CURRENT',
    NO_STATISTICS = 'NO_STATISTICS',
    NO_HOW_U_DOING = 'NO_HOW_U_DOING'
}

type Props = {
    keyState: EmptyStateKey;
    enable: boolean;
    onActionClick?: (event: React.BaseSyntheticEvent) => void;
};

const Root = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 40px 16px;
    text-align: center;
    width: 100%;

    .asset {
        width: 80px;
        height: auto;

        > * {
            width: 100%;
            height: 100%;
        }
    }

    .title {
        padding: 24px 0;
    }

    .description {
        font-size: 14px;
    }

    .action {
        margin-top: 48px;
        border: solid 1px;
    }
`;

const getAsset = (key: EmptyStateKey): React.ReactNode => {
    switch (key) {
        case EmptyStateKey.NO_CURRENT_GOALS:
        case EmptyStateKey.NO_HISTORY_GOALS:
        case EmptyStateKey.NO_GOAL_SELECT:
            return <assets.EMPTY_GOALS_ICON />;
        case EmptyStateKey.NO_TEAM_EVENTS:
            return <assets.EMPTY_GOALS_ICON />;
        case EmptyStateKey.NO_TEAM_EVENT_DETAIL:
            return ICONS.PEOPLE_LINE;
        default:
            return null;
    }
};

const EmptyState: React.FC<Props> = ({
    keyState,
    enable,
    onActionClick
}: Props) => {
    const { t } = useTranslation();

    const handleActionClick = (event: React.BaseSyntheticEvent) => {
        if (onActionClick) {
            onActionClick(event);
        }
    };

    if (!enable) {
        return null;
    }
    return (
        <Root>
            <div className='asset'>{getAsset(keyState)}</div>
            <Title className='title'>{t(`EMPTY.${keyState}.TITLE`)}</Title>
            <p className='description'>{t(`EMPTY.${keyState}.DESC`)}</p>
            {Boolean(onActionClick) && (
                <Button
                    className='action'
                    variant='primaryadd'
                    onClick={handleActionClick}
                >
                    {t(`EMPTY.${keyState}.ACTION`)}
                </Button>
            )}
        </Root>
    );
};

export default EmptyState;
