import { get, post } from 'api';
import { Assessment, UserOnBoardingAssessment } from 'models/assessment';
import { CMSGoal } from 'models/goals';

export async function createAssessment(
    isOnBoarding: boolean = false
): Promise<Assessment> {
    const response = await post(`/api/v1/assessments`);
    return isOnBoarding
        ? new UserOnBoardingAssessment(response.data, true)
        : new Assessment(response.data);
}

export async function completeQuestion(
    score: any,
    questionId: string,
    assessmentId: string
): Promise<any[]> {
    const response = await post(
        `/api/v1/assessments/answer/${assessmentId}/${questionId}`,
        { score }
    );
    return response.data;
}

export async function getGoalsBasedOnAssessment(
    assessmentId: string
): Promise<CMSGoal[]> {
    const response = await get(`/api/v1/assessments/${assessmentId}/goals`);
    return response.data.map((goal: any) => new CMSGoal(goal));
}
