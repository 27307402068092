import styled from 'styled-components';
import theme from 'utils/theme';
import assets from 'assets';
import { Box } from 'components/box/styling';

export const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-image: url(${assets.TRAJECT_BG});
    background-repeat: no-repeat;
    background-size: cover;
    color: ${theme.colors.white};
    border-radius: 20px;
    padding: ${theme.space.page};
    gap: 24px;
    padding-top: 64px;
    margin-bottom: 32px;
    cursor: pointer;

    .traject__footer {
        display: grid;
        grid-template-areas: 'title action' 'amount-of-actions action';
        grid-column-gap: 32px;
        grid-row-gap: 8px;
        background: ${theme.colors.trajectPrimary};
        margin: 0 -24px -24px;
        padding: 24px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        .title {
            grid-area: title;
        }

        .amount-of-actions {
            grid-area: amount-of-actions;
        }

        .action {
            grid-area: action;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .text {
                text-align: right;
            }

            .icon {
                font-size: 12px;
                margin-left: 4px;
            }
        }
    }
`;

export const EmptyRoot = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 32px;

    .button {
        margin: auto;
    }
`;

export const DayIndicatorContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 40px;

    .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-75%, -60%);
        font-weight: 600;
        font-size: 20px;
    }

    .group {
        position: absolute;
        top: -32px;
        left: 0;
        width: 100%;
    }
`;
