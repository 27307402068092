import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MoodBox as StyledMoodBox,
    SubmittedContainer
} from 'pages/home/mood-box/styling';
import { createFeeling } from 'api/user';
import Form from 'components/form';
import Input from 'components/text-field';
import { ButtonGroup } from 'components/button/styling';
import Button from 'components/button';
import FeedbackEmoji from 'components/feedback/emojiList';
import { FEEDBACK_EMOJI_TYPE } from 'utils/enums';
import SeeAllHeader from 'pages/home/see-all-header';
import { ROUTES } from 'routes/list';
import { Title, Description } from 'components/text';

type Props = {};

const MoodBox: React.FC<Props> = () => {
    const { t } = useTranslation();
    const [activeMood, setActiveMood] = useState<FEEDBACK_EMOJI_TYPE | null>(
        null
    );
    const [description, setDescription] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const handleToggle = (mood: FEEDBACK_EMOJI_TYPE) => {
        setActiveMood(mood);
    };

    const handleSubmitFeeling = async () => {
        if (!activeMood) {
            return;
        }
        try {
            await createFeeling(activeMood!, description);
            setHasSubmitted(true);
        } catch (e) {
            console.log(e);
        }
    };

    const handleCancelSubmit = () => {
        setDescription('');
        setActiveMood(null);
    };

    return (
        <div className='mood-box'>
            <SeeAllHeader
                title={t('HOME.MOOD.HEADER')}
                navPath={ROUTES.HOW_U_DOING.path()}
            />

            {!hasSubmitted && (
                <StyledMoodBox>
                    <h3>{t('HOME.MOOD.TITLE')}</h3>
                    <div className='mood-selection-container'>
                        <FeedbackEmoji
                            activeValue={activeMood}
                            onSelect={handleToggle}
                        />
                    </div>
                    {activeMood && (
                        <Form
                            className='submit-container'
                            onSubmit={handleSubmitFeeling}
                        >
                            <Input
                                type='text'
                                value={description}
                                label={t('HOME.MOOD.DESCRIPTION')}
                                onChange={setDescription}
                            />

                            <ButtonGroup className='submit-buttons'>
                                <Button
                                    type='button'
                                    variant='secondary'
                                    onClick={handleCancelSubmit}
                                >
                                    {t('HOME.MOOD.CANCEL')}
                                </Button>
                                <Button type='submit' variant='primary'>
                                    {t('HOME.MOOD.SUBMIT')}
                                </Button>
                            </ButtonGroup>
                        </Form>
                    )}
                </StyledMoodBox>
            )}

            {hasSubmitted && (
                <SubmittedContainer>
                    <Title className='title'>
                        {t('HOME.MOOD.SUBMITTED.TITLE')}
                    </Title>
                    <Description className='description'>
                        {t('HOME.MOOD.SUBMITTED.DESCRIPTION')}
                    </Description>
                </SubmittedContainer>
            )}
        </div>
    );
};

export default MoodBox;
