import React from 'react';
import Dialog from '@mui/material/Dialog';
import { ICONS } from 'assets/icons';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton
} from '@mui/material';

type Props = {
    open: boolean;
    title: string | React.ReactNode;
    content: React.ReactNode;
    footer: React.ReactNode;
    onCancel?: () => void;
};
const Modal: React.FC<Props> = ({
    open = false,
    title,
    content,
    footer,
    onCancel
}) => {
    return (
        <Dialog open={open}>
            <DialogTitle>
                {title}
                {onCancel && (
                    // TODO: make custom icon button
                    <IconButton
                        aria-label='close'
                        onClick={onCancel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <ICONS.CLOSE />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent dividers>{content}</DialogContent>
            <DialogActions sx={{ gap: '8px' }}>{footer}</DialogActions>
        </Dialog>
    );
};

export default Modal;
