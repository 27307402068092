import React from 'react';
import Button from 'components/button';
import { Header } from 'components/text';
import { ICONS } from 'assets/icons';
import assets from 'assets';
import { useTranslation } from 'react-i18next';
import { ScreenRoot } from 'pages/assessment/styling';

type Props = {
    onStartAssessment: () => void;
};

const StartScreen: React.FC<Props> = ({ onStartAssessment }) => {
    const { t } = useTranslation();
    return (
        <ScreenRoot>
            <div className='root-container'>
                <div className='intro-container'>
                    <img
                        className='logo'
                        src={assets.LOGO_WHITE_BLUEBG}
                        alt='Coach Me Logo'
                    />
                    <div className='text-container'>
                        <Header className='header'>
                            {t('ASSESSMENT.START_SCREEN.TITLE')}
                        </Header>
                        <p className='subheader'>
                            {t('ASSESSMENT.START_SCREEN.SUB_TITLE_1')}
                        </p>
                        <p className='subheader'>
                            {t('ASSESSMENT.START_SCREEN.SUB_TITLE_2')}
                        </p>
                    </div>
                </div>
                <div className='cta-container'>
                    <div className='estimation'>
                        <ICONS.CLOCK className='clock' />
                        <p> {t('ASSESSMENT.START_SCREEN.ESTIMATION')}</p>
                    </div>
                    <Button className='cta-btn' onClick={onStartAssessment}>
                        {t('ASSESSMENT.START_SCREEN.START')}
                    </Button>
                </div>
            </div>
        </ScreenRoot>
    );
};

export default StartScreen;
