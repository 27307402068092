import styled from 'styled-components';
import { ButtonVariantType } from 'components/button/types';
import theme from 'utils/theme';

const getColor = (variant: ButtonVariantType) => {
    switch (variant) {
        case 'primary':
        case 'delete':
            return theme.colors.white;
        case 'primaryadd':
            return '#38C976';
        case 'primarytext':
            return '#3173FA';
        case 'secondary':
            return theme.colors.primary;
    }
};

const getBackgroundColor = (variant: ButtonVariantType) => {
    switch (variant) {
        case 'primary':
            return theme.colors.primary;
        case 'primaryadd':
        case 'primarytext':
            return '#fff';
        case 'delete':
            return theme.colors.error;
        case 'secondary':
            return 'transparent';
    }
};

const getOpacity = (variant: ButtonVariantType) => {
    return variant.includes('disabled') ? 0.4 : 1;
};

const getBorder = (variant: ButtonVariantType) => {
    switch (variant) {
        case 'secondary':
            return `solid 1px ${theme.colors.primary}`;

        default:
            return 'none';
    }
};

type ButtonVariantProps = {
    variant: ButtonVariantType;
    btnDisabled: boolean;
};

const ButtonStyle = styled.button<ButtonVariantProps>`
    cursor: pointer;
    color: ${({ variant }) => getColor(variant)};
    background-color: ${({ variant }) => getBackgroundColor(variant)};
    opacity: ${({ variant }) => getOpacity(variant)};
    border: ${({ variant }) => getBorder(variant)};
    padding: 8px 24px;
    border-radius: 8px;
    font-size: 1rem;
    outline: none;
    min-height: ${theme.space.minHeightFormFields};
    white-space: nowrap;
    text-align: center;

    ${({ btnDisabled }) =>
        btnDisabled &&
        `
        pointer-events: none;
        opacity: 0.4;
    `}
`;

export const ButtonGroup = styled.div`
    display: flex;
    grid-gap: 8px;
`;

export default ButtonStyle;
