import { PageRoot } from 'pages';
import styled from 'styled-components';

export const StyledStatisticPage = styled(PageRoot)`
    .statistic-number {
        font-size: 24px;
        font-weight: 900;
        color: #000;
    }

    .parent-statistic-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        align-items: center;
        justify-items: center;
        gap: 16px;
        margin: 16px 0;
    }
`;

type StyledProps = {
    iconColor: string;
};

export const StyledStatisticContainer = styled.div<StyledProps>`
    background-color: #fff;
    padding: 32px 24px;
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;
    height: 100%;
    border-radius: 10px;

    .icon-container {
        width: 50px;
        height: 50px;
        padding: 15px;
        background-color: ${(props: StyledProps) => props.iconColor};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .statistic-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;
export const StyledHowYouDoingStatisticContainer = styled.div`
    background-color: #fff;
    padding: 32px 24px;
    padding-bottom: 0;
    width: 100%;
    border-radius: 10px;

    @media (min-width: 646px) {
        grid-column: 1 / 3;
    }
    .how-you-doing--total {
        display: flex;
        column-gap: 16px;
        align-items: center;
    }

    .how-you-doing-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .how-you-doing--grid-container {
        display: grid;
        width: 100%;
        justify-items: center;
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
        row-gap: 32px;
        padding: 40px 5px;
    }

    .how-you-doing--item-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
`;
