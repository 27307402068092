import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Root,
    PictureContainer,
    LevelStyled,
    AchievementStyled
} from 'pages/userProfile/styling';
import assets from 'assets';
import { Level } from 'models/level';
import { getUserLevel } from 'api/level';
import Levels from 'pages/userProfile/levels';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/list';
import { getMe } from 'api/user';
import { User } from 'models/user';
import Loader from 'components/states/loader';
import Achievements from 'pages/userProfile/achievements';
import Header, { HeaderTitle } from 'components/header';
import Button from 'components/button';
import { logout } from 'api/auth';
import { useHasAccess } from 'hooks/useHasAccess';
import { USER_ROLES } from 'utils/enums';

const UserProfilePage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isHr = useHasAccess(USER_ROLES.HR);

    const [user, setUser] = useState<User>();
    const [level, setLevel] = useState<Level>();

    const fetchUser = async () => {
        try {
            const response = await getMe();
            setUser(response);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchUserLevel = async () => {
        try {
            const response = await getUserLevel();
            setLevel(response);
        } catch (e) {
            console.log(e);
        }
    };

    const handleEditProfile = () => {
        navigate(ROUTES.USER_PROFILE_EDIT.path());
    };

    const handleLogout = () => {
        try {
            logout();
        } catch (e) {
        } finally {
            navigate(ROUTES.LOGIN.path());
        }
    };

    const handleStatistics = () => {
        navigate(ROUTES.STATISTICS.path());
    };

    useEffect(() => {
        fetchUser();
        fetchUserLevel();
    }, []);

    if (!user) {
        return (
            <Root hasNavBar>
                <Loader enable />
            </Root>
        );
    }

    return (
        <Root hasNavBar>
            <Header>
                <HeaderTitle title={t('USER_PROFILE.TITLE')} />
            </Header>

            <PictureContainer>
                <img
                    className='picture'
                    src={user.picture}
                    alt={user.displayName}
                />
                <assets.USER_PROFILE_EDIT_ICON
                    className='edit_icon'
                    onClick={handleEditProfile}
                />
            </PictureContainer>

            <h2 className='name'>{user.displayName}</h2>

            <LevelStyled>
                <h5 className='title title__level'>
                    {t('USER_PROFILE.LEVEL')}
                </h5>
                <div className='points-container'>
                    <assets.POINTS_ICON />
                    <span className='points'>{level?.points}</span>
                </div>
                <div className='levels'>
                    {Boolean(level) && <Levels level={level!} />}
                </div>
            </LevelStyled>

            <AchievementStyled>
                <h5 className='title title__achievements'>Achievements</h5>
                <div className='achievements-container'>
                    <Achievements />
                </div>
            </AchievementStyled>

            {isHr && (
                <Button
                    className='statistics-btn'
                    variant='primary'
                    onClick={handleStatistics}
                >
                    {t('USER_PROFILE.STATISTICS')}
                </Button>
            )}

            <Button variant='primaryadd' onClick={handleLogout}>
                {t('USER_PROFILE.LOGOUT')}
            </Button>

            <p className='version'>
                Version: {process.env.REACT_APP_BUILD_TIME}
            </p>
        </Root>
    );
};

export default UserProfilePage;
