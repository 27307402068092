import React from 'react';
import styled from 'styled-components';
import { Header } from 'components/text';
import BackButton from 'components/button/back';

type PageHeaderProps = {
    removeBackButton: Boolean;
};

export const StyledPageHeader = styled.div<PageHeaderProps>`
    position: relative;
    margin-bottom: 48px;
    padding-left: 70px;
    display: flex;
    align-items: center;

    .back-button {
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translate(-50%, -50%);
    }

    ${({ removeBackButton }) =>
        removeBackButton &&
        `
        .back-button {
            display: none;
        }
        .title: {
            margin: 0;
        }
        `}
`;

type Props = {
    title: string;
    removeBackButton?: boolean;
    className?: string;
};

const PageHeader: React.FC<Props> = ({
    title,
    removeBackButton = false,
    className = ''
}) => {
    return (
        <StyledPageHeader
            className={className}
            removeBackButton={removeBackButton}
        >
            <BackButton className='back-button' />
            <Header className='title'>{title}</Header>
        </StyledPageHeader>
    );
};

export default PageHeader;
