import React from 'react';
import { createAssessment } from 'api/assessments';
import { Assessment } from 'models/assessment';
import SingleApiRender from 'components/api-render/single';
import { EmptyStateKey } from 'components/states/empty';
import RenderAssessment from 'pages/assessment/renderAssessment';
import { PageRoot } from 'pages';

type Props = {
    isOnBoarding?: boolean;
};

const AssessmentPage: React.FC<Props> = ({ isOnBoarding = false }: Props) => {
    return (
        <SingleApiRender
            keyState={EmptyStateKey.NO_ASSESSMENT}
            promiseCall={createAssessment}
            promiseParams={isOnBoarding}
            renderItem={(assessment: Assessment) => {
                const Root = isOnBoarding ? React.Fragment : PageRoot;

                return (
                    <Root>
                        <RenderAssessment
                            assessmentObject={assessment}
                            isOnBoarding={isOnBoarding}
                        />
                    </Root>
                );
            }}
        />
    );
};

export default AssessmentPage;
