import styled from 'styled-components';
import { PageRoot } from 'pages/index';
import theme from 'utils/theme';
import { Box } from 'components/box/styling';

const pictureSize = '200px';

export const Root = styled(PageRoot)`
    .name {
        text-align: center;
        margin: 32px 0;
    }

    .statistics-btn {
        margin-bottom: 32px;
    }

    .version {
        margin-top: 40px;
        opacity: 0.1;
        text-align: center;
    }
`;

export const PictureContainer = styled.div`
    position: relative;
    width: ${pictureSize};
    height: ${pictureSize};
    margin: 0 auto;

    .picture {
        border-radius: 100%;
        border: solid 2px ${theme.colors.background};
        width: ${pictureSize};
        height: ${pictureSize};
        background-color: ${theme.colors.background};
    }

    .edit_icon {
        position: absolute;
        width: 40px;
        height: 40px;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
    }
`;

export const LevelStyled = styled(Box)`
    background-color: ${theme.colors.white};
    border-radius: 20px;
    padding: 24px;
    margin-bottom: 32px;
    display: grid;
    grid-template-areas:
        'title-levels points'
        'levels levels';

    grid-row-gap: 24px;

    .title {
        font-size: 20px;

        &__levels {
            grid-area: title-levels;
        }
    }

    .points-container {
        grid-area: points;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    .points {
        color: ${theme.colors.primary};
        font-weight: 500;
        margin-left: 8px;
    }

    .levels {
        grid-area: levels;
    }
`;

export const AchievementStyled = styled(Box)`
    background-color: ${theme.colors.white};
    border-radius: 20px;
    padding: 24px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 24px;

    .title {
        font-size: 20px;

        &__achievements {
            position: relative;
            grid-area: title-achievements;
        }
    }

    .achievements-container {
        grid-area: achievements;
        max-width: 600px;
        width: 100%;
        margin: auto;
    }
`;
