import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import assets from 'assets';
import { Store } from 'store';
import { StyledHeader } from 'pages/home/header/styling';
import { IntercomCustomButton } from 'components/intercom';

type Props = {
    isMobile?: boolean;
};

const Header: React.FC<Props> = ({ isMobile = false }: Props) => {
    const { t } = useTranslation();
    const user = useSelector((store: Store) => store.user);

    return (
        <StyledHeader className='header' isMobile={isMobile}>
            <div className='header-content'>
                <div className='greeting'>
                    <h3>{`${t('HOME.HEADER.GREETING')}, ${user.firstName}`}</h3>
                    <img src={assets.WAVING_HAND_ICON} alt='' />
                </div>
                <p>{t('HOME.HEADER.WELCOME_TEXT')}</p>
                <IntercomCustomButton />
            </div>
        </StyledHeader>
    );
};

export default Header;
