import React from 'react';
import TooltipMUI from '@mui/material/Tooltip';

type Props = {
    text: string;
    children: React.ReactElement;
};

const Tooltip: React.FC<Props> = ({ text, children }: Props) => {
    return <TooltipMUI title={text}>{children}</TooltipMUI>;
};

export default Tooltip;
