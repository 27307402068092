import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { subscribeToNotifications } from 'notifications';
import * as serviceWorkerRegistration from 'pwa/serviceWorkerRegistration';
import Button from 'components/button';
import theme from 'utils/theme';
import { useTranslation } from 'react-i18next';

// @formatter:off
const ReloadIndication = keyframes`
  0, 100% {
    box-shadow: 0 0 20px 1px ${theme.colors.primary10};
  }
  50% {
    box-shadow: 0 0 20px 10px ${theme.colors.primary10};
  }
`;
// @formatter:on

const Root = styled.div`
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: ${theme.zIndex.pwaReload};
    width: 80%;
    max-width: 400px;
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    display: grid;
    grid-template-areas: 'title submit' 'time submit';
    grid-column-gap: 24px;
    grid-row-gap: 8px;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 20px 1px ${theme.colors.primary10};
    animation: ${ReloadIndication} 4s linear infinite;

    .title {
        grid-area: title;
    }

    .time {
        grid-area: time;
    }

    .submit {
        grid-area: submit;
        min-height: unset;
        color: ${theme.colors.white};
        border-color: ${theme.colors.white};
        height: min-content;
        margin: auto;
    }
`;

let timerInterval: any = -1;

const PWAContainer: React.FC = () => {
    const [mustUpdate, setMustUpdate] = useState(false);
    const [timer, setTimer] = useState(10);
    const { t } = useTranslation();

    const handleSuccessPWA = (event: ServiceWorkerRegistration) => {
        try {
            console.log('PWA SUCCESS', event);
            subscribeToNotifications(event.pushManager);
        } catch (e) {}
    };

    const handleUpdatePWA = async (event: ServiceWorkerRegistration) => {
        try {
            console.log('PWA UPDATE', event);
            setMustUpdate(true);
        } catch (e) {}
    };

    const handleStartPWA = (event: ServiceWorkerRegistration) => {
        try {
            console.log('PWA START', event);
            subscribeToNotifications(event.pushManager);
        } catch (e) {}
    };

    const handleSubmitUpdatePWA = () => {
        setMustUpdate(false);
        window.location.reload();
    };

    useEffect(() => {
        if (!mustUpdate) {
            return;
        }

        clearInterval(timerInterval);
        timerInterval = setInterval(() => {
            setTimer((prev: number) => prev - 1);
        }, 1000);
    }, [mustUpdate]);

    useEffect(() => {
        if (timer === 0) {
            handleSubmitUpdatePWA();
            clearInterval();
        }
    }, [timer]);

    useEffect(() => {
        serviceWorkerRegistration.register({
            onSuccess: handleSuccessPWA,
            onUpdate: handleUpdatePWA,
            onStart: handleStartPWA
        });
    }, []);

    if (mustUpdate) {
        return (
            <Root>
                <p className='title'>{t('PWA_RELOAD.TITLE')}</p>
                <p className='time'>
                    {t('PWA_RELOAD.AUTO_IN', { time: timer })}
                </p>
                <Button
                    className='submit'
                    variant='secondary'
                    onClick={handleSubmitUpdatePWA}
                >
                    {t('PWA_RELOAD.SUBMIT')}
                </Button>
            </Root>
        );
    }

    return null;
};

export default PWAContainer;
