import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import InfoOnBoarding from 'pages/onboarding/info';
import assets from 'assets';
import AssessmentPage from 'pages/assessment';
import { Root } from 'pages/onboarding/styling';

type CurrentStepType = number | null | undefined;

const OnBoardingPage: React.FC = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState<CurrentStepType>();

    const goNext = () => {
        setCurrentStep((prev: CurrentStepType) => {
            if (!prev) {
                return 1;
            }
            return prev + 1;
        });
    };

    const renderStep = (step: CurrentStepType) => {
        if (!step) {
            return 'NOOP';
        }

        switch (step) {
            case 1:
            case 2:
            case 3:
                return (
                    <InfoOnBoarding
                        imgElement={assets[`ONBOARDING_STEP_${step}_ICON`]}
                        title={t(`ON-BOARDING.STEP_${step}.TITLE`)}
                        description={t(`ON-BOARDING.STEP_${step}.DESCRIPTION`)}
                        step={step}
                        amountOfSteps={3}
                        onNext={goNext}
                    />
                );
            case 4:
                return <AssessmentPage isOnBoarding />;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (!currentStep) {
            return;
        }
        setSearchParams(
            createSearchParams({
                step: currentStep.toString()
            })
        );
    }, [currentStep]);

    useEffect(() => {
        try {
            const step = searchParams.get('step');
            if (!step || !Number.isInteger(+step) || +step > 4) {
                throw new Error('No step in the url');
            }
            setCurrentStep(+step);
        } catch (e) {
            setCurrentStep(1);
        }
    }, [searchParams]);

    return <Root>{renderStep(currentStep)}</Root>;
};

export default OnBoardingPage;
