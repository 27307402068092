import React, { BaseSyntheticEvent } from 'react';
import { InputTypes } from 'components/input/types';
import { InputRootStyling } from 'components/input/styling';
import FormError from 'components/form/formError';

type Props = {
    name?: string;
    type: InputTypes;
    value: any;
    label?: string;
    error?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    onChange: (newValue: any) => void;
};

const Input: React.FC<Props> = ({
    name,
    type,
    value,
    label,
    placeholder = '',
    error = '',
    required = false,
    disabled = false,
    onChange
}: Props) => {
    const handleChange = (event: BaseSyntheticEvent) => {
        onChange(event.target.value);
    };

    return (
        <InputRootStyling
            disabled={disabled}
            name={name}
            error={Boolean(error)}
        >
            {Boolean(label) && <label htmlFor={label}>{label}</label>}
            <input
                id={label}
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
            />
            <FormError value={error} />
        </InputRootStyling>
    );
};

export default Input;
