import { ACHIEVEMENTS } from 'utils/enums';
import { Achievement } from 'models/achievement';

const ACHIEVEMENT_LIST = [
    new Achievement({ type: ACHIEVEMENTS.LOGIN_WEEKLY }),
    new Achievement({ type: ACHIEVEMENTS.USER_PROFILE_PICTURE_ADDED }),
    new Achievement({ type: ACHIEVEMENTS.TRAJECT_CREATED }),
    new Achievement({ type: ACHIEVEMENTS.TRAJECT_INFO_ADDED }),
    new Achievement({ type: ACHIEVEMENTS.TRAJECT_COMPLETED }),
    new Achievement({ type: ACHIEVEMENTS.COMMUNITY_TEAM_EVENT_CREATED }),
    new Achievement({ type: ACHIEVEMENTS.COMMUNITY_TEAM_EVENT_ATTENDED }),
    new Achievement({ type: ACHIEVEMENTS.COMMUNITY_CHALLENGE_ATTENDED }),
    new Achievement({ type: ACHIEVEMENTS.HOW_YOU_DOING })
];

export default ACHIEVEMENT_LIST;
