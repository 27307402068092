import React from 'react';
import { useTranslation } from 'react-i18next';
import { getStatistics } from 'api/statistics';
import SingleApiRender from 'components/api-render/single';
import PageHeader from 'components/page-header';
import { EmptyStateKey } from 'components/states/empty';
import { Statistics } from 'models/statistic';
import { StyledStatisticPage } from 'pages/statistics/styling';
import { RenderGeneralStatistic } from 'pages/statistics/renders/base';
import { RenderHowYouDoingStatistics } from 'pages/statistics/renders/feelings';
import { SubHeader } from 'components/text';
import styled from 'styled-components';

const Header = styled(SubHeader)`
    margin: 32px 0;
`;
const StatisticsPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <StyledStatisticPage>
            <PageHeader title={t('STATISTICS.TITLE')} />
            <SingleApiRender
                keyState={EmptyStateKey.NO_STATISTICS}
                promiseCall={getStatistics}
                renderItem={(statistics: Statistics) => {
                    return (
                        <>
                            <Header>{t('STATISTICS.USER.TITLE')}</Header>
                            <RenderGeneralStatistic
                                statistics={statistics.user}
                            />

                            <RenderHowYouDoingStatistics
                                totalAmount={
                                    statistics.getTotalAmountHowYouDoings
                                }
                                statistics={statistics.howYouDoings}
                            />
                            <Header>{t('STATISTICS.ASSESSMENT.TITLE')}</Header>
                            <RenderGeneralStatistic
                                statistics={statistics.assessment}
                            />
                            <Header>{t('STATISTICS.TRAJECTORY.TITLE')}</Header>
                            <RenderGeneralStatistic
                                statistics={statistics.traject}
                            />
                        </>
                    );
                }}
            />
        </StyledStatisticPage>
    );
};
export default StatisticsPage;
