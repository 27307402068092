import React, { BaseSyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'utils/theme';
import assets from 'assets';

const Root = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 1;

    svg {
        width: 100%;
        height: 100%;

        path {
            fill: ${theme.colors.white};
        }
    }
`;

type Props = {
    className?: string;
    onClick?: (event: BaseSyntheticEvent) => void;
};

const BackButton: React.FC<Props> = ({ className = '', onClick }: Props) => {
    const navigate = useNavigate();

    const handleClick = (event: BaseSyntheticEvent) => {
        event.stopPropagation();
        if (onClick) {
            onClick(event);
            return;
        }
        navigate(-1);
    };

    return (
        <Root className={className} onClick={handleClick}>
            <assets.BACK_ARROW_ICON />
        </Root>
    );
};

export default BackButton;
